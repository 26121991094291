import React, { useEffect, useState } from 'react'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { v4 } from 'uuid'
import { getEntityByDataModelId } from './parameter.api'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

const TYPES_NAMES = {
    2: 'Script',
    3: 'Value Setter',
    4: 'Score Card',
}

export function CreateEntitiesFields({
    entity,
    globalParameters,
    onEntityChange,
}) {
    const params = useParams()

    const [options, setOptions] = useState([])
    const modelId = entity && entity.modelId ? entity.modelId : params.modelId
    useEffect(() => {
        getEntityByDataModelId(modelId, entity.type).then((options) => {
            const opts = [
                {
                    name: 'Choose',
                    value: null,
                },
                ...options,
            ]
            setOptions(opts)
        })
    }, [entity.type])
    const addParameterRow = () => {
        const validationParameters = [
            ...entity.validationParameters,
            {
                id: v4(),
                parameter: '',
            },
        ]
        onEntityChange('validationParameters', validationParameters)
    }
    const removeParameter = (id) => {
        const filtered = entity.validationParameters.filter((v) => v.id !== id)

        onEntityChange('validationParameters', filtered)
    }
    const updateParameterById = (ParameterEntity) => {
        const validationParameters = entity.validationParameters.map(
            (parameter) => {
                if (parameter.id === ParameterEntity.id) {
                    return ParameterEntity
                }
                return parameter
            },
        )
        onEntityChange('validationParameters', validationParameters)
    }
    return (
        <>
            <div className="row">
                <SelectWithLabelInput
                    options={options}
                    label={TYPES_NAMES[entity.type]}
                    defaultValue={entity.selectedType}
                    onChange={({ target }) => {
                        onEntityChange('selectedType', target.value)
                    }}
                    placeholder={TYPES_NAMES[entity.type]}
                />
            </div>
            <div className="row">
                <div className="parameters">
                    <div className="resultTitle"> Validation Parameters</div>
                    <label className="multiSelect">
                        Group
                        <Select
                            isMulti
                            name="validationParameters"
                            options={globalParameters}
                            value={globalParameters.filter((group) =>
                                entity.validationParameters.includes(
                                    group.value,
                                ),
                            )}
                            onChange={(selectedOptions) => {
                                const selectedValues = selectedOptions.map(
                                    (option) => option.value,
                                )
                                onEntityChange(
                                    'validationParameters',
                                    selectedValues,
                                )
                            }}
                            classNamePrefix="select"
                            placeholder="Select Parameters"
                        />
                    </label>
                </div>
            </div>
        </>
    )
}
