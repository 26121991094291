import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

const normalizeDataModels = (response) => {
    if (response?.dataModels?.length) {
        let data = []
        response?.dataModels.forEach((item) => {
            data.unshift({
                ...item.models[0],
                _id: item._id,
                createdBy: Users().getUserById(item.models[0].userId)?.name,
            })
        })
        return data.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
        )
    }
    return []
}

const normalizeCreatedDataModel = (response) => {
    if (response?.dataModel) {
        return {
            ...response?.dataModel,
            _id: response?.dataModel.modelId,
            createdBy: Users().getUserById(response?.dataModel.userId)?.name,
        }
    }
    return null
}

export function getDataModel() {
    const page = 1
    const limit = 1000
    return Api()
        .get(`api/data-models/all?page=${page}&limit=${limit}`)
        .then((response) => normalizeDataModels(response))
}

export function createDataModel(name) {
    const jsonCode = `{\n\n}`
    return Api()
        .post('api/data-models/create', {
            name,
            bodyInput: jsonCode,
            bodyOutput: jsonCode,
        })
        .then((response) => normalizeCreatedDataModel(response))
}

export function getDataById({ id }) {
    return Api()
        .get(`api/data-models/${id}`)
        .then((response) => normalizeCreatedDataModel(response))
}
