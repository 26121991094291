import React, { useCallback, useEffect, useState } from 'react';
import ListPage from '../../Organisms/ListPage/ListPage';
import 'react-toastify/dist/ReactToastify.css';
import { CreateNewScriptPopup } from '../../Organisms/Popups/CreateNewScriptPopup';
import { DataStoreColumn } from '../../../utils/tableColumns';
import { CreateNewDataStore } from '../../Organisms/Popups/CreateNewDataStorePopup/CreateNewDataStorePopup';
import { getAllDataStorage } from '../../../store/dataStorage/api'
import {  getDataModel } from '../../../store/model/api';
import { handleError } from '../../../utils/handleErrors';

export const DataStore = () => {
    
    return (
              <>
                <ListPage         
                    title="Data Store"
                    dataModelCall={getDataModel}
                    dataCall={getAllDataStorage}
                    tableColumns={DataStoreColumn} 
                    btnName="Create DS"
                    CreatePopupComponent={CreateNewDataStore}
                />
              </>
    )
}