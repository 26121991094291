import { Api } from '../../Services/Api'
import {Users} from "../../Services/users";
import {getDataById} from "../model/api"
function normalizeDataStorage(response){
    return  response?.dataStorages?.map((i)=>{
        return {...i?.models[0],user:Users().getUserById(i?.models[0]?.userId)?.name, _id:i?._id}
    })
}

function normalizeDataStorageID(response){
    return{ name:response.dataStorage.name, data:response.data.map((i,index)=>{
        return {
            ...i,
            n: index+1,
        }
    }), modelSchema:response?.dataStorage?.modelSchema.map((i,index)=>{
        return{...i,
            n:index+1,
            name:response?.dataStorage?.name,
            createdAt:response?.dataStorage?.createdAt,
            updatedAt:response?.dataStorage?.updatedAt
        }
    })}
}

export function getAllDataStorage() {
    const page = 1
    const limit = 10000
    return Api().get(`api/data-storages/all?page=${page}&limit=${limit}`).then(response=>normalizeDataStorage(response))
}

export function  getDataStorageById(id) {
    return Api().get(`api/data-storages/${id}`).then((response)=>normalizeDataStorageID(response))
}

export function createDataStorage(name, modelSchema) {
    return Api().post('api/data-storages/create', {
        name,modelSchema
    }).then((response) => console.log(response))
}

export function InsertData(data, id) {
    return Api().post(`api/data-storages/insert-data/${id}`, {
         data
    }).then((response) => console.log(response))
}

export function archiveDataStorageById(id) {
    return Api().patch(`api/flows/data-storages/archived/${id}`)
}
export function updateDataStorage(name, modelSchema,id) {
    return Api().put(`api/data-storages/update/${id}`, {
        name,modelSchema
    }).then((response) => console.log(response))
}