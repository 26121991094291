import React, { memo, useEffect, useState } from 'react'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useParams } from 'react-router-dom'
import { TextInput } from '../../Atoms/Inputs/Text'
import {
    getSegmentByDataModelId,
    getSegmentById,
} from '../../../store/segment/api'
import { scoringsUpdate } from '../../../store/score/api'
import { handleError } from '../../../utils/handleErrors'
import { ScoreResults } from './score-results'
import { Header } from '../../Organisms/single-page-common/header'
import { ScoreSegments } from './score-segments'
import { getEntityVersionById } from '../../../store/common/api'

const entityType = 'scoring'
const entityURL = 'scores'
export const Score = memo(() => {
    const { id } = useParams()
    const [versions, setVersions] = useState([])
    const [segmentModelsList, setSegmentModelsList] = useState()
    const [score, setScore] = useState(null)

    useEffect(() => {
        if (id) {
            getEntityVersionById(id, entityType)
                .then(async (versions) => {
                    const normalVersions = versions
                    setVersions(normalVersions)
                    const score = await reformatSegments(normalVersions[0])
                    setScore(score)
                    getSegmentByDataModelId({
                        page: 1,
                        limit: 10000,
                        modelId: score.modelId,
                    })
                        .then(({ segments }) => {
                            const options = segments.map((segment) => {
                                return {
                                    name: segment.models[0].name,
                                    value: segment._id,
                                }
                            })

                            setSegmentModelsList(() => [
                                { name: 'Choose', value: '' },
                                ...options,
                            ])
                        })
                        .catch(handleError)
                })
                .catch(handleError)
        }
    }, [id])
    const updateScoring = (key, value) => {
        setScore({
            ...score,
            [key]: value,
        })
    }
    const changeVersion = async (score) => {
        setScore(await reformatSegments(score))
    }
    const reformatSegments = async (score) => {
        const segments = score.segments
        const segmentsCall = segments.map(async (item) =>
            getSegmentById({ id: item.segmentId }),
        )
        return await Promise.all(segmentsCall).then((responnse) => {
            const list = responnse
                .map((item) => item.segment)
                .map((item) => {
                    const segment = segments.find(
                        (segment) => segment.segmentId === item.segmentId,
                    )
                    return {
                        segmentId: item.segmentId,
                        scores: item.conditions.map((score) => {
                            const hasScore = segment.scores[score.id]

                            const values = hasScore
                                ? {
                                      scoreId: hasScore.scoreId,
                                      scoreValue: +hasScore.scoreValue,
                                  }
                                : {}
                            return {
                                ...score,
                                ...values,
                            }
                        }),
                    }
                })

            return {
                ...score,
                segments: list,
            }
        })
    }

    const updateScore = () => {
        const segments = score.segments.map((item) => {
            const scores = {}
            item.scores.forEach((score) => {
                scores[score.id] = {
                    scoreId: score.scoreId,
                    scoreValue: +score.scoreValue,
                }
            })
            return {
                segmentId: item.segmentId,
                scores,
            }
        })

        scoringsUpdate({
            name: score.name,
            scoringId: id,
            modelId: score.modelId,
            segments,
            results: score.results,
            propertyCode: score.propertyCode,
            propertyResult: score.propertyResult,
            propertyValue: score.propertyValue,
            initialScore: score.initialScore,
        })
            .then(async ({ scoring }) => {
                const normalized = await reformatSegments(scoring)
                setScore(normalized)
                setVersions([normalized, ...versions])
                toast.success('Successfully Updated')
            })
            .catch(handleError)
    }
    return (
        <>
            <ToastContainer />
            {score ? (
                <div className="rightSide">
                    <div className="codeWrapper">
                        <Header
                            entityType={entityType}
                            onEntityUpdate={updateScore}
                            setEntity={changeVersion}
                            entity={score}
                            entityURL={entityURL}
                            versions={versions}
                        />

                        <div className="score-content">
                            <div className="mainForm">
                                <div className="mainFormContent">
                                    <div className="formItem">
                                        <TextInput
                                            label="Initial Score"
                                            placeholder="Initial Score"
                                            value={score.initialScore}
                                            onChange={({ target }) => {
                                                updateScoring(
                                                    'initialScore',
                                                    target.value,
                                                )
                                            }}
                                        />
                                    </div>

                                    <div className="formItem">
                                        <TextInput
                                            label="Score Result Value Variable"
                                            placeholder="Score Result Value Variable"
                                            value={score.propertyValue}
                                            onChange={({ target }) => {
                                                updateScoring(
                                                    'propertyValue',
                                                    target.value,
                                                )
                                            }}
                                        />
                                    </div>

                                    <div className="formItem">
                                        <TextInput
                                            label="Score Result Code Variable"
                                            placeholder="Score Result Code Variable"
                                            value={score.propertyCode}
                                            onChange={({ target }) => {
                                                updateScoring(
                                                    'propertyCode',
                                                    target.value,
                                                )
                                            }}
                                        />
                                    </div>

                                    <div className="formItem">
                                        <TextInput
                                            label="Score Value Variable"
                                            placeholder="Score Value Variable"
                                            value={score.propertyResult}
                                            onChange={({ target }) => {
                                                updateScoring(
                                                    'propertyResult',
                                                    target.value,
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="formSegment">
                                <ScoreSegments
                                    entity={score}
                                    setEntity={setScore}
                                    segmentModelsList={segmentModelsList}
                                />
                            </div>
                            <ScoreResults setEntity={setScore} entity={score} />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
})
