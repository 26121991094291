import {Api} from "./Api";
let USERS = []

export function Users() {
    function fetchAllUsers() {
        const page = 1;
        const limit = 1000
        return Api().get(`api/users/all?page=${page}&limit=${limit}`)
    }
    function initialLoad() {
        return fetchAllUsers().then((response) => USERS = response.users ).then(()=> true)
    }
    function getUserById(id) {
        return USERS.find(u => u.id === id)
    }
    return {
        USERS,
        initialLoad,
        getUserById
    }
}