import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import ListPage from '../../Organisms/ListPage/ListPage';
import { valueSetterTableColumn } from '../../../utils/tableColumns';
import { getDataModel } from '../../../store/model/api';
import { CreateNewValuePopup } from '../../Organisms/Popups/CreateNewScorePopup copy';
import { getAllValueSetter} from '../../../store/valueSetter/api';


export const ValueSetterList = () => {
    

    return (

             <>
             <ListPage 
                    title="Value Setter"
                    dataModelCall={getDataModel}
                    dataCall={getAllValueSetter}
                    tableColumns={valueSetterTableColumn} 
                    btnName="Value Setter"
                    CreatePopupComponent={CreateNewValuePopup}/>
                </>
    )
}