import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { TextInput } from '../../Atoms/Inputs/Text'
import { useParams } from 'react-router-dom'
import { Button } from '../../Atoms/Buttons/Button'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { getAllRole } from '../../../store/role/api'
import { SelectMultipleWithLabelInput } from '../../Atoms/Inputs/SelectMultipleWithLabel'
import { getDataModel } from '../../../store/model/api'
import Select from 'react-select'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'

const entityType = 'user'
const entityURL = 'users'
const defaultOption = { name: 'Choose', value: null }
export const CreateUser = () => {
    const [entity, setEntity] = useState({
        name: '',
        email: '',
        phone: '',
        role: '',
        password: '',
        dataModels: [],
    })
    const [roles, setRoles] = useState([])
    const [dataModels, setDataModels] = useState([])
    const generatePassword = () => {
        const a = Math.random().toString(36).slice(-8)
        onEntityChange('password', a)
    }

    useEffect(() => {
        getAllRole().then((_roles) => {
            const list = _roles.map((r) => {
                return {
                    name: r.name,
                    value: r._id,
                }
            })
            setRoles([defaultOption, ...list])
        })

        getDataModel().then((_dataModels) => {
            setDataModels([
                ..._dataModels.map((r) => {
                    return {
                        label: r.name,
                        value: r._id,
                    }
                }),
            ])
        })
    }, [])

    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header entity={entity} entityURL={entityURL} />
                    <div className="single-table-wrapper rule-content user-page">
                        <div className="inputsWrapper">
                            <div className="row first-row">
                                <TextInput
                                    label="Name"
                                    value={entity?.name}
                                    onChange={({ target }) => {
                                        onEntityChange('name', target.value)
                                    }}
                                    placeholder="Name"
                                />
                                <TextInput
                                    label="Email"
                                    value={entity?.email}
                                    onChange={({ target }) => {
                                        onEntityChange('email', target.value)
                                    }}
                                    placeholder="Email"
                                />
                                <TextInput
                                    label="Phone"
                                    value={entity?.phone}
                                    onChange={({ target }) => {
                                        onEntityChange('phone', target.value)
                                    }}
                                    placeholder="Phone"
                                />
                            </div>
                            <div className="row second-row">
                                <TextInput
                                    label="Password"
                                    value={entity?.password}
                                    onChange={({ target }) => {
                                        onEntityChange('password', target.value)
                                    }}
                                    placeholder="Password"
                                />
                                <div>
                                    <ButtonWithIcon
                                        onClick={generatePassword}
                                        green={true}
                                        value="Generate Password"
                                    />
                                </div>
                            </div>
                            <div className="row third-row">
                                <SelectWithLabelInput
                                    label="Role"
                                    defaultValue={entity?.role}
                                    options={roles}
                                    onChange={({ target }) => {
                                        onEntityChange('role', target.value)
                                    }}
                                />
                                <label className="multiSelect">
                                    Data Models
                                    <Select
                                        isMulti
                                        name="dataModels"
                                        options={dataModels}
                                        value={dataModels.filter((group) =>
                                            entity.dataModels.includes(
                                                group.value,
                                            ),
                                        )}
                                        onChange={(selectedOptions) => {
                                            const selectedValues =
                                                selectedOptions.map(
                                                    (option) => option.value,
                                                )
                                            onEntityChange(
                                                'dataModels',
                                                selectedValues,
                                            )
                                        }}
                                        classNamePrefix="select"
                                        placeholder="Select Data Models"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
