import React, { useEffect, useState } from 'react'
import ListPage from '../../Organisms/ListPage/ListPage'
import 'react-toastify/dist/ReactToastify.css'
import editGreen from './../../../imgs/EditGreen.png'
import deletIcon from './../../../imgs/Group 34774 (1).png'
import { ModalEditObject } from '../CreateStorage/ModalEditObject'
import { CreateNewDataStore } from '../../Organisms/Popups/CreateNewDataStorePopup/CreateNewDataStorePopup'
import { TextInput } from '../../Atoms/Inputs/Text'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { useParams } from 'react-router-dom'
import { getDataStorageById, InsertData } from '../../../store/dataStorage/api'
import { getDataModel } from '../../../store/model/api'
import { toast } from 'react-toastify'

export const DataStoreName = () => {
    const [render, setRender] = useState(false)
    const [rows, setRows] = useState([])
    const [keys, setKeys] = useState(0)
    const [rowId, setRowId] = useState('')
    const [key, setKey] = useState('')
    const [objectValue, setObjectValue] = useState('')
    const [showPopup, setShowPopup] = useState(false)
    const [selctedRows, setSelectedRows] = useState([])
    const [name, setName] = useState('')
    const [show, setShow] = useState(true)
    const [n, setN] = useState(1)
    const { id } = useParams()
    const [DataStoreColumn, setDataStoreColumn] = useState([])
    const [isValidJson, setIsValidJson] = useState(true)

    const deleteSelectedRows = () => {
        setRows(rows.filter((i) => !selctedRows.includes(i.n)))
        setSelectedRows([])
    }
    let columns = []
    useEffect(() => {
        getDataStorageById(id)
            .then((response) => {
                setName(response.name)
                setRows(response.data)
                columns = response.modelSchema.map((i) => {
                    let key = i.key
                    return {
                        field: i.key,
                        defaultValue: i.defaultValue,
                        types: i.columnType,
                        minWidth: '300',
                        cellRenderer: (params) => {
                            const n = params.data?.n
                            return (
                                <div
                                    style={{
                                        width: '200px',
                                        height: '60px',
                                        marginBottom: '8px',
                                    }}
                                >
                                    {i.columnType == 'object' ? (
                                        <textarea
                                            className={`${
                                                isValidJson ? 'initial' : 'red'
                                            }`}
                                            style={{
                                                width: '100%',
                                                height: '52px',
                                                borderRadius: '7px',
                                                marginTop: '7px',
                                            }}
                                            rows="5"
                                            cols="20"
                                            defaultValue={params.data[i?.key]}
                                            onClick={(e) => {
                                                setObjectValue(e.target.value)
                                                setRowId(n)
                                                setKey(key)
                                                setShowPopup(true)
                                            }}
                                        />
                                    ) : i.columnType == 'boolean' ? (
                                        <SelectWithLabelInput
                                            options={['false', 'true']}
                                            defaultValue={String(
                                                params.data[i?.key],
                                            )}
                                            onChange={({ target }) =>
                                                editColumn(
                                                    n,
                                                    JSON.parse(target.value),
                                                    i.key,
                                                )
                                            }
                                        />
                                    ) : (
                                        <TextInput
                                            placeholder={
                                                i.columnType == 'object'
                                                    ? '"{"a":"5"}"'
                                                    : ''
                                            }
                                            type={
                                                i.columnType == 'number'
                                                    ? 'number'
                                                    : 'text'
                                            }
                                            value={params.value}
                                            onChange={({ target }) =>
                                                params.setValue(target.value)
                                            }
                                            onBlur={({ target }) =>
                                                editColumn(
                                                    n,
                                                    target.value,
                                                    i.key,
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            )
                        },
                    }
                })
                setDataStoreColumn([
                    {
                        headerName: 'N',
                        field: 'n',
                        checkboxSelection: true,
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        sortable: true,
                        maxWidth: 120,
                    },
                    ...columns,

                    {
                        field: 'Actions',
                        maxWidth: 120,
                        cellRenderer: (params) => {
                            return (
                                <div className="icons">
                                    <img
                                        src={deletIcon}
                                        onClick={(event) => {
                                            setKeys(params.data.n)
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <img
                                        src={editGreen}
                                        style={{
                                            cursor: 'pointer',
                                            paddingLeft: '5px',
                                        }}
                                    />{' '}
                                </div>
                            )
                        },
                    },
                ])
                // setKeys(response)
            })
            .catch((e) => console.log(e))
    }, [render])
    const addRows = () => {
        const addedRow = {}
        for (let i of DataStoreColumn) {
            addedRow[i.field] =
                i.types === 'boolean'
                    ? JSON.parse(i.defaultValue)
                    : i.defaultValue
        }

        setN(n + 1)
        setRows(normalizeCloumns([...rows, addedRow]))
    }
    const editColumn = (id, value, key) => {
        setRows((prevColumn) => {
            return prevColumn.map((item) => {
                if (item.n === id) {
                    return { ...item, [key]: value, createdAt: '' }
                }
                return item
            })
        })
    }
    useEffect(() => {
        setRows(rows.filter((i) => keys !== i.n))
    }, [keys])
    const normalizeCloumns = (column) => {
        return column.map((i, index) => {
            return { ...i, n: index + 1 }
        })
    }
    const insertData = () => {
        const data = rows.filter((i) => !i.createdAt)
        if (data.length) {
            InsertData(data, id)
                .then((e) => {
                    toast.success('Data is Successfuly Inserted ')
                    setRender(!render)
                })
                .catch((e) => toast.error(e))
        } else {
            toast.error('empty Data')
        }
    }

    return (
        <>
            {showPopup && (
                <ModalEditObject
                    setShowPopup={setShowPopup}
                    editColumn={editColumn}
                    objectValue={objectValue}
                    setObjectValue={setObjectValue}
                    key1={key}
                    rowId={rowId}
                />
            )}
            <ListPage
                pg={25}
                insertData={insertData}
                insert={true}
                id={id}
                setShow={setShow}
                show={show}
                deleteSelectedRows={deleteSelectedRows}
                selctedRows={selctedRows}
                setSelectedRows={setSelectedRows}
                column={rows}
                title={`Data Store ${name}`}
                dataModelCall={getDataModel}
                tableColumns={DataStoreColumn}
                savedBtn="Save"
                editBtnStruc="Edit Structure"
                addRows={addRows}
                CreatePopupComponent={CreateNewDataStore}
            />
        </>
    )
}
