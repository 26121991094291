import React from 'react'
import { useHistory } from 'react-router-dom'
import s from './Header.module.scss'

export const Header = ({ onClick = () => {}, value = '', disabled, title }) => {
    let history = useHistory()
    return (
        <div className={s.headerWrapper}>
            <div className={s.headerTitle}>{title}</div>
            <div className={s.headerActionWrapper}>
                {/*<div className={s.headerSearch}>*/}
                {/*    <img src="/assets/images/Search.png" alt="search" /> */}
                {/*    <input placeholder="Type here to search" />*/}
                {/*</div>*/}
                {/*<div className={s.headerNotification}>*/}
                {/*    <img src="/assets/images/Notification.png" alt="Notification" />*/}
                {/*</div>*/}
                {/*<div className={s.headerSettings}>*/}
                {/*    <img src="/assets/images/Setting.svg" alt="Setting" />*/}
                {/*</div>*/}
                <div
                    className={s.headerProfile}
                    onClick={() => history.push('/profile')}
                >
                    <img src="/assets/images/Profile.svg" alt="Profile" />
                </div>
            </div>
        </div>
    )
}
