import React, { memo } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import View from '../../../imgs/view.png'
import { useHistory } from 'react-router-dom'

export const RuleRow = memo(
    ({ rules, rule, updateRuleById, removeRuleById }) => {
        const updateRule = (key, value) => {
            updateRuleById({
                ...rule,
                [key]: value,
            })
        }
        const removeRule = (id) => {
            removeRuleById(id)
        }
        const history = useHistory()
        const viewRule = (id) => {
            history.push(`/rules/${id}?type=view`)
        }
        return (
            <div
                className="formResult"
                style={{
                    border: '1px solid #blue',
                }}
            >
                <div className="formItem">
                    <SelectWithLabelInput
                        options={rules}
                        defaultValue={rule.rule}
                        onChange={({ target }) => {
                            updateRule('rule', target.value)
                        }}
                    />
                    <div style={{ paddingTop: '11px', paddingLeft: '10px' }}>
                        <img
                            src={View}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                viewRule(rule.rule)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                    </div>
                    <div className="remove" onClick={() => removeRule(rule.id)}>
                        -
                    </div>
                </div>
            </div>
        )
    },
)
