import React, { useCallback, useEffect, useState } from 'react'
import { TextInput } from '../../Atoms/Inputs/Text'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import s from './UserManagement.module.scss'
import { ButtonLighth } from '../../Atoms/Buttons/ButtonLighth'
import { ButtonSmall } from '../../Atoms/Buttons/ButtonSmall'
import { Header } from '../../Organisms/Header'
import { Sidebar } from '../../Organisms/Sidebar/Sidebar'
import {
    addUserAction,
    getAllUsersAction,
    updateUsersAction,
} from '../../../store/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsersSelector } from '../../../store/user/selector'
import { toast, ToastContainer } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

export const UserManagement = () => {
    const dispatch = useDispatch()
    const [name, setName] = useState()
    const [surname, setSurname] = useState()
    const [email, setEmail] = useState()
    const [role, setRole] = useState()
    const [phone, setPhone] = useState()
    const [password, setPassword] = useState()
    const [oldPassword, setOldPassword] = useState()

    const [editName, setEditName] = useState()

    const [showAddUser, setShowAddUser] = useState(false)
    const [showEditUser, setShowEditUser] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(1000000)

    const state = useSelector(getAllUsersSelector)
    const options = [{ name: 'Administartor', value: '0' }]

    const editUser = useCallback(() => {
        setShowEditUser(true)
        setShowAddUser(false)
    }, [setShowEditUser, setShowAddUser])

    const addNewUser = useCallback(() => {
        setShowAddUser(true)
        setShowEditUser(false)
    }, [setShowAddUser, setShowEditUser])

    const onCancelEditUser = useCallback(() => {
        setShowEditUser(false)
    }, [setShowEditUser])

    const onCancelAddUser = useCallback(() => {
        setShowAddUser(false)
    }, [setShowAddUser])

    const handleEditUser = useCallback(() => {
        dispatch(updateUsersAction({ editName, phone, password, oldPassword }))
            .then(() => {})
            .catch((a) => {
                let error = JSON.parse(a)
                toast.error(error.message ? error.message : a)
            })
    }, [editName, password, phone, oldPassword])

    const handleAddUser = useCallback(() => {
        dispatch(addUserAction({ name, email, phone }))
            .then(() => {})
            .catch((a) => {
                let error = JSON.parse(a)
                toast.error(error.message ? error.message : a)
            })
    }, [name, email, phone])

    const pagination = useCallback(() => {
        let pagination = []
        for (let index = 0; index < state?.totalPages; index++) {
            pagination.push(index + 1)
        }
        return pagination
    }, [state?.totalPages])

    const handleChangePage = useCallback(
        (event) => {
            setPage(event.target.innerText)
        },
        [setPage],
    )

    useEffect(() => {
        dispatch(getAllUsersAction({ page, limit }))
            .then(() => {})
            .catch((a) => {
                let error = JSON.parse(a)
                toast.error(error.message ? error.message : a)
            })
    }, [page, limit])

    if (window.localStorage.getItem('Authorization') === null)
        return <Redirect to="/main" />

    return (
        <>
            <ToastContainer />
            <div className={s.mainWrapper}>
                <div className={s.sidebarWrapper}>
                    <Sidebar />
                </div>
                <div className={s.contentWrapper}>
                    <Header title="User Management" />
                    <div className={s.mainComponentLogin}>
                        <div className={s.mainComponent}>
                            <div className={s.tableWrapper}>
                                <div className={s.tableBox}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                                {/* <th>Actions</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state &&
                                                state.users &&
                                                state.users.map((item) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.name}</td>
                                                            <td>
                                                                {item.phone}
                                                            </td>
                                                            <td>
                                                                {item.email}
                                                            </td>
                                                            <td>
                                                                Administrator
                                                            </td>
                                                            {/* <td className={s.tableEdit} onClick={editUser}><img src="assets/images/Edit.png" />Edit</td> */}
                                                        </tr>
                                                    )
                                                })}
                                            <tr className={s.empty}></tr>
                                        </tbody>
                                    </table>
                                    <div className={s.pagination}>
                                        <ul>
                                            {pagination().length &&
                                                pagination().map(
                                                    (item, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                onClick={
                                                                    handleChangePage
                                                                }
                                                                className={
                                                                    state &&
                                                                    state.currentPage ===
                                                                        item
                                                                        ? s.active
                                                                        : ''
                                                                }
                                                            >
                                                                {item}
                                                            </li>
                                                        )
                                                    },
                                                )}
                                        </ul>
                                    </div>
                                </div>
                                {showEditUser && (
                                    <div className={s.editUserWrapper}>
                                        <div className={s.editUserTitle}>
                                            Edit User
                                        </div>
                                        <div className={s.editUserBox}>
                                            <div className={s.editUserInput}>
                                                <TextInput
                                                    label="Name"
                                                    placeholder="Name"
                                                    value={editName}
                                                    onChange={({ target }) => {
                                                        setEditName(
                                                            target.value,
                                                        )
                                                    }}
                                                />
                                            </div>
                                            {/* <div className={s.editUserInput}>
                                            <TextInput label="Surname" placeholder="Surname" value={editSurname} onChange={({target}) => {setEditSurname(target.value)}}  />
                                        </div> */}
                                            <div className={s.editUserInput}>
                                                <TextInput
                                                    label="Phone"
                                                    placeholder="Phone"
                                                    value={phone}
                                                    onChange={({ target }) => {
                                                        setPhone(target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className={s.editUserSelect}>
                                                <SelectWithLabelInput
                                                    label="Role"
                                                    options={options}
                                                    onChange={({ target }) => {
                                                        setRole(target.value)
                                                    }}
                                                />
                                            </div>
                                            {/* <div className={s.editUserSwitcher}>
                                            <SwitcherInput label="Deactivate User" />
                                        </div> */}
                                            <div className={s.buttonsWrapper}>
                                                <ButtonLighth
                                                    value="Cancel"
                                                    onClick={onCancelEditUser}
                                                />
                                                <ButtonSmall
                                                    onClick={handleEditUser}
                                                    value="Save"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {showAddUser && (
                                    <div className={s.editUserWrapper}>
                                        <div className={s.editUserTitle}>
                                            Add New User
                                        </div>
                                        <div className={s.editUserBox}>
                                            <div className={s.editUserInput}>
                                                <TextInput
                                                    label="Name"
                                                    placeholder="Name"
                                                    value={name}
                                                    onChange={({ target }) => {
                                                        setName(target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className={s.editUserInput}>
                                                <TextInput
                                                    label="Surname"
                                                    placeholder="Surname"
                                                    value={surname}
                                                    onChange={({ target }) => {
                                                        setSurname(target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className={s.editUserInput}>
                                                <TextInput
                                                    label="Email"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={({ target }) => {
                                                        setEmail(target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className={s.editUserSelect}>
                                                <SelectWithLabelInput
                                                    label="Role"
                                                    options={options}
                                                    onChange={({ target }) => {
                                                        setRole(target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className={s.buttonsWrapper}>
                                                <ButtonLighth
                                                    value="Cancel"
                                                    onClick={onCancelAddUser}
                                                />
                                                <ButtonSmall
                                                    onClick={handleAddUser}
                                                    value="Invite"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
