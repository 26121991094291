import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

function normalizeSubProductCatalog(response, dataModelsForNormalize) {
    if (response?.subCatalogs?.length) {
        const data = response.subCatalogs.map((catalog) => {
            return {
                ...catalog.models[0],
                user: Users().getUserById(catalog.models?.[0].userId)?.name,
                dataModel: dataModelsForNormalize.find(
                    (dm) => dm._id === catalog.modelId,
                )?.name,
                _id: catalog._id,
            }
        })
        return data.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
        )
    } else {
        return []
    }
}

export function getAllSubProductCatalogs(dataModelsForNormalize) {
    const page = 1
    const limit = 10000
    return Api()
        .get(`api/sub-product-catalogs/all?page=${page}&limit=${limit}`)
        .then((response) => {
            const data = normalizeSubProductCatalog(
                response,
                dataModelsForNormalize,
            )
            return data
        })
}

export function getSubProductCatalogById({ id }) {
    return Api().get(`api/sub-product-catalogs/${id}`)
}

export function createSubProductCatalog(entity, dataModelsForNormalize) {
    return Api()
        .post(`api/sub-product-catalogs/create`, entity)
        .then((response) =>
            normalizeSubProductCatalog(response, dataModelsForNormalize),
        )
}

export function productSubCatalogUpdate({
    name,
    subCatalogId,
    internalId,
    parent,
    modelId,
    rules,
    description,
    globalParameters,
    calculationBlocks,
}) {
    return Api().put(`api/sub-product-catalogs/update/${subCatalogId}`, {
        name,
        subCatalogId,
        internalId,
        modelId,
        parent,
        rules,
        description,
        globalParameters,
        calculationBlocks,
    })
}

export function archiveSubProductCatalogById(id) {
    return Api().patch(`api/sub-product-catalogs/archive/${id}`)
}

export function getSubProductCatalogsByDataModelId(
    { page = 0, limit = 1000, modelId },
    dataModelsForNormalize,
) {
    return Api()
        .get(
            `api/sub-product-catalogs/data-model/${modelId}?page=${page}&limit=${limit}`,
        )
        .then((response) => {
            const data = normalizeSubProductCatalog(
                response,
                dataModelsForNormalize,
            )

            return data
        })
}
