import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { TextInput } from '../../Atoms/Inputs/Text'
import { useParams } from 'react-router-dom'
import { getEntityVersionById } from '../../../store/common/api'
import { handleError } from '../../../utils/handleErrors'
import { formatDate } from '../../../Services/dateFormat'
import Table from '../../Organisms/Tables/Table/Table'
import { RuleHistoryChangesPopup } from './RuleHistoryChangesPopup'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { v4 } from 'uuid'

import { IFCondition } from './IFCondition'
import { IFConditionHeader } from './IFConditionHeader'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { ResultRow } from './ResultRow'
import { getParametersByDataModelId } from '../../../store/parameter/api'
import Select from 'react-select'

const entityType = 'rule'
const entityURL = 'rules'

const TYPES = [
    { name: 'Rejection', value: 1 },
    { name: 'Limit Calculation', value: 2 },
]
const GROUPS = [
    { label: 'Borrower', value: 1 },
    { label: 'Pledger', value: 5 },
    { label: 'Client', value: 11 },
    { label: 'Contact person', value: 9 },
    { label: 'Guarantor', value: 3 },
    { label: 'Seller', value: 12 },
    { label: 'Co-borrower', value: 2 },
    { label: 'Spouse', value: 4 },
]
const CONDITION_OPTIONS = [
    { name: 'And', value: 'AND' },
    { name: 'Or', value: 'OR' },
]
const conditions = []

export const Rule = () => {
    const { id } = useParams()
    const [entity, setEntity] = useState({
        conditions: [], // Initialize conditions as an empty array
        results: [],
    })
    const [versions, setVersions] = useState([])
    const [openRowPopup, setOpenRowPopup] = useState(null)
    const versionTableHeaders = [
        {
            headerName: 'Version',
            field: 'version',
            filter: true,
            sort: true,
        },
        {
            headerName: 'Created By',
            field: 'createdBy',
            filter: true,
            sort: true,
        },
        {
            headerName: 'Created At',
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            headerName: 'Approver',
            field: 'createdBy',
            filter: true,
            sort: true,
        },
        {
            field: 'Actions',
            filter: false,
            sort: false,
            cellRenderer: (params) => {
                return (
                    <span
                        onClick={() => setOpenRowPopup(params.data.id)}
                        style={{ color: 'rgb(0 198 162 / 69%)' }}
                    >
                        View
                    </span>
                )
            },
        },
    ]
    const [dataModels, setDataModels] = useState([])
    const [parameters, setParameters] = useState([
        { name: 'Choose', value: null },
    ])

    const updateInfo = (id) => {
        getEntityVersionById(id, entityType)
            .then((versions) => {
                setVersions(versions)
                if (versions && versions.length > 0) {
                    setEntity(versions[0])
                }
            })
            .catch(handleError)
    }

    useEffect(() => {
        updateInfo(id)
    }, [])

    useEffect(() => {
        // Check if modelId is defined before fetching parameters
        if (entity?.modelId) {
            getParametersByDataModelId({ modelId: entity.modelId }, dataModels)
                .then((_parameters) => {
                    const list = _parameters
                        .filter((item) => item.group !== 11 && item.type == 1)
                        .map((item) => {
                            return { name: item.name, value: item._id }
                        })
                    setParameters([{ name: 'Choose', value: null }, ...list])
                })
                .catch(handleError)
        }
    }, [entity.modelId, dataModels])
    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }

    const updateConditionById = (ConditionEntity) => {
        setEntity({
            ...entity,
            conditions: entity.conditions.map((condition) => {
                if (condition.id === ConditionEntity.id) {
                    return ConditionEntity
                }
                return condition
            }),
        })
    }

    const updateResultById = (ResultEntity) => {
        setEntity({
            ...entity,
            results: entity.results.map((result) => {
                if (result.id === ResultEntity.id) {
                    return ResultEntity
                }
                return result
            }),
        })
    }

    const addIfCondition = () => {
        setEntity({
            ...entity,
            conditions: [
                ...entity.conditions,
                {
                    id: v4(),
                    type: 'AND',
                    rows: [
                        {
                            id: v4(),
                            type: 'and',
                            parameter: '',
                            condition: '',
                            value: '',
                        },
                    ],
                },
            ],
        })
    }

    const addResultRow = () => {
        setEntity({
            ...entity,
            results: [
                ...entity.results,
                {
                    id: v4(),
                    parameter: '',
                    value: '',
                },
            ],
        })
    }

    const removeIfCondition = (id) => {
        setEntity({
            ...entity,
            conditions: entity.conditions.filter(
                (condition) => condition.id !== id,
            ),
        })
    }
    const removeResult = (id) => {
        setEntity({
            ...entity,
            results: entity.results.filter((result) => result.id !== id),
        })
    }

    const changeIfConditionType = (type, id) => {
        setEntity({
            ...entity,
            conditions: entity.conditions.map((condition) => {
                if (condition.id === id) {
                    condition.type = type
                }
                return condition
            }),
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header
                        updateInfo={updateInfo}
                        entity={entity}
                        entityURL={entityURL}
                    />
                    {openRowPopup ? (
                        <RuleHistoryChangesPopup
                            id={openRowPopup}
                            close={() => setOpenRowPopup(null)}
                        />
                    ) : null}
                    <div className="single-table-wrapper rule-content parameter-individual-page">
                        <div className="inputsWrapper">
                            <div className="row first-row">
                                <TextInput
                                    label="Name"
                                    value={entity?.name}
                                    onChange={({ target }) => {
                                        onEntityChange('name', target.value)
                                    }}
                                    placeholder="Name"
                                />
                                <SelectWithLabelInput
                                    label="Type"
                                    defaultValue={entity?.type}
                                    onChange={({ target }) => {
                                        onEntityChange('type', target.value)
                                    }}
                                    options={TYPES}
                                />
                                <label className="multiSelect">
                                    Group
                                    <Select
                                        isMulti
                                        name="groups"
                                        options={GROUPS}
                                        value={GROUPS.filter((group) =>
                                            entity && entity.groups
                                                ? entity.groups.includes(
                                                      group.value,
                                                  )
                                                : [],
                                        )}
                                        onChange={(selectedOptions) => {
                                            const selectedValues =
                                                selectedOptions.map(
                                                    (option) => option.value,
                                                )
                                            onEntityChange(
                                                'groups',
                                                selectedValues,
                                            )
                                        }}
                                        classNamePrefix="select"
                                        placeholder="Select Groups"
                                    />
                                </label>
                            </div>
                            <div className="row second-row">
                                <div className="conditions-block">
                                    <div className="conditions-group">
                                        {entity
                                            ? entity?.conditions.map(
                                                  (item, index) => {
                                                      return (
                                                          <div
                                                              key={index}
                                                              className="conditions"
                                                          >
                                                              <IFConditionHeader
                                                                  changeIfConditionType={
                                                                      changeIfConditionType
                                                                  }
                                                                  removeIfCondition={
                                                                      removeIfCondition
                                                                  }
                                                                  item={item}
                                                                  index={index}
                                                              />
                                                              <IFCondition
                                                                  updateConditionById={
                                                                      updateConditionById
                                                                  }
                                                                  entity={item}
                                                                  parameters={
                                                                      parameters
                                                                  }
                                                              />
                                                          </div>
                                                      )
                                                  },
                                              )
                                            : null}
                                        <div className="conditionGroupButton">
                                            <ButtonWithIcon
                                                onClick={addIfCondition}
                                                icon="/assets/images/icon+.png"
                                                green={true}
                                                value="Add Condition Group"
                                            />
                                        </div>
                                    </div>
                                    <div className="results">
                                        <div className="resultTitle"> Then</div>
                                        {entity
                                            ? entity?.results.map(
                                                  (item, index) => {
                                                      return (
                                                          <div
                                                              key={index}
                                                              className="conditions"
                                                          >
                                                              <ResultRow
                                                                  updateResultById={
                                                                      updateResultById
                                                                  }
                                                                  removeResultById={
                                                                      removeResult
                                                                  }
                                                                  result={item}
                                                                  parameters={
                                                                      parameters
                                                                  }
                                                              />
                                                          </div>
                                                      )
                                                  },
                                              )
                                            : null}
                                        <ButtonWithIcon
                                            onClick={addResultRow}
                                            icon="/assets/images/icon+.png"
                                            green={true}
                                            value="Add Result"
                                        />
                                    </div>
                                </div>
                                <div style={{ width: '35%' }}>
                                    <label>
                                        Description
                                        <textarea
                                            value={entity?.description}
                                            onChange={({ target }) => {
                                                onEntityChange(
                                                    'description',
                                                    target.value,
                                                )
                                            }}
                                            placeholder="Description"
                                            style={{
                                                width: '100%',
                                                borderRadius: '7px',
                                                marginTop: '7px',
                                            }}
                                            rows="5"
                                            cols="25"
                                        />
                                    </label>
                                    <div className="historyWrapper">
                                        <div className="title">
                                            <span>History</span>
                                            <div className="wrapper">
                                                <Table
                                                    column={versionTableHeaders}
                                                    id={
                                                        window.location.pathname
                                                    }
                                                    data={versions}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
