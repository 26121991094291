import React, { useState } from 'react'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { VersionPopup } from './version-popup'
import './versions.scss'

export const Versions = ({ versions, onVersionChange, version, header }) => {
    const [showVersionsPopup, setShowVersionsPopup] = useState(false)
    return (
        <>
            <ButtonWithIcon
                value="Versions"
                green={true}
                onClick={() => setShowVersionsPopup(true)}
            />

            <VersionPopup
                isOpen={showVersionsPopup}
                setIsOpen={setShowVersionsPopup}
                tableHeaders={header}
                rows={versions}
                onVersionChange={onVersionChange}
            />
        </>
    )
}
