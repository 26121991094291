import React, { useCallback, useEffect, useState } from 'react'
import Table from '../Tables/Table/Table'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { handleError } from '../../../utils/handleErrors'

function ListPage({
    pg,
    deleteSelectedRows,
    addRows,
    insert,
    selctedRows,
    column,
    addColumn,
    editType,
    title,
    otherBtns,
    dataModelCall,
    dataCall,
    insertData,
    setShow,
    id,
    show,
    setSelectedRows,
    onRowValueChanged,
    btnName,
    tableColumns,
    name,
    CreatePopupComponent,
    FlowPanel,
    setOpenPanel,
    flowDetail,
    closeFlowDetail,
    setFlowDetail,
    showSettingsAction,
    editDataItem,
    editBtnStruc,
    addDataStorage,
    idr,
    savedBtn,
    HeaderComponent,
    triggerCall = undefined,
}) {
    const history = useHistory()
    const [stateDataModel, setStateDataModel] = useState([])
    const [state, setState] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    useEffect(() => {
        dataModelCall()
            ?.then((allData) => {
                setStateDataModel(allData)
                dataCall(allData)
                    .then((allFlow) => {
                        setState(allFlow)
                    })
                    .catch(handleError)
            })
            .catch(handleError)
    }, [triggerCall])

    const editScoreItem = useCallback(
        (item) => {
            history.push(`${window.location.pathname}/${item.data._id}`)
        },
        [history],
    )

    return (
        <div className="table-Wrapper">
            {showPopup ? (
                <CreatePopupComponent
                    isOpen={showPopup}
                    setStateDataModel={setStateDataModel}
                    setShowPopup={setShowPopup}
                    closePopUp={() => setShowPopup(false)}
                    state={state}
                    setState={setState}
                    stateDataModel={stateDataModel}
                    dataModelsList={stateDataModel.map((dm) => ({
                        id: dm.id,
                        value: dm._id,
                        name: dm.name,
                    }))}
                />
            ) : null}
            {(!window.location.pathname === '/data-models'
                ? state
                : stateDataModel) && (
                <Table
                    pg={pg}
                    Did={id}
                    insert={insert}
                    insertData={insertData}
                    editBtnStruc={editBtnStruc}
                    show={show}
                    setShow={setShow}
                    column1={column}
                    deleteSelectedRows={deleteSelectedRows}
                    selctedRows={selctedRows}
                    addRows={addRows}
                    addDataStorage={addDataStorage}
                    setSelectedRows={setSelectedRows}
                    name={name}
                    editType={editType}
                    addColumn={addColumn}
                    savedBtn={savedBtn}
                    onRowValueChanged={onRowValueChanged}
                    otherBtns={otherBtns}
                    idr={idr}
                    openDetail={
                        window.location.pathname === '/logs'
                            ? showSettingsAction
                            : window.location.pathname === '/published-flows' ||
                              column ||
                              idr
                            ? () => {}
                            : editScoreItem
                    }
                    editItem={
                        window.location.pathname === '/published-flows'
                            ? editDataItem
                            : () => {}
                    }
                    hesHeader
                    headerTitle={title}
                    handleAdd={setShowPopup}
                    addButtonName={btnName}
                    column={tableColumns}
                    data={
                        window.location.pathname === '/data-models' ||
                        window.location.pathname === '/logs'
                            ? stateDataModel
                            : column?.length
                            ? column
                            : state
                    }
                    withEditItem
                    id={window.location.pathname}
                    HeaderComponent={
                        HeaderComponent ? (
                            <HeaderComponent dataModels={stateDataModel} />
                        ) : null
                    }
                />
            )}
            {window.location.pathname === '/flows' ||
            window.location.pathname === '/published-flows' ||
            idr === 'user' ||
            idr === 'role' ? (
                <FlowPanel
                    state={state}
                    setState={setState}
                    flowDetail={flowDetail}
                    setFlowDetail={setFlowDetail}
                    setOpenPanel={setOpenPanel}
                    closeFlowDetail={closeFlowDetail}
                />
            ) : null}
        </div>
    )
}

export default ListPage
