import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { TextInput } from '../../Atoms/Inputs/Text'
import { useParams } from 'react-router-dom'
import { handleError } from '../../../utils/handleErrors'
import { getUserById } from '../../../store/user/api'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import Select from 'react-select'
import { getAllRole } from '../../../store/role/api'
import { getDataModel } from '../../../store/model/api'

const entityType = 'user'
const entityURL = 'users'
const defaultOption = { name: 'Choose', value: null }

export const User = () => {
    const { id } = useParams()
    const [entity, setEntity] = useState({
        dataModels: [],
    })
    const [roles, setRoles] = useState([{ name: 'Choose', value: null }])
    const [dataModels, setDataModels] = useState([
        { name: 'Choose', value: null },
    ])

    const updateInfo = (id) => {
        getUserById(id)
            .then((user) => {
                setEntity({ ...user, password: undefined })
            })
            .catch(handleError)
    }

    useEffect(() => {
        if (id) {
            updateInfo(id)
            getAllRole().then((_roles) => {
                const list = _roles.map((r) => {
                    return {
                        name: r.name,
                        value: r._id,
                    }
                })
                setRoles([defaultOption, ...list])
            })

            getDataModel().then((_dataModels) => {
                setDataModels([
                    ..._dataModels.map((r) => {
                        return {
                            label: r.name,
                            value: r._id,
                        }
                    }),
                ])
            })
        }
    }, [id])
    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }
    const generatePassword = () => {
        const a = Math.random().toString(36).slice(-8)
        onEntityChange('password', a)
    }
    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header
                        updateInfo={updateInfo}
                        entity={entity}
                        entityURL={entityURL}
                    />
                    <div className="single-table-wrapper rule-content  user-page parameter-individual-page">
                        <div className="inputsWrapper">
                            <div className="row first-row">
                                <TextInput
                                    label="Name"
                                    value={entity?.name}
                                    onChange={({ target }) => {
                                        onEntityChange('name', target.value)
                                    }}
                                    placeholder="Name"
                                />
                                <TextInput
                                    label="Email"
                                    value={entity?.email}
                                    onChange={({ target }) => {
                                        onEntityChange('email', target.value)
                                    }}
                                    placeholder="Email"
                                />
                                <TextInput
                                    label="Phone"
                                    value={entity?.phone}
                                    onChange={({ target }) => {
                                        onEntityChange('phone', target.value)
                                    }}
                                    placeholder="Phone"
                                />
                            </div>
                            <div className="row second-row">
                                <TextInput
                                    label="Password"
                                    value={entity?.password}
                                    onChange={({ target }) => {
                                        onEntityChange('password', target.value)
                                    }}
                                    placeholder="Password"
                                />
                                <div>
                                    <ButtonWithIcon
                                        onClick={generatePassword}
                                        green={true}
                                        value="Generate Password"
                                    />
                                </div>
                            </div>
                            <div className="row third-row">
                                <SelectWithLabelInput
                                    label="Role"
                                    defaultValue={entity?.role}
                                    options={roles}
                                    onChange={({ target }) => {
                                        onEntityChange('role', target.value)
                                    }}
                                />
                                <label className="multiSelect">
                                    Data Models
                                    <Select
                                        isMulti
                                        name="dataModels"
                                        options={dataModels}
                                        value={dataModels.filter((group) =>
                                            entity.dataModels.includes(
                                                group.value,
                                            ),
                                        )}
                                        onChange={(selectedOptions) => {
                                            const selectedValues =
                                                selectedOptions.map(
                                                    (option) => option.value,
                                                )
                                            onEntityChange(
                                                'dataModels',
                                                selectedValues,
                                            )
                                        }}
                                        classNamePrefix="select"
                                        placeholder="Select Data Models"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
