import React, { useState } from 'react'
import './single-page-content-header.scss'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { handleError } from '../../../utils/handleErrors'
import { ArchivePopup } from '../Popups/General/ArchivePopup'
import { archiveEntityById } from '../../../store/common/api'
import { useHistory } from 'react-router-dom'
import { Versions } from '../versions'
import editGreen from './../../../imgs/EditGreen.png'
import checked from './../../../imgs/check (1).png'
import { formatDate } from '../../../Services/dateFormat'

export const Header = ({
    entityType = 'script',
    versions = [],
    onEntityUpdate,
    setEntity,
    entity,
    header,
    entityURL = 'scripts',
}) => {
    const history = useHistory()
    const [archivePopup, setArchivePopup] = useState(false)
    const onVersionChange = (version) => {
        version && setEntity(versions.find((v) => v.version == version))
    }
    const onArchive = () => {
        archiveEntityById(entity[`${entityType}Id`], entityType)
            .then((res) => {
                history.push(`/${entityURL}`)
                setArchivePopup(false)
                setEntity(null)
            })
            .catch(handleError)
    }
    const headers = [
        {
            headerName: 'Version',
            field: 'version',
            filter: true,
            sort: true,
        },
        {
            headerName: 'Created By',
            field: 'createdBy',
            filter: true,
            sort: true,
        },
        {
            headerName: 'Created At',
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            field: 'Actions',
            filter: false,
            sort: false,
            cellRenderer: (params) => {
                return (
                    <img
                        src={editGreen}
                        style={{
                            cursor: 'pointer',
                            paddingRight: '5px',
                            paddingLeft: '15px',
                        }}
                    />
                )
            },
        },
        {
            field: 'Selected',
            filter: false,
            sort: false,
            cellRenderer: (params) => {
                return (
                    <>
                        {params?.data.version === entity.version ? (
                            <img
                                src={checked}
                                style={{
                                    cursor: 'pointer',
                                    paddingRight: '5px',
                                    paddingLeft: '15px',
                                }}
                            />
                        ) : null}
                    </>
                )
            },
        },
    ]
    return (
        <>
            <ArchivePopup
                isOpen={archivePopup}
                togglePanel={setArchivePopup}
                onDelete={onArchive}
            >
                Are you sure you want to delete <b>{entity?.name}</b> ?
            </ArchivePopup>
            <div className="single-page-content-header">
                <div className="title">
                    <span>{entity.name}</span>
                </div>
                <div className="actions">
                    <Versions
                        onVersionChange={onVersionChange}
                        header={headers}
                        versions={versions}
                        version={entity.version}
                    />
                    <ButtonWithIcon
                        onClick={() => setArchivePopup(true)}
                        icon="/assets/images/Delete.png"
                        red={true}
                        value="Remove"
                    />
                    <ButtonWithIcon
                        onClick={onEntityUpdate}
                        value="Update"
                        green={true}
                    />
                </div>
            </div>
        </>
    )
}
