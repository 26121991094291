import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

function normalizeFlows(response, dataModelsForNormalize) {
    if (response?.flows?.length) {
        const data = response?.flows.map((item) => {
            return {
                ...item.models[0],
                dataModelName: dataModelsForNormalize.find(
                    (dm) => dm._id === item.models[0].modelId,
                )?.name,
                _id: item._id,
            }
        })
        return data.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
        )
    }
    return []
}

function normalizeFlow(response, dataModelsForNormalize) {
    if (response.flow) {
        return {
            ...response.flow,
            _id: response.flow.flowId,
            dataModelName: dataModelsForNormalize.find(
                (dm) => dm._id === response.flow.modelId,
            )?.name,
            user: Users().getUserById(response.flow.userId)?.name,
        }
    }
    return []
}

// Flow
export function getAllFlow(dataModelsForNormalize) {
    const page = 1
    const limit = 1000
    return Api()
        .get(`api/flows/all?page=${page}&limit=${limit}`)
        .then((response) => normalizeFlows(response, dataModelsForNormalize))
}

export function createFlow(
    { slug, name, modelId, body },
    dataModelsForNormalize,
) {
    return Api()
        .post('api/flows/create', {
            slug,
            name,
            modelId,
            body,
        })
        .then((response) => normalizeFlow(response, dataModelsForNormalize))
}

const _normalizeFlow = (response) => {
    response.flow.nodes = response.flow.body.nodes
    response.flow.edges = response.flow.body.edges
    delete response.flow.body
    return response.flow
}

export function getFlowById(id) {
    return Api().get(`api/flows/${id}`).then(_normalizeFlow)
}

export function getFlowByIdWithAllData(id) {
    return Api()
        .get(`api/flows/${id}`)
        .then((response) => {
            response.publishedFlows = response.publishedFlows.sort(function (
                a,
                b,
            ) {
                return new Date(b.createdAt) - new Date(a.createdAt)
            })
            return response
        })
}

export function updateFlow({ slug, name, modelId, body, id }) {
    return Api()
        .put(`api/flows/update/${id}`, {
            slug,
            name,
            modelId,
            body,
        })
        .then(_normalizeFlow)
}

export function archiveFlowById(id) {
    return Api().patch(`api/flows/archive/${id}`)
}

export function publishFlow(flowId) {
    return Api().post('api/flows/publish', {
        flowId,
    })
}

export function publishedCode({ slug, version, apiKey, body }) {
    return Api().test(
        `api/flows/publish/${slug}/v${version}`,
        JSON.parse(body),
        { key: apiKey },
    )
}
