import { Handle, Position } from 'react-flow-renderer';
import s from './nodes.module.scss'

export function ValueSetterNode(props ) {
    return (
        <>
            <Handle type="target" position={Position.Top} />
            <div className={s.valueSetterNodeContent} >
                <label htmlFor="text">Value Setter Card: {props.data.label}</label>
            </div>
            <Handle type="source" position={Position.Bottom} />
        </>
    );
}