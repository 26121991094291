import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getEntityVersionById} from "../store/common/api";
import {handleError} from "../utils/handleErrors";

export const SinglePageHook =({entityType})=>{
    const {id} = useParams()
    const [entity, setEntity] = useState({})
    const [versions, setVersions] = useState([])

    useEffect(() => {
        if (id) {
            getEntityVersionById(id, entityType)
                .then((versions) => {
                    setVersions(versions)
                    setEntity(versions[0])
                })
                .catch(handleError)
        }
    }, [id])
    return {
        id,
        entity,
        setEntity,
        versions,
        setVersions
    }
}