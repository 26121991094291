import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

function normalizeIntegrationsDB(response, dataModelsForNormalize) {
    if (response?.['integration-dbs']) {
        const data = response?.['integration-dbs'].map((item) => {
            return {
                ...item.models[0],
                dataModelName: dataModelsForNormalize.find(
                    (dm) => dm._id === item.models[0].id,
                )?.name,
                _id: item._id,
                user: Users().getUserById(item.models[0].userId)?.name,
            }
        })
        return data.sort(
            (a, b) => Date.parse(a.createdAt) - Date.parse(a.createdAt),
        )
    }
    return []
}

function normalizeIntegrationDB(response, dataModelsForNormalize) {
    if (response.integration) {
        return {
            ...response.integration,
            _id: response.integration.integrationId,
            dataModelName: dataModelsForNormalize.find(
                (dm) => dm._id === response.integration.modelId,
            )?.name,
            user: Users().getUserById(response.integration.userId)?.name,
        }
    }
    return []
}

export function getAllIntegrationsDB(dataModelsForNormalize) {
    const page = 1
    const limit = 1000
    return Api()
        .get(`api/integration-dbs?page=${page}&limit=${limit}`)
        .then((response) =>
            normalizeIntegrationsDB(response, dataModelsForNormalize),
        )
}

export function createIntegrationDB(
    { name, id, modelId },
    dataModelsForNormalize,
) {
    return Api()
        .post('api/integration-dbs/create', {
            name,
            id,
            modelId,
        })
        .then((response) =>
            normalizeIntegrationDB(response, dataModelsForNormalize),
        )
}
