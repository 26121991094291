import { ButtonWithIcon } from '../../../Atoms/Buttons/ButtonWithIcon'
import { ButtonLighth } from '../../../Atoms/Buttons/ButtonLighth'
import './Table.css'
import { AgGridReact } from 'ag-grid-react'
import deletBtn from './../../../../imgs/Group 34677.png'
import editBtn from './../../../../imgs/Group 35264 (1).png'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import 'ag-grid-enterprise'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { LicenseManager } from 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import TableNavigation from '../../../TableNavigation/TableNavigation'
import { toast } from 'react-toastify'
import { getAllLogs } from '../../../../store/logs/api'

LicenseManager.setLicenseKey(
    process.env.REACT_APP_AG_GRID + '6375ce4e397ec3b0caec647570d74f25',
)
const suppressEnter = (params) => {
    var KEY_ENTER = 'Enter'
    var event = params.event
    var key = event.key
    var suppress = key === KEY_ENTER
    return suppress
}

const suppressNavigation = (params) => {
    var KEY_A = 'A'
    var KEY_C = 'C'
    var KEY_V = 'V'
    var KEY_D = 'D'
    var KEY_PAGE_UP = 'PageUp'
    var KEY_PAGE_DOWN = 'PageDown'
    var KEY_TAB = 'Tab'
    var KEY_LEFT = 'ArrowLeft'
    var KEY_UP = 'ArrowUp'
    var KEY_RIGHT = 'ArrowRight'
    var KEY_DOWN = 'ArrowDown'
    var KEY_F2 = 'F2'
    var KEY_BACKSPACE = 'Backspace'
    var KEY_ESCAPE = 'Escape'
    var KEY_SPACE = ' '
    var KEY_DELETE = 'Delete'
    var KEY_PAGE_HOME = 'Home'
    var KEY_PAGE_END = 'End'
    var event = params.event
    var key = event.key
    var keysToSuppress = [
        KEY_PAGE_UP,
        KEY_PAGE_DOWN,
        KEY_TAB,
        KEY_F2,
        KEY_ESCAPE,
    ]
    var editingKeys = [
        KEY_LEFT,
        KEY_RIGHT,
        KEY_UP,
        KEY_DOWN,
        KEY_BACKSPACE,
        KEY_DELETE,
        KEY_SPACE,
        KEY_PAGE_HOME,
        KEY_PAGE_END,
    ]
    if (event.ctrlKey || event.metaKey) {
        keysToSuppress.push(KEY_A)
        keysToSuppress.push(KEY_V)
        keysToSuppress.push(KEY_C)
        keysToSuppress.push(KEY_D)
    }
    if (!params.editing) {
        keysToSuppress = keysToSuppress.concat(editingKeys)
    }
    if (
        params.column.getId() === 'country' &&
        (key === KEY_UP || key === KEY_DOWN)
    ) {
        return false
    }
    var suppress = keysToSuppress.some(function (suppressedKey) {
        return suppressedKey === key || key.toUpperCase() === suppressedKey
    })
    return suppress
}

const suppressUpDownNavigation = (params) => {
    var key = params.event.key
    return key === 'ArrowUp' || key === 'ArrowDown'
}

export default function Table(props) {
    const {
        pg,
        show,
        addDataStorage = () => {},
        addRows,
        addColumn,
        insertData,
        insert,
        name,
        column1,
        selctedRows,
        id,
        idr,
        onRowValueChanged,
        Did,
        editBtnStruc,
        savedBtn,
        headerTitle,
        addButtonName,
        handleAdd,
        otherBtns,
        hesHeader,
        openDetail = () => {},
        data = [],
        column = [],
        deleteSelectedRows,
        setSelectedRows = () => {},
        selectedRows = [],
        editItem = () => {},
        onFilterChange = (cb) => {},
        HeaderComponent,
    } = props
    const gridRef = useRef()
    const [serverData, setServerData] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [page, setPage] = useState(1)
    const [rowCount, setRowCount] = useState('')
    const callData = (page) => {
        getAllLogs(page)
            .then((responce) => {
                setServerData(responce.logs)
                setPageCount(responce.totalPages)
                setRowCount(responce.currentPage)
            })
            .catch((e) => console.error)
    }
    useEffect(() => {
        if (window.location.pathname === '/logs') {
            callData(page)
        } else {
            setServerData([])
        }
    }, [])
    const history = useHistory()
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            editable: false,
            cellDataType: false,
            suppressKeyboardEvent: suppressNavigation,
            suppressHeaderKeyboardEvent: suppressUpDownNavigation,
        }
    }, [])

    const _onFilterModified = () => {
        if (id) {
            setTimeout(() => {
                if (gridRef.current) {
                    const filterState = JSON.stringify(
                        gridRef.current.api.getFilterModel(),
                    )
                    const sortingState = JSON.stringify(
                        gridRef.current.columnApi.getColumnState(),
                    )
                    localStorage.setItem(id + '_f', filterState)
                    localStorage.setItem(id + '_s', sortingState)
                }
            }, 500)
        } else {
            throw Error('id not found')
        }
    }

    useEffect(() => {
        onFilterChange(() => {
            setTimeout(() => {
                if (gridRef.current) {
                    gridRef.current.columnApi.autoSizeColumns()
                    gridRef.current.api.sizeColumnsToFit()
                }
            }, 100)
        })
    }, [])
    useEffect(() => {
        if (gridRef && selectedRows.length) {
            gridRef.current.api.forEachNode((node) =>
                node.setSelected(
                    !!node.data && selectedRows.includes(node.data.id),
                ),
            )
        }
    }, [selectedRows, data])

    const onSelectionChanged = useCallback((e) => {
        const selectedCars = e.api.getSelectedNodes().map((r) => r.data.n)
        setSelectedRows(selectedCars)
    }, [])

    return (
        <>
            <div className="tableWrapper">
                {hesHeader && !HeaderComponent ? (
                    <div
                        className="tableHeader"
                        style={
                            idr == 'user' || idr === 'role'
                                ? { justifyContent: 'space-between' }
                                : {}
                        }
                    >
                        {idr == 'user' || idr === 'role' ? (
                            <div className="roleNavigate">
                                <div
                                    onClick={() =>
                                        history.push('/permesionmanagment/user')
                                    }
                                    className={
                                        idr === 'user'
                                            ? 'ActiverRole'
                                            : 'roleNav'
                                    }
                                >
                                    User Management
                                </div>
                                <div
                                    onClick={() =>
                                        history.push('/permesionmanagment/role')
                                    }
                                    className={
                                        idr === 'role'
                                            ? 'ActiverRole'
                                            : 'roleNav'
                                    }
                                >
                                    Role Management
                                </div>
                            </div>
                        ) : (
                            <div className="tableHeaderText">{headerTitle}</div>
                        )}
                        <div className="tableHeaderButton">
                            {selctedRows?.length > 1 ? (
                                <img
                                    onClick={() => deleteSelectedRows()}
                                    src={deletBtn}
                                    style={{
                                        cursor: 'pointer',
                                        paddingRight: '10px',
                                        height: '59px',
                                    }}
                                />
                            ) : null}
                            {otherBtns ? (
                                <div
                                    style={{
                                        marginRight: '15px',
                                        height: '20px',
                                        width: '220px',
                                    }}
                                >
                                    <ButtonLighth
                                        onClick={() => addColumn()}
                                        value={otherBtns}
                                    />
                                </div>
                            ) : null}
                            {editBtnStruc ? (
                                <div
                                    style={{
                                        marginRight: '15px',
                                        height: '20px',
                                        width: '220px',
                                    }}
                                >
                                    <ButtonLighth
                                        onClick={() => {
                                            if (!column1.length) {
                                                history.push(`/update/${Did}`)
                                            } else {
                                                toast.error(
                                                    'cant change structure in this case',
                                                )
                                            }
                                        }}
                                        value={editBtnStruc}
                                    />
                                </div>
                            ) : null}
                            {savedBtn ? (
                                <div style={{ width: '220px' }}>
                                    <ButtonSmall
                                        onClick={
                                            !insert
                                                ? () =>
                                                      addDataStorage(
                                                          name,
                                                          column1,
                                                      )
                                                : () => insertData()
                                        }
                                        icon="/assets/images/icon+.png"
                                        value={savedBtn}
                                    />
                                    :
                                </div>
                            ) : null}
                            {addButtonName && (
                                <ButtonWithIcon
                                    onClick={() => handleAdd(true)}
                                    green={true}
                                    icon="/assets/images/icon+.png"
                                    value={addButtonName}
                                />
                            )}
                        </div>
                    </div>
                ) : HeaderComponent ? (
                    HeaderComponent
                ) : null}
                <div>
                    {data && (
                        <AgGridReact
                            style={{
                                height:
                                    window.location.pathname === '/datastore'
                                        ? 'maxcontent'
                                        : '100%',
                            }}
                            onRowDoubleClicked={editItem}
                            onRowClicked={(row) => {
                                if (!row.event.defaultPrevented) {
                                    openDetail(row)
                                }
                            }}
                            CellEditingStartedEvent={true}
                            ref={gridRef}
                            suppressPaginationPanel={
                                window.location.pathname === '/logs'
                                    ? true
                                    : false
                            }
                            className="ag-theme-alpine-dark"
                            columnDefs={column}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            paginationPageSize={pg ? pg : 10}
                            rowData={
                                window.location.pathname == '/logs'
                                    ? serverData
                                    : data
                            }
                            rowGroup={true}
                            rowSelection={'multiple'}
                            onRowValueChanged={onRowValueChanged}
                            editType={'fullRow'}
                            setsuppressCellFocus={true}
                            suppressRowClickSelection={false}
                            onSelectionChanged={onSelectionChanged}
                            suppressSizeToFit={true}
                            onGridReady={(params) => {
                                const filterState = localStorage.getItem(
                                    id + '_f',
                                )
                                const sortingState = localStorage.getItem(
                                    id + '_s',
                                )
                                if (filterState) {
                                    params.api.setFilterModel(
                                        JSON.parse(filterState),
                                    )
                                }
                                if (sortingState) {
                                    params.columnApi.applyColumnState({
                                        state: JSON.parse(sortingState),
                                        applyOrder: true,
                                    })
                                }
                            }}
                            onFilterChanged={() => {
                                _onFilterModified()
                            }}
                            onSortChanged={() => {
                                _onFilterModified()
                            }}
                            onColumnPinned={() => {
                                _onFilterModified()
                            }}
                            columnResized={() => {
                                _onFilterModified()
                            }}
                            onColumnEverythingChanged={() => {
                                _onFilterModified()
                            }}
                        ></AgGridReact>
                    )}
                    {window.location.pathname === '/logs' ? (
                        <TableNavigation
                            pageCount={pageCount}
                            page={page}
                            setPage={setPage}
                            rowCount={rowCount}
                            callData={callData}
                        />
                    ) : null}
                    {show ? (
                        <div
                            style={{
                                width: '100%',
                                justifyContent: 'end',
                                display: 'flex',
                                paddingTop: '10px',
                                paddingBottom: '20px',
                            }}
                        >
                            <img
                                src={editBtn}
                                onClick={() => addRows()}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}
