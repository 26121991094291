import { Handle, Position } from 'react-flow-renderer';
import s from './nodes.module.scss'

export function SegmentNode({ data }) {
  const handleStyle = { left: 10 };
  return (
    <>
      <Handle type="target" position={Position.Top} />
      <div className={s.segmentNodeContent} >
        <label htmlFor="text">Segment: {data.label}</label>
      </div>
      <div className={s.segmentNodePoints}>
        {data && data.children && data.children.map((item, index) => {
          let left = handleStyle.left * index;
          return <Handle key={item.sourceHandle} type="source" position={Position.Bottom} id={item.sourceHandle} style={{ left }} />
        })}
      </div>
    </>
  );
}