import React from 'react'
import style from './Navigtions.module.scss'

function TableNavigation({pageCount,page,setPage,rowCount,callData=()=>{}}) {
  return (
    <span className={style.navigationBar}>
        <span>
            <span>{rowCount*10-9}</span>
            <span>to</span>
            <span>{rowCount*10}</span>
            <span>of</span>
            <span>{pageCount*10}</span>
        </span>
        <span>
            <div aria-label="First Page" aria-disabled="true">
                <span style={{cursor:'pointer'}} onClick={()=>{callData(1)
                                    setPage(1)
                }}  unselectable="on"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="#ffff" fill-rule="evenodd" d="M5.5 4A.75.75 0 0 0 4 4v8a.75.75 0 0 0 1.5 0V4Zm2.81 4 3.47-3.47a.75.75 0 0 0-1.06-1.06l-4 4a.75.75 0 0 0 0 1.06l4 4a.75.75 0 1 0 1.06-1.06L8.31 8Z" clip-rule="evenodd"/></svg></span>
            </div>
            <div aria-label="Previous Page" aria-disabled="true">
                <span style={{cursor:'pointer'}} onClick={()=>{
                    callData(page==1?1:page-1)
                    setPage(page==1?1:page-1)
                    }}unselectable="on"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="#ffff" fill-rule="evenodd" d="M10.53 3.47a.75.75 0 0 1 0 1.06L7.06 8l3.47 3.47a.75.75 0 1 1-1.06 1.06l-4-4a.75.75 0 0 1 0-1.06l4-4a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd"/></svg></span>
            </div>
            <span>
                <span>Page</span>
                <span>{page}</span>
                <span>of</span>
                <span>{pageCount}</span>
            </span>
            <div aria-label="Next Page" aria-disabled="false" tabindex="0">
                <span style={{cursor:'pointer'}} onClick={()=>{
                            callData(page==pageCount?page:page+1)
                             setPage(page==pageCount?page:page+1)
                }}><svg xmlns="http://www.w3.org/2000/svg" color='white' width="18" height="18" fill=""><path fill="#ffff" fill-rule="evenodd" d="M5.47 3.47a.75.75 0 0 1 1.06 0l4 4a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06-1.06L8.94 8 5.47 4.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"/></svg></span>
            </div>
            <div aria-label="Last Page" aria-disabled="false" tabindex="0">
                <span style={{cursor:'pointer'}} onClick={()=>{callData(pageCount)
                                    setPage(pageCount)
                }} unselectable="on"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="#ffff" fill-rule="evenodd" d="M11.75 12.75a.75.75 0 0 0 .75-.75V4A.75.75 0 0 0 11 4v8c0 .414.336.75.75.75ZM5.78 3.47a.75.75 0 0 0-1.06 1.06L8.19 8l-3.47 3.47a.75.75 0 1 0 1.06 1.06l4-4a.75.75 0 0 0 0-1.06l-4-4Z" clip-rule="evenodd"/></svg></span>
            </div>
        </span>
    </span>


  )
}

export default TableNavigation