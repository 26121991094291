import React from 'react'
import { TextInput } from '../../Atoms/Inputs/Text'
import { Segment } from './value-setter-segment'

export const RenderCondition = ({
    treeId,
    level,
    stateLevelLength,
    item,
    stateCurrentVariables,
    addSegmentChild,
    toggleRow,
    setSegmentArchivePopup,
    removeVariable,
    updateVariable,
}) => {
    return (
        <div className="formTableRowWrapper">
            <div className="formTableRow">
                <div
                    className="formTableMainCell"
                    style={{ marginRight: (stateLevelLength - level) * -5 }}
                >
                    {item.children && item.children.length ? (
                        <div className="arrowDown">
                            <img
                                className={item.isOpen ? '' : 'arrowRight'}
                                onClick={() =>
                                    toggleRow(treeId, item.id, !item.isOpen)
                                }
                                src="/assets/images/ArrowDown.png"
                                alt="icon"
                            />
                        </div>
                    ) : (
                        <button
                            onClick={() => addSegmentChild(treeId, item.id)}
                            className="pluseBtn"
                        >
                            <img src="/assets/images/icon+.png" alt="icon" />
                        </button>
                    )}
                    <div className="formTableMainInput">
                        <TextInput
                            disabled
                            marginTop="0"
                            placeholder="Name"
                            value={item.name}
                        />
                    </div>
                </div>
                {!item.children &&
                    stateCurrentVariables &&
                    stateCurrentVariables.map((_variable) => {
                        return (
                            <div className="formTableInput" key={_variable.id}>
                                <TextInput
                                    marginTop="0"
                                    placeholder="Name"
                                    onChange={({ target }) =>
                                        updateVariable(
                                            treeId,
                                            item.id,
                                            _variable.name,
                                            target.value,
                                        )
                                    }
                                    value={item[_variable.name]}
                                />
                            </div>
                        )
                    })}
            </div>

            {item.children && item.children.length && item.isOpen && (
                <div className="formTableRowChildren">
                    <div className="formTableHeader">
                        <div className="formTableHeaderTrash">
                            <img
                                onClick={() => {
                                    setSegmentArchivePopup({
                                        treeId,
                                        conditionId: item.id,
                                    })
                                }}
                                src="/assets/images/trash.png"
                                alt="trash"
                            />
                        </div>
                        <div
                            className="formTableSegmentName"
                            style={{
                                marginRight:
                                    (stateLevelLength -
                                        item.children[0].level) *
                                    -5,
                            }}
                        >
                            Segment - {item.children[0].name}
                        </div>
                        {stateCurrentVariables &&
                            stateCurrentVariables.map((res) => {
                                return (
                                    <div
                                        key={res.id}
                                        className="formTableVariableNameWrapper"
                                    >
                                        <div className="nameLine"></div>
                                        <div className="formTableVariableName">
                                            {res.name}
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                    <Segment
                        stateLevelLength={stateLevelLength}
                        updateVariable={updateVariable}
                        stateCurrentTree={item.children[0]}
                        stateCurrentVariables={stateCurrentVariables}
                        removeVariable={removeVariable}
                        addSegmentChild={addSegmentChild}
                        toggleRow={toggleRow}
                        setSegmentArchivePopup={setSegmentArchivePopup}
                    />
                </div>
            )}
        </div>
    )
}
