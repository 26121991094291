import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { createValueSetter } from '../../../../store/valueSetter/api'
import { handleError } from '../../../../utils/handleErrors'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { SelectWithLabelInput } from '../../../Atoms/Inputs/SelectWithLabel'
import { TextInput } from '../../../Atoms/Inputs/Text'
import s from './CreateNewValuePopup.module.scss'

export const CreateNewValuePopup = ({
    closePopUp,
    setState,
    dataModelsList,
    setShowPopup,
    state,
}) => {
    const [name, setName] = useState()
    const [dataModel, setDataName] = useState(dataModelsList[0]?.value)

    const handleCreateFlow = () => {
        setShowPopup(false)
        createValueSetter(
            {
                name,
                modelId: dataModel,
                body: { tree: [], variables: [] },
            },
            dataModelsList,
        )
            .then((valueSetter) => {
                setState([valueSetter, ...state])
                toast.success('Successfully Created')
            })
            .catch(handleError)
    }

    return (
        <div className={s.popupWrapper}>
            <div className={s.popupBox}>
                <div className={s.popupHeader}>
                    <div className={s.popupName}>Create New Value Setter</div>
                    <div className={s.popupX}>
                        <img
                            onClick={closePopUp}
                            src="/assets/images/popupX.png"
                            alt="close"
                        />
                    </div>
                </div>

                <div className={s.popupForm}>
                    <div className={s.popupFormItem}>
                        <TextInput
                            placeholder="Name"
                            value={name}
                            onChange={({ target }) => {
                                setName(target.value)
                            }}
                        />
                    </div>
                    <div className={s.popupFormItem}>
                        <SelectWithLabelInput
                            options={dataModelsList}
                            onChange={({ target }) => {
                                setDataName(target.value)
                            }}
                        />
                    </div>
                </div>

                <div className={s.popupButtonsWrapper}>
                    <ButtonSmall onClick={handleCreateFlow} value="Create" />
                </div>
            </div>
        </div>
    )
}
