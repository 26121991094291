import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { TextInput } from '../../Atoms/Inputs/Text'

export const IntegrationHeaders = ({ setEntity, entity }) => {
    let headers = []
    try {
        headers =
            typeof entity.headers === 'string'
                ? JSON.parse(entity.headers)
                : entity.headers
    } catch (e) {
        console.log('IntegrationHeaders', e)
    }

    const updateHeaders = (headers) => {
        setEntity({
            ...entity,
            headers,
        })
    }
    const addFormItem = () => {
        updateHeaders([
            ...headers,
            {
                name: '',
                value: '',
            },
        ])
    }

    const removeSegmentItem = (index) => {
        updateHeaders(headers.filter((_, i) => index !== i))
    }
    const updateSegmentItem = (index, key, value) => {
        updateHeaders(
            headers.map((item, i) => {
                if (index === i) {
                    item[key] = value
                }
                return item
            }),
        )
    }
    const deleteOnClick =
        headers.length > 1 ? (index) => removeSegmentItem(index) : () => {}
    return (
        <>
            <div className="codeContent_right">
                <div className="formBody">
                    <div className="formWrapper">
                        {(headers || []).map((item, index) => {
                            return (
                                <div className="formItem" key={index}>
                                    <TextInput
                                        placeholder="Api Key"
                                        value={item.name}
                                        onChange={({ target }) => {
                                            updateSegmentItem(
                                                index,
                                                'name',
                                                target.value,
                                            )
                                        }}
                                    />
                                    <TextInput
                                        placeholder="Value"
                                        value={item.value}
                                        onChange={({ target }) => {
                                            updateSegmentItem(
                                                index,
                                                'value',
                                                target.value,
                                            )
                                        }}
                                    />
                                    <div
                                        onClick={() => deleteOnClick(index)}
                                        className="removeSegment"
                                    >
                                        -
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="addFormItem">
                <img
                    onClick={addFormItem}
                    alt="add"
                    src="/assets/images/addFormItem.png"
                />
            </div>
        </>
    )
}
