import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const RoleListHeader = () => {
    const history = useHistory()
    const handleAdd = () => {
        history.push(`${window.location.pathname}/create`)
    }
    return (
        <div className="tableHeader">
            <div className="tableHeaderText">Roles</div>
            <div className="tableHeaderButton">
                <ButtonWithIcon
                    onClick={handleAdd}
                    green={true}
                    icon="/assets/images/icon+.png"
                    value={'Add Role'}
                />
            </div>
            )
        </div>
    )
}
