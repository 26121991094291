import React, { memo } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import View from '../../../imgs/view.png'
import { useHistory } from 'react-router-dom'

export const CalculationRow = memo(
    ({
        parameters,
        parameter,
        updateCalculationById,
        removeCalculationById,
    }) => {
        const updateCalculation = (key, value) => {
            updateCalculationById({
                ...parameter,
                [key]: value,
            })
        }
        const removeCalculation = (id) => {
            removeCalculationById(id)
        }
        const history = useHistory()

        const viewCalculationBlock = (item) => {
            history.push(`/parameters/${item}?type=view`)
        }
        return (
            <div
                className="formResult"
                style={{
                    border: '1px solid #blue',
                }}
            >
                <div className="formItem">
                    <SelectWithLabelInput
                        options={parameters}
                        defaultValue={parameter.parameter}
                        onChange={({ target }) => {
                            updateCalculation('parameter', target.value)
                        }}
                    />
                    <div style={{ paddingTop: '11px', paddingLeft: '10px' }}>
                        <img
                            src={View}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                viewCalculationBlock(parameter.parameter)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                    </div>
                    <div
                        className="remove"
                        onClick={() => removeCalculation(parameter.id)}
                    >
                        -
                    </div>
                </div>
            </div>
        )
    },
)
