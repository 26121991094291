import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

function normalizeScorings(response, dataModelsForNormalize) {
    if (response?.scorings?.length) {
        const data = response?.scorings.map((item) => {
            return {
                ...item.models[0],
                dataModelName: dataModelsForNormalize.find(
                    (dm) => dm._id === item.models[0].modelId,
                )?.name,
                _id: item._id,
                user: Users().getUserById(item.models[0].userId)?.name,
            }
        })
        return data.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
        )
    }
    return []
}

function normalizeScore(response, dataModelsForNormalize) {
    if (response.scoring) {
        return {
            ...response.scoring,
            _id: response.scoring.scoringId,
            dataModelName: dataModelsForNormalize.find(
                (dm) => dm.value === response.scoring.modelId,
            )?.name,
            user: Users().getUserById(response.scoring.userId)?.name,
        }
    }
    return []
}

export function getAllScorings(dataModelsForNormalize) {
    const page = 1
    const limit = 1000
    return Api()
        .get(`api/scorings/all?page=${page}&limit=${limit}`)
        .then((response) => normalizeScorings(response, dataModelsForNormalize))
}

export function createScorings(
    { name, segments, results, modelId },
    dataModelsForNormalize,
) {
    return Api()
        .post(`api/scorings/create`, {
            name,
            segments,
            results,
            modelId,
        })
        .then((response) => normalizeScore(response, dataModelsForNormalize))
}

export function scoringsUpdate({
    name,
    scoringId,
    modelId,
    segments,
    results,
    propertyCode,
    propertyResult,
    propertyValue,
    initialScore,
}) {
    return Api().put(`api/scorings/update/${scoringId}`, {
        name,
        modelId,
        segments,
        results,
        propertyCode,
        propertyResult,
        propertyValue,
        initialScore,
    })
}
