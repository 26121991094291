import React, { useState } from 'react'
import { ButtonLighth } from '../../../Atoms/Buttons/ButtonLighth'
import { createScript } from '../../../../store/script/api'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { SelectWithLabelInput } from '../../../Atoms/Inputs/SelectWithLabel'
import { TextInput } from '../../../Atoms/Inputs/Text'
import s from './CreateNewScriptPopup.module.scss'
import { toast } from 'react-toastify'

const scriptCode = '/* here you can write Your script */'

export const CreateNewScriptPopup = ({
    isOpen,
    closePopUp,
    setState,
    dataModelsList,
    setShowPopup,
    stateDataModel,
    state,
}) => {
    const [scriptName, setScriptName] = useState()
    const [dataModel, setDataModel] = useState(dataModelsList[0]?.value)
    const createScripts = ({ scriptName, dataModel }) => {
        setShowPopup(false)
        createScript(
            {
                name: scriptName,
                body: scriptCode,
                modelId: dataModel,
            },
            stateDataModel,
        )
            .then((createScript) => {
                setState([createScript, ...state])
            })
            .catch((e) => toast.error(e))
    }

    return (
        <>
            {isOpen && (
                <div className={s.popupWrapper}>
                    <div className={s.popupBox}>
                        <div className={s.popupHeader}>
                            <div className={s.popupName}>
                                Create New Parameter
                            </div>
                            <div className={s.popupX}>
                                <img
                                    onClick={closePopUp}
                                    src="/assets/images/popupX.png"
                                    alt="close"
                                />
                            </div>
                        </div>

                        <div className={s.popupForm}>
                            <div className={s.popupFormItem}>
                                <TextInput
                                    label="Script Name"
                                    placeholder="Script Name"
                                    value={scriptName}
                                    onChange={({ target }) => {
                                        setScriptName(target.value)
                                    }}
                                />
                            </div>
                            <div className={s.popupFormItem}>
                                <SelectWithLabelInput
                                    label="Data Model"
                                    options={dataModelsList}
                                    defaultValue={dataModel}
                                    onChange={({ target }) => {
                                        setDataModel(target.value)
                                    }}
                                />
                            </div>
                        </div>

                        <div className={s.popupButtonsWrapper}>
                            <ButtonLighth onClick={closePopUp} value="Cancel" />
                            <ButtonSmall
                                onClick={() => {
                                    createScripts({
                                        scriptName,
                                        dataModel,
                                    })
                                }}
                                value="Create"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
