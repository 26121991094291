import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { formatDate } from '../../../Services/dateFormat'
import Table from '../../Organisms/Tables/Table/Table'

export const RuleDetails = ({ details, versions, close }) => {
    const versionTableHeaders = [
        {
            headerName: 'Version',
            field: 'version',
            filter: true,
            sort: true,
        },
        {
            headerName: 'Created By',
            field: 'createdBy',
            filter: true,
            sort: true,
        },
        {
            headerName: 'Created At',
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            headerName: 'Approver',
            field: 'createdBy',
            filter: true,
            sort: true,
        },
        {
            field: 'Actions',
            filter: false,
            sort: false,
            cellRenderer: (params) => {
                return (
                    <span style={{ color: 'rgb(0 198 162 / 69%)' }}>View</span>
                )
            },
        },
    ]
    return (
        <div className="editWrapper">
            <div className="editHeader">
                <div className="editTitle">Description</div>
                <div className="close">
                    <img
                        alt="x"
                        src="assets/images/settingsX.png"
                        onClick={close}
                    />
                </div>
            </div>
            <div className="editBox">
                <ul className="infoList">
                    <li>
                        <div className="infoListName">
                            {details.description}
                        </div>
                    </li>
                </ul>
                <div className="historyWrapper">
                    <div className="title">
                        <div className="editHeader">
                            <div className="editTitle">History</div>
                        </div>
                        <div className="wrapper">
                            <Table
                                column={versionTableHeaders}
                                id={window.location.pathname + '1'}
                                data={versions}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
