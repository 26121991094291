import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import '../../../Styles/list-Page.scss'
import editGreen from './../../../imgs/EditGreen.png'
import Delete from './../../../imgs/recycle.png'
import View from './../../../imgs/view.png'
import setingGreen from './../../../imgs/SettingGreen.png'
import { getDataById, getDataModel } from '../../../store/model/api'
import { handleError } from '../../../utils/handleErrors'
import { FlowDetail } from '../FlowList/FlowDetail'
import { formatDate } from '../../../Services/dateFormat'
import ListPage from '../../Organisms/ListPage/ListPage'
import { ProductCatalogListHeader } from './ProductCatalogListHeader'
import { ProductCatalogDetails } from './ProductCatalogDetails'
import { getEntityVersionById } from '../../../store/common/api'
import { ArchivePopup } from '../../Organisms/Popups/General/ArchivePopup'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    archiveProductCatalogById,
    getProductCatalogsByDataModelId,
} from '../../../store/productCatalog/api'
const entityType = 'catalog'
export const ProductCatalogList = (props) => {
    const [showPopup, setShowPopup] = useState(false)
    const [archivePopupId, setArchivePopupId] = useState(null)
    const [name, setName] = useState()
    const [key, setKey] = useState()
    const [versions, setVersions] = useState()
    const [dataModel, setDataModel] = useState()
    const [dataModels, setDataModels] = useState([])
    const [productCatalogDetail, setProductCatalogDetail] = useState(null)
    const DIRECTIONS = {
        1: 'Personal Loan',
    }
    const STATUSES_BY_KEY = {
        0: 'Archived',
        1: 'Draft',
        2: 'In review',
        3: 'Approved',
        4: 'Declined',
    }
    const history = useHistory()

    useEffect(() => {
        getDataModel().then((_dataModels) => {
            setDataModels(_dataModels)
            setDataModel(_dataModels?.[0]?._id)
        })
    }, [])
    const openProductCatalogDetail = (id) => {
        getEntityVersionById(id, entityType)
            .then((versions) => {
                setVersions(versions)
                if (versions && versions.length > 0) {
                    setProductCatalogDetail(versions[0])
                }
            })
            .catch(handleError)
    }
    const viewProductCatalog = (item) => {
        history.push(`${window.location.pathname}/${item.data._id}?type=view`)
    }

    const deleteProductCatalog = () => {
        archiveProductCatalogById(archivePopupId)
            .then((versions) => {
                setArchivePopupId(null)
                setVersions(versions)
                if (versions && versions.length > 0) {
                    setVersions(versions[0])
                }
                toast.success('Product Catalog deleted successfully')
            })
            .catch(handleError)
    }

    const editDataItem = (item) => {
        getDataById({ id: item.data.modelId })
            .then((dataById) => {
                setShowPopup(true)
                setName(item.data.name)
                setVersions(item.data.version)
                setKey(item.data.key)
            })
            .catch(handleError)
    }
    const closeProductCatalogDetail = () => {
        setProductCatalogDetail(false)
    }

    const productCatalogTableColumns = [
        { field: 'name', filter: true },
        {
            field: 'direction',
            filter: true,
            valueGetter: (params) => {
                return DIRECTIONS[params.data.direction]
            },
        },

        {
            field: 'Sub Products Count',
            filter: true,
            valueGetter: (params) => {
                return 0
            },
        },
        {
            headerName: 'Last Modified By',
            field: 'user',
            filter: 'agDateColumnFilter',
        },
        {
            headerName: 'Last Modified Date',
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            field: 'Actions',
            cellRenderer: (params) => {
                return (
                    <div className="icons">
                        <img
                            src={editGreen}
                            onClick={() => editDataItem(params)}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={setingGreen}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                openProductCatalogDetail(params.data._id)
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                        <img
                            src={Delete}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setArchivePopupId(params.data._id)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={View}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                viewProductCatalog(params)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                    </div>
                )
            },
        },
    ]
    return (
        <>
            <ArchivePopup
                isOpen={!!archivePopupId}
                togglePanel={setArchivePopupId}
                onDelete={() => deleteProductCatalog()}
            ></ArchivePopup>
            <div className={'tableWrapper'}>
                {productCatalogDetail ? (
                    <ProductCatalogDetails
                        details={productCatalogDetail}
                        versions={versions}
                        close={() => {
                            setProductCatalogDetail(null)
                        }}
                    />
                ) : null}
                {dataModel ? (
                    <ListPage
                        pg="20"
                        triggerCall={dataModel}
                        dataModelCall={getDataModel}
                        dataCall={() =>
                            getProductCatalogsByDataModelId(
                                { modelId: dataModel },
                                dataModels,
                            )
                        }
                        tableColumns={productCatalogTableColumns}
                        parameterPanel={FlowDetail}
                        productCatalogDetail={productCatalogDetail}
                        setProductCatalogDetail={setProductCatalogDetail}
                        closeProductCatalogDetail={closeProductCatalogDetail}
                        setOpenPanel={props.setOpenPanel}
                        editDataItem={editDataItem}
                        HeaderComponent={() => (
                            <ProductCatalogListHeader
                                dataModel={dataModel}
                                setDataModel={setDataModel}
                                dataModels={dataModels}
                            />
                        )}
                    />
                ) : null}
            </div>
        </>
    )
}
