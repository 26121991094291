import React, { useState, useCallback, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { TextInput } from '../../Atoms/Inputs/Text'
import { useParams } from 'react-router-dom'
import { getEntityVersionById } from '../../../store/common/api'
import { handleError } from '../../../utils/handleErrors'
import { formatDate } from '../../../Services/dateFormat'
import { SubProductCatalogHistoryChangesPopup } from './SubProductCatalogHistoryChangesPopup'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { v4 } from 'uuid'
import { getParametersByDataModelId } from '../../../store/parameter/api'
import { getRulesByDataModelId } from '../../../store/rule/api'
import { RuleRow } from './RuleRow'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { ParameterRow } from './ParameterRow'
import { getProductCatalogsByDataModelId } from '../../../store/productCatalog/api'
import { CalculationRow } from './CalculationRow'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const entityType = 'subCatalog'
const entityURL = 'sub-product-catalogs'

const ItemType = {
    PARAMETER: 'PARAMETER',
    CALCULATION: 'CALCULATION',
    RULE: 'RULE',
}

const DraggableParameterRow = ({
    parameter,
    index,
    moveItem,
    updateParameterById,
    removeParameterById,
    parameters,
}) => {
    const [, ref] = useDrag({
        type: ItemType.PARAMETER,
        item: { index },
    })

    const [, drop] = useDrop({
        accept: ItemType.PARAMETER,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index, 'globalParameters')
                draggedItem.index = index
            }
        },
    })

    return (
        <div ref={(node) => ref(drop(node))} className="conditions">
            <ParameterRow
                parameter={parameter}
                parameters={parameters}
                updateParameterById={updateParameterById}
                removeParameterById={removeParameterById}
            />
        </div>
    )
}

// Draggable Item Component for Calculation Blocks
const DraggableCalculationRow = ({
    calculation,
    index,
    moveItem,
    updateCalculationById,
    removeCalculationById,
    parameters,
}) => {
    const [, ref] = useDrag({
        type: ItemType.CALCULATION,
        item: { index },
    })

    const [, drop] = useDrop({
        accept: ItemType.CALCULATION,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index, 'calculationBlocks')
                draggedItem.index = index
            }
        },
    })

    return (
        <div ref={(node) => ref(drop(node))} className="conditions">
            <CalculationRow
                parameter={calculation}
                parameters={parameters}
                updateCalculationById={updateCalculationById}
                removeCalculationById={removeCalculationById}
            />
        </div>
    )
}
// Draggable Item Component for Rules
const DraggableRuleRow = ({
    rule,
    index,
    moveItem,
    rules,
    updateRuleById,
    removeRuleById,
}) => {
    const [, ref] = useDrag({
        type: ItemType.RULE,
        item: { index },
    })

    const [, drop] = useDrop({
        accept: ItemType.RULE,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index, 'rules')
                draggedItem.index = index
            }
        },
    })

    return (
        <div ref={(node) => ref(drop(node))} className="conditions">
            <RuleRow
                rule={rule}
                rules={rules}
                removeRuleById={removeRuleById}
                updateRuleById={updateRuleById}
            />
        </div>
    )
}

export const SubProductCatalog = () => {
    const { id } = useParams()
    const [entity, setEntity] = useState({
        rules: [],
        globalParameters: [],
        calculationBlocks: [],
    })
    const [versions, setVersions] = useState([])
    const [openRowPopup, setOpenRowPopup] = useState(null)
    const versionTableHeaders = [
        {
            headerName: 'Version',
            field: 'version',
            filter: true,
            sort: true,
        },
        {
            headerName: 'Created By',
            field: 'createdBy',
            filter: true,
            sort: true,
        },
        {
            headerName: 'Created At',
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            headerName: 'Approver',
            field: 'createdBy',
            filter: true,
            sort: true,
        },
        {
            field: 'Actions',
            filter: false,
            sort: false,
            cellRenderer: (params) => {
                return (
                    <span
                        onClick={() => setOpenRowPopup(params.data.id)}
                        style={{ color: 'rgb(0 198 162 / 69%)' }}
                    >
                        View
                    </span>
                )
            },
        },
    ]
    const [dataModels, setDataModels] = useState([])
    const [parameters, setParameters] = useState([
        { name: 'Choose', value: null },
    ])
    const [productCatalogs, setProductCatalogs] = useState([
        { name: 'Choose', value: null },
    ])
    const [globalParameters, setGlobalParameters] = useState([
        { name: 'Choose', value: null },
    ])
    const [rules, setRules] = useState([{ name: 'Choose', value: null }])
    const [calculationBlocks, setCalculationBlocks] = useState([
        { name: 'Choose', value: null },
    ])

    const updateInfo = (id) => {
        getEntityVersionById(id, entityType)
            .then((versions) => {
                setVersions(versions)
                if (versions && versions.length > 0) {
                    setEntity(versions[0])
                }
            })
            .catch(handleError)
    }

    useEffect(() => {
        updateInfo(id)
    }, [])

    useEffect(() => {
        // Check if modelId is defined before fetching parameters
        if (entity?.modelId) {
            getProductCatalogsByDataModelId(
                { modelId: entity.modelId },
                dataModels,
            ).then((_catalogs) => {
                const list = _catalogs.map((item) => {
                    return { name: item.name, value: item._id }
                })
                setProductCatalogs([{ name: 'Choose', value: null }, ...list])
            })
            getParametersByDataModelId(
                { modelId: entity.modelId },
                dataModels,
            ).then((_parameters) => {
                const list = _parameters
                    .filter((item) => item.group != 11 && item.type == 1)
                    .map((item) => {
                        return { name: item.name, value: item._id }
                    })
                setParameters([{ name: 'Choose', value: null }, ...list])
                const list1 = _parameters
                    .filter((item) => item.group === 11 && item.type == 1)
                    .map((item) => {
                        return { name: item.name, value: item._id }
                    })
                setGlobalParameters([{ name: 'Choose', value: null }, ...list1])
                const list2 = _parameters
                    .filter((item) => item.type != 1) // Filter items where group equals 11
                    .map((item) => {
                        return { name: item.name, value: item._id }
                    })

                setCalculationBlocks([
                    { name: 'Choose', value: null },
                    ...list2,
                ])
            })
            getRulesByDataModelId({ modelId: entity.modelId }, dataModels).then(
                (_rules) => {
                    const list = _rules.map((item) => {
                        return { name: item.name, value: item._id }
                    })
                    setRules([{ name: 'Choose', value: null }, ...list])
                },
            )
        }
    }, [entity.modelId, dataModels])
    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }
    const updateRuleById = (RuleEntity) => {
        setEntity({
            ...entity,
            rules: entity.rules.map((rule) => {
                if (rule.id === RuleEntity.id) {
                    return RuleEntity
                }
                return rule
            }),
        })
    }

    const addRuleRow = () => {
        setEntity({
            ...entity,
            rules: [
                ...entity.rules,
                {
                    id: v4(),
                    rule: '',
                },
            ],
        })
    }
    const removeRule = (id) => {
        setEntity({
            ...entity,
            rules: entity.rules.filter((rule) => rule.id !== id),
        })
    }

    const updateParameterById = (ParameterEntity) => {
        setEntity({
            ...entity,
            globalParameters: entity.globalParameters.map((parameter) => {
                if (parameter.id === ParameterEntity.id) {
                    return ParameterEntity
                }
                return parameter
            }),
        })
    }

    const addParameterRow = () => {
        setEntity({
            ...entity,
            globalParameters: [
                ...entity.globalParameters,
                {
                    id: v4(),
                    parameter: '',
                    value: '',
                },
            ],
        })
    }
    const removeParameter = (id) => {
        setEntity({
            ...entity,
            globalParameters: entity.globalParameters.filter(
                (parameter) => parameter.id !== id,
            ),
        })
    }

    const updateCalculationById = (CalculationEntity) => {
        setEntity({
            ...entity,
            calculationBlocks: entity.calculationBlocks.map((parameter) => {
                if (parameter.id === CalculationEntity.id) {
                    return CalculationEntity
                }
                return parameter
            }),
        })
    }
    const addCalculationRow = () => {
        setEntity({
            ...entity,
            calculationBlocks: [
                ...entity.calculationBlocks,
                {
                    id: v4(),
                    parameter: '',
                },
            ],
        })
    }
    const removeCalculation = (id) => {
        setEntity({
            ...entity,
            calculationBlocks: entity.calculationBlocks.filter(
                (parameter) => parameter.id !== id,
            ),
        })
    }

    const moveItem = (fromIndex, toIndex, listName) => {
        const updatedList = [...entity[listName]]
        const [movedItem] = updatedList.splice(fromIndex, 1)
        updatedList.splice(toIndex, 0, movedItem)
        setEntity({
            ...entity,
            [listName]: updatedList,
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header
                        updateInfo={updateInfo}
                        entity={entity}
                        entityURL={entityURL}
                    />
                    {openRowPopup ? (
                        <SubProductCatalogHistoryChangesPopup
                            id={openRowPopup}
                            close={() => setOpenRowPopup(null)}
                        />
                    ) : null}
                    <div className="single-table-wrapper rule-content parameter-individual-page">
                        <div className="inputsWrapper">
                            <div className="row first-row">
                                <TextInput
                                    label="Name"
                                    value={entity?.name}
                                    onChange={({ target }) => {
                                        onEntityChange('name', target.value)
                                    }}
                                    placeholder="Name"
                                />
                                <TextInput
                                    label="Internal Id"
                                    value={entity?.internalId}
                                    onChange={({ target }) => {
                                        onEntityChange(
                                            'internalId',
                                            target.value,
                                        )
                                    }}
                                    placeholder="Internal Id"
                                />
                                <SelectWithLabelInput
                                    label="Parent"
                                    defaultValue={entity?.parent}
                                    onChange={({ target }) => {
                                        onEntityChange('type', target.value)
                                    }}
                                    options={productCatalogs}
                                />
                                <label>
                                    Description
                                    <textarea
                                        value={entity?.description}
                                        onChange={({ target }) => {
                                            onEntityChange(
                                                'description',
                                                target.value,
                                            )
                                        }}
                                        placeholder="Description"
                                        style={{
                                            width: '100%',
                                            borderRadius: '7px',
                                            marginTop: '7px',
                                        }}
                                        rows="2"
                                        cols="25"
                                    />
                                </label>
                            </div>
                            <DndProvider backend={HTML5Backend}>
                                <div className="row second-row sub-catalog-main-content">
                                    <div className="catalog-first-row">
                                        <div className="parameters">
                                            <div className="resultTitle">
                                                {' '}
                                                Global Parameters
                                            </div>
                                            {entity.globalParameters.map(
                                                (item, index) => (
                                                    <DraggableParameterRow
                                                        key={item.id}
                                                        index={index}
                                                        parameter={item}
                                                        moveItem={moveItem}
                                                        updateParameterById={
                                                            updateParameterById
                                                        }
                                                        removeParameterById={
                                                            removeParameter
                                                        }
                                                        parameters={
                                                            globalParameters
                                                        }
                                                    />
                                                ),
                                                // return (
                                                //     <div
                                                //         key={index}
                                                //         className="conditions"
                                                //     >
                                                //         <ParameterRow
                                                //             updateParameterById={
                                                //                 updateParameterById
                                                //             }
                                                //             removeParameterById={
                                                //                 removeParameter
                                                //             }
                                                //             parameter={item}
                                                //             parameters={
                                                //                 globalParameters
                                                //             }
                                                //         />
                                                //     </div>
                                                // )
                                            )}
                                            <ButtonWithIcon
                                                onClick={addParameterRow}
                                                icon="/assets/images/icon+.png"
                                                green={true}
                                                value="Add Parameter"
                                            />
                                        </div>
                                        <div className="calculation-blocks">
                                            <div className="resultTitle">
                                                {' '}
                                                Calculation Blocks
                                            </div>
                                            {entity.calculationBlocks.map(
                                                (item, index) => (
                                                    // {
                                                    //     return (
                                                    //         <div
                                                    //             key={index}
                                                    //             className="conditions"
                                                    //         >
                                                    //             <CalculationRow
                                                    //                 updateCalculationById={
                                                    //                     updateCalculationById
                                                    //                 }
                                                    //                 removeCalculationById={
                                                    //                     removeCalculation
                                                    //                 }
                                                    //                 parameter={item}
                                                    //                 parameters={
                                                    //                     calculationBlocks
                                                    //                 }
                                                    //             />
                                                    //         </div>
                                                    //     )
                                                    // },
                                                    <DraggableCalculationRow
                                                        key={item.id}
                                                        index={index}
                                                        calculation={item}
                                                        moveItem={moveItem}
                                                        updateCalculationById={
                                                            updateCalculationById
                                                        }
                                                        removeCalculationById={
                                                            removeCalculation
                                                        }
                                                        parameter={item}
                                                        parameters={
                                                            calculationBlocks
                                                        }
                                                    />
                                                ),
                                            )}
                                            <ButtonWithIcon
                                                onClick={addCalculationRow}
                                                icon="/assets/images/icon+.png"
                                                green={true}
                                                value="Add Calculation Block"
                                            />
                                        </div>
                                    </div>
                                    <div className="rules">
                                        <div className="resultTitle">
                                            {' '}
                                            Rules
                                        </div>
                                        {entity.rules.map((item, index) => (
                                            // {
                                            //     return (
                                            //         <div
                                            //             key={index}
                                            //             className="conditions"
                                            //         >
                                            //             <RuleRow
                                            //                 updateRuleById={
                                            //                     updateRuleById
                                            //                 }
                                            //                 removeRuleById={
                                            //                     removeRule
                                            //                 }
                                            //                 rule={item}
                                            //                 rules={rules}
                                            //             />
                                            //         </div>
                                            //     )
                                            // }
                                            <DraggableRuleRow
                                                key={item.id}
                                                index={index}
                                                rule={item}
                                                moveItem={moveItem}
                                                updateRuleById={updateRuleById}
                                                removeRuleById={removeRule}
                                                rules={rules}
                                            />
                                        ))}
                                        <ButtonWithIcon
                                            onClick={addRuleRow}
                                            icon="/assets/images/icon+.png"
                                            green={true}
                                            value="Add Rule"
                                        />
                                    </div>
                                </div>
                            </DndProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
