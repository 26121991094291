import React from "react";
import s from './CloseWrapper.module.scss'

export default function CloseWrapper({ isOpen, onClose , children}) {
    return (
        <div
            onClick={onClose}
            className={isOpen ? s.closeWrapper : s.noneWrapper}
        >
            {children}
        </div>
    )
}