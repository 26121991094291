import React, { useState } from 'react'
import { createIntegrationDB } from '../../../../store/integrationDB/api'
import { SelectWithLabelInput } from '../../../Atoms/Inputs/SelectWithLabel'
import { toast } from 'react-toastify'
import { handleError } from '../../../../utils/handleErrors'
import { ButtonLighth } from '../../../Atoms/Buttons/ButtonLighth'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { TextInput } from '../../../Atoms/Inputs/Text'
import s from './CreateNewIntegrationDBPopup.module.scss'

export const CreateNewIntegrationDBPopup = ({
    closePopUp,
    setShowPopup,
    state,
    setState,
    dataModelsList,
}) => {
    const [integrationDBName, setIntegrationDBName] = useState('')
    const [modelId, setModelId] = useState(dataModelsList[0]?.value)
    const handleCreateIntegraion = ({ id, name, body, modelId }) => {
        setShowPopup(false)
        createIntegrationDB(
            {
                name: integrationDBName,
                id: id,
                body,
                modelId,
            },
            dataModelsList,
        )
            .then((createIntegrationDB) => {
                setState([createIntegrationDB, ...state])
                toast.success('Successfully Created')
            })
            .catch(handleError)
    }

    return (
        <div className={s.popupWrapper}>
            <div className={s.popupBox}>
                <div className={s.popupHeader}>
                    <div className={s.popupName}>Create New Integration DB</div>
                    <div className={s.popupX}>
                        <img
                            onClick={closePopUp}
                            src="/assets/images/popupX.png"
                            alt="close"
                        />
                    </div>
                </div>

                <div className={s.popupForm}>
                    <div className={s.popupFormItem}>
                        <TextInput
                            label="Integration DB Name"
                            placeholder="Integration DB Name"
                            value={integrationDBName}
                            onChange={({ target }) => {
                                setIntegrationDBName(target.value)
                            }}
                        />
                    </div>
                </div>
                <div className={s.popupFormSelect1}>
                    <SelectWithLabelInput
                        label="Data Model"
                        options={dataModelsList}
                        onChange={({ target }) => {
                            setModelId(target.value)
                        }}
                    />
                </div>
                <div className={s.popupButtonsWrapper}>
                    <ButtonLighth onClick={closePopUp} value="Cancel" />
                    <ButtonSmall
                        onClick={() => {
                            handleCreateIntegraion({
                                integrationDBName,
                                modelId,
                            })
                        }}
                        value="Create"
                    />
                </div>
            </div>
        </div>
    )
}
