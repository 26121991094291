import React from 'react';
import { CreateNewIntegrationPopup } from '../../Organisms/Popups/CreateNewIntegrationPopup';
import { createIntegrations, getAllIntegrations } from '../../../store/integration/api';
import { integrationTableColumn } from '../../../utils/tableColumns';
import { getDataModel } from '../../../store/model/api'
import ListPage from '../../Organisms/ListPage/ListPage';

export const IntegrationList = () => {
    return (
          <>
                <ListPage 
                 title="Integration" 
                 dataModelCall={getDataModel}  
                 dataCall={getAllIntegrations} 
                 tableColumns={integrationTableColumn}
                 CreatePopupComponent = {CreateNewIntegrationPopup}
                 btnName="Create New Integration"
              />
          </>
       

    )
}