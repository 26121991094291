import React, { useCallback, useEffect, useState, useRef } from 'react';
import ListPage from '../../Organisms/ListPage/ListPage';
import 'react-toastify/dist/ReactToastify.css';
import DeletIcon from './../../../imgs/Delete.png'
import { CreateNewScriptPopup } from '../../Organisms/Popups/CreateNewScriptPopup';
import MonacoEditor from '@monaco-editor/react';
import { formatDate } from "./../../../Services/dateFormat";
import { CreateNewDataStore } from '../../Organisms/Popups/CreateNewDataStorePopup/CreateNewDataStorePopup';
import Editor from '@monaco-editor/react';
import { createScript, getAllScripts } from '../../../store/script/api';
import { useParams } from 'react-router-dom';
import { TextInput } from '../../Atoms/Inputs/Text';
import { ModalEditObject } from './ModalEditObject';
import { createDataStorage, getDataStorageById, updateDataStorage } from '../../../store/dataStorage/api';
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel';
import Switch from '../role-pages/Switch';
import { getDataModel } from '../../../store/model/api';
import { handleError } from '../../../utils/handleErrors';
import { toast } from 'react-toastify';

// const editDefalutValue = {
//   boolean:<SelectWithLabelInput options={['false','true']} defaultValue={false} onChange={({target})=>editColumn(id,JSON.parse(target.value),'columnType')}/>,

// }
export const UpdateDataStorage = () => {
  const [name, setName] = useState('')
  const [column, setColumn] = useState([])
  const { id } = useParams()
  const [rowId,setRowId] = useState('')
  const [key,setKey] = useState('')
  const [objectValue,setObjectValue] = useState('')
  const [showPopup,setShowPopup] = useState(false)


  const handleInputChange = (event) => {
    const inputText = event.target.value;
    try {
      JSON.parse(inputText);
      // setIsValidJson(true);
      return true
    } catch (error) {
      toast.error("isNot Valid Object")
      console.error(error)
      return false
      // setIsValidJson(false);
    }
    //  try {
    //    JSON.parse(inputText);
    //    setIsValidJson(true);
    //  } catch (e) {
    //    setIsValidJson(false);
    //  }


  };






  const DataStoreColumn = [
    {
      headerName: 'Name', field: "key", cellRenderer: (params) => {
        const id = params.data?._id
        return <div style={{ width: '150px', height: '60px' }}>
          <TextInput marginTop={'4px'} value={params.value} onChange={({ target }) => params.setValue(target.value)} onBlur={({ target }) => editColumn(id, target.value, 'key')} />

        </div>
      }
    },
    {
      headerName: 'Type', field: "columnType", cellRenderer: (params) => {
        const id = params.data?._id
        const value = params.data?.columnType
        return <div style={{ width: '150px', height: '60px' }}><SelectWithLabelInput marginTop={'4px'} options={["string", 'number', 'boolean', 'object']} defaultValue={params.data?.columnType} onChange={({ target }) => editColumn(id, target.value, 'columnType')} /></div>
      },
    },
    {
      headerName: 'Default Value', field: 'defaultValue', cellRenderer: (params) => {
        const id = params.data?._id
        const type = params.data?.columnType
        return <div style={{ width: '200px', height: '60px', marginBottom: '5px' }}>
          {type == 'object' ?
            <textarea
              style={{ width: '100%', height: '52px', borderRadius: '7px', marginTop: '7px' }}
              rows="5"
              cols="25"
              onClick={(e)=>{
                setObjectValue(e.target.value)
                setRowId(id)
                setKey('defaultValue')
                setShowPopup(true)
              }}
              defaultValue={params.data.defaultValue}
              onBlur={(e) => {
                const isValid = handleInputChange(e)
                if (isValid) {
                  editColumn(id, e.target.value, 'defaultValue')
                  return
                }
              }}

            /> : type == "boolean" ? <SelectWithLabelInput  options={['false', 'true']} defaultValue={params.data.defaultValue} onChange={({ target }) => editColumn(id, JSON.parse(target.value), 'defaultValue')} /> : <TextInput placeholder={type == 'object' ? '"{"a":"5"}"' : ''} type={type == 'number' ? 'number' : 'text'} value={params.value} onChange={({ target }) => params.setValue(target.value)} onBlur={({ target }) => editColumn(id, target.value, 'defaultValue')} />}

        </div>
      }
    },
    {
      headerName: 'Unique',
      field: 'uniqueColumn',
      cellRenderer: (params) => {
        const id = params.data?._id
        return (
          <Switch perm={params.data?._id + "uinq"} isOn={params.value} colorOne="#01AA6B" colorTwo="#292626" handleToggle={() => editColumn(id, !params.value, "uniqueColumn")} />
        );
      }
    },
    {

      field: "Delete",
      maxWidth: 120,
      cellRenderer: (params) => {
        return (
          <img
            onClick={() => setColumn(column.filter((i) => i._id !== params.data?._id))}
            src={DeletIcon}
            style={{
              cursor: "pointer",
              paddingRight: "5px",
              paddingLeft: "15px",
            }}
          />
        );
      },
    },
  ];

  const editColumn = (id, value, key) => {
    const updatedColumn = column.map((item) => {

      if (item._id === id) {
        return { ...item, [key]: value }
      }
      return item;
    });
    setColumn(updatedColumn)
  }

  const addColumn = () => {

    setColumn([{ key: '', columnType: 'string', defaultValue: false, notNull: false, uniqueColumn: false, _id: Math.random() }, ...column])
  }
  useEffect(() => {
    getDataStorageById(id).then((r) =>{
      setColumn(r.modelSchema)
      setName(r.name)
    }).catch(e => console.log(e))
  }, [])
  const addDataStorage = (id, column) => {
    const data = column.map(i=>{return {key: i.key,columnType:i.columnType,defaultValue:i.columnType==="boolean"?String(i.defaultValue):i.defaultValue,uniqueColumn:i.uniqueColumn}})
    updateDataStorage(name, data,id).then((r) => toast.success("You Create Data Storage")).catch((e) => toast.error(e))
  }
  return (
    <>
           {showPopup&&<ModalEditObject setShowPopup={setShowPopup} editColumn={editColumn} objectValue={objectValue} setObjectValue={setObjectValue} key1={key} rowId = {rowId}/>}
      <ListPage
        column={column}
        addColumn={addColumn}
        title="Update Data Store"
        dataModelCall={getDataModel}
        name={id}
        tableColumns={DataStoreColumn}
        addDataStorage={addDataStorage}
        savedBtn="Update"
        otherBtns="Add Column"
        CreatePopupComponent={CreateNewDataStore}
      />
    </>
  )
}