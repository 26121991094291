import React, { useCallback, useState } from "react";
import { ButtonLighth } from "../../../Atoms/Buttons/ButtonLighth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import s from './ActiveAPIPopup.module.scss'
import { ButtonWithIcon } from "../../../Atoms/Buttons/ButtonWithIcon";
import MonacoEditor from '@monaco-editor/react';
import { publishedCode } from "../../../../store/activeAPI/api";
import { handleError } from "../../../../utils/handleErrors";
import { useEffect } from "react";

export const ActiveAPIPopup = ({setShowPopup, slug, version, apiKey, modelBody}) => {
    const [value, setValue] = useState(null);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    useEffect(()=>{
        setValue(modelBody)
    },[modelBody])
    const handleApply = useCallback(() => {
        publishedCode({ slug, version, apiKey, body: value }).then((code) => {
            toast.success("Successfully Updated");
            setResponse(code);
        }).catch((err) => {
            setError(err);
            handleError(err);
        })
    }, [value, slug, version, apiKey]);
    const handleEditorDidMount =(e) => {
        e.getAction('editor.action.formatDocument').run()
      }
    const onCancel = useCallback(() => {
        setValue('')
        setResponse("");
        setShowPopup(false);
    }, [setResponse, setShowPopup,setValue]);

    return <div className={s.popupWrapper}>
    <div className={s.popupBox}>

        <div className={s.popupHeader}>
            <div className={s.popupName}>
                
            </div>
            <div className={s.popupX}>
                <img onClick={onCancel} src="/assets/images/popupX.png" alt="close" />
            </div>
        </div>

        <div className={s.popupContent}>
            <div className={s.codeWrapper}>
                <MonacoEditor
                    defaultLanguage="json"
                    theme="vs-dark"
                    value={value}
                    onChange={setValue}
                />
            </div>
            {response && <div className={s.codeResponseWrapper}>
                <MonacoEditor
                    onMount={handleEditorDidMount}
                    autoInden={true}
                    defaultLanguage="json"
                    theme="vs-dark"
                    value={JSON.stringify(response)}
                />
            </div>}
            {error && <pre className={s.preError}>{error}</pre>}
        </div>

        <div className={s.popupButtonsWrapper}>
            <ButtonWithIcon onClick={handleApply} green={true} value='Test' />
            <ButtonLighth onClick={onCancel} value="Cancel" />
        </div>

    </div>
</div>
}