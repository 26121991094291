import React from 'react'
import ListPage from '../../Organisms/ListPage/ListPage'
import 'react-toastify/dist/ReactToastify.css'
import { CreateNewScriptPopup } from '../../Organisms/Popups/CreateNewScriptPopup'
import { scriptTableColumn } from '../../../utils/tableColumns'
import { createScript, getAllScripts } from '../../../store/script/api'
import { getDataModel } from '../../../store/model/api'

export const ScriptList = () => {
    return (
        <>
            <ListPage
                title="Script"
                dataModelCall={getDataModel}
                dataCall={getAllScripts}
                tableColumns={scriptTableColumn}
                btnName="Create New Script"
                CreatePopupComponent={CreateNewScriptPopup}
            />
        </>
    )
}
