import React, { useEffect, useState } from 'react'
import editGreen from './../../../../src/imgs/EditGreen.png'
import settingGreen from './../../../../src/imgs/SettingGreen.png'
import 'react-toastify/dist/ReactToastify.css'
import {CreateFlowPopup} from '../../Organisms/Popups/CreateNewFlowPopup'
import ListPage from '../../Organisms/ListPage/ListPage'
import {
    getAllFlow,
    getFlowByIdWithAllData,
} from '../../../store/flow/api'
import { getDataModel } from '../../../store/model/api'
import { handleError } from '../../../utils/handleErrors'
import { formatDate } from '../../../Services/dateFormat'
import { FlowDetail } from './FlowDetail'

export const FlowList = (props) => {
    const [render,setRender] = useState(false)
    const [flowDetail, setFlowDetail] = useState(null) 
    const flowTableColumn = [
        { headerName: 'Flow Name', field: 'name', filter: true, sort: true },
        {
            headerName: 'Data Model',
            field: 'dataModelName',
            filter: true,
            sort: true,
        },
        {
            headerName: 'Version',
            field: 'version',
            filter: true,
            sort: true,
        },
        {
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        { field: 'user', headerName: 'Created By', filter: true, sort: true },
        {
            field: 'Actions',
            cellRenderer: (params) => {
                return (
                    <div className="icons">
                        <img
                            // onClick={() => editFlowItem(params)}
                            src={editGreen}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={settingGreen}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                openFlowDetail(params)
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                )
            },
        },
    ]
    const openFlowDetail = (params) => {
        getFlowByIdWithAllData(params?.data?._id)
            .then((_flowDetail) => {
                setFlowDetail(_flowDetail)
            })
            .catch(handleError)
    }

    const closeFlowDetail = () => {
        setFlowDetail(null)
    }
    useEffect(()=>{
        setRender(true)
    },[])

    return (
      <>
                <ListPage
                 title="Decison Tree" 
                 dataModelCall={getDataModel}  
                 dataCall={getAllFlow} 
                 tableColumns={flowTableColumn}
                 CreatePopupComponent = {CreateFlowPopup}
                 btnName="Create New Flow"
                 FlowPanel = {FlowDetail}
                 flowDetail = {flowDetail}
                 setFlowDetail = {setFlowDetail}
                 closeFlowDetail = {closeFlowDetail}
                 setOpenPanel = {props.setOpenPanel}
                 render={render}
                  />
                </>
    
    )
}
