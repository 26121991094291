import React from 'react'

export const SubProductCatalogHistoryChangesPopup = ({ parameter, close }) => {
    return (
        <div className="version-popup-background ">
            <div
                className="table-popup-wrapper"
                component="form"
                sx={{ borderRadius: '5px' }}
            >
                <div className="action-bar">
                    <img
                        onClick={close}
                        src="/assets/images/popupX.png"
                        alt="close"
                    />
                </div>
                <div className="wrapper"></div>
            </div>
        </div>
    )
}
