import Table from '../Tables/Table/Table'
import React, { useEffect, useRef } from 'react'

export function VersionPopup({
    isOpen,
    setIsOpen,
    tableHeaders,
    rows,
    onVersionChange,
}) {
    const ref = useRef(null)
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [])

    return (
        <>
            {isOpen ? (
                <div className="version-popup-background ">
                    <div
                        ref={ref}
                        className="table-popup-wrapper"
                        component="form"
                        sx={{ borderRadius: '5px' }}
                    >
                        <div className="action-bar">
                            <img
                                onClick={() => setIsOpen(false)}
                                src="/assets/images/popupX.png"
                                alt="close"
                            />
                        </div>

                        <div className="wrapper">
                            <Table
                                openDetail={(e) => {
                                    onVersionChange(e.data.version)
                                }}
                                column={tableHeaders}
                                id={window.location.pathname}
                                data={rows}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
}
