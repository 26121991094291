import { Handle, Position } from 'react-flow-renderer'
import s from './nodes.module.scss'

export function ProductCatalogNode(props) {
    return (
        <>
            <Handle type="target" position={Position.Top} />
            <div className={s.productCatalogsNodeContent}>
                <label htmlFor="text">Product Catalogs</label>
            </div>
            <Handle type="source" position={Position.Bottom} />
        </>
    )
}
