import React, { useState } from 'react'
import { createIntegrations } from '../../../../store/integration/api'
import { SelectWithLabelInput } from '../../../Atoms/Inputs/SelectWithLabel'
import { ButtonLighth } from '../../../Atoms/Buttons/ButtonLighth'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { toast } from 'react-toastify'
import { handleError } from '../../../../utils/handleErrors'
import { TextInput } from '../../../Atoms/Inputs/Text'
import s from './CreateNewIntegrationPopup.module.scss'

export const CreateNewIntegrationPopup = ({
    closePopUp,
    state,
    setState,
    setShowPopup,
    dataModelsList,
}) => {
    const [integrationName, setIntegrationName] = useState('')
    const [modelId, setModelId] = useState(dataModelsList[0]?.value)

    const handleCreateIntegraion = ({ id, name, body, modelId }) => {
        setShowPopup(false)
        createIntegrations(
            {
                name: integrationName,
                modelId: modelId,
                id: id,
                body: body,
                method: 'get',
                type: 1,
            },
            dataModelsList,
        )
            .then((createIntegrations) => {
                setState([createIntegrations, ...state])
                toast.success('Successfully Created')
            })
            .catch(handleError)
    }

    return (
        <div className={s.popupWrapper}>
            <div className={s.popupBox}>
                <div className={s.popupHeader}>
                    <div className={s.popupName}>Create New Integration</div>
                    <div className={s.popupX}>
                        <img
                            onClick={closePopUp}
                            src="/assets/images/popupX.png"
                            alt="close"
                        />
                    </div>
                </div>

                <div className={s.popupForm}>
                    <div className={s.popupFormItem}>
                        <TextInput
                            label="Integration Name"
                            placeholder="Integration Name"
                            value={integrationName}
                            onChange={({ target }) => {
                                setIntegrationName(target.value)
                            }}
                        />
                    </div>
                </div>
                <div className={s.popupFormSelect}>
                    <SelectWithLabelInput
                        label="Data Model"
                        options={dataModelsList}
                        defaultValue={modelId}
                        onChange={({ target }) => {
                            setModelId(target.value)
                        }}
                    />
                </div>
                <div className={s.popupButtonsWrapper}>
                    <ButtonLighth onClick={closePopUp} value="Cancel" />
                    <ButtonSmall
                        onClick={() => {
                            handleCreateIntegraion({
                                integrationName,
                                modelId,
                            })
                        }}
                        value="Create"
                    />
                </div>
            </div>
        </div>
    )
}
