import React from "react";
import { ButtonLighth } from "../../../Atoms/Buttons/ButtonLighth";
import { ButtonSmall } from "../../../Atoms/Buttons/ButtonSmall";
import s from './TakeLivePopup.module.scss';

export const TakeLivePopup = ({setShowLivePopup}) => {
    return <div className={s.popupWrapper}>
    <div className={s.popupBox}>

        <div className={s.popupHeader}>
            <div className={s.popupName}>
            Take Live
            </div>
            <div className={s.popupX}>
                <img onClick={() => setShowLivePopup(false)} src="/assets/images/popupX.png" alt="close" />
            </div>
        </div>

        <div className={s.popupContent}>
            <div className={s.popupText}>
             <span>If you want to unpublish the flow “Flow name” type unpublish in the text field below </span>
            </div>
        </div>

        <div className={s.popupButtonsWrapper}>
            <ButtonLighth onClick={() => setShowLivePopup(false)} value="Cancel" />
            <ButtonSmall value="Take Live" onClick={()=>{}} />
        </div>

    </div>
</div>
}