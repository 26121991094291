import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import '../../../Styles/list-Page.scss'
import editGreen from './../../../imgs/EditGreen.png'
import Delete from './../../../imgs/recycle.png'
import View from './../../../imgs/view.png'
import setingGreen from './../../../imgs/SettingGreen.png'
import { getAllRole } from '../../../store/role/api'
import { getDataById, getDataModel } from '../../../store/model/api'
import { handleError } from '../../../utils/handleErrors'
import { FlowDetail } from '../FlowList/FlowDetail'
import { formatDate } from '../../../Services/dateFormat'
import ListPage from '../../Organisms/ListPage/ListPage'
import { RoleListHeader } from './RoleListHeader'
import { RoleDetails } from './RoleDetails'
import { getEntityVersionById } from '../../../store/common/api'
import { ArchivePopup } from '../../Organisms/Popups/General/ArchivePopup'
import { useHistory } from 'react-router-dom'
const entityType = 'rule'

const STATUSES_BY_KEY = {
    0: 'Archived',
    1: 'Draft',
    2: 'In review',
    3: 'Approved',
    4: 'Declined',
}
export const RoleList = (props) => {
    const [showPopup, setShowPopup] = useState(false)
    // const [archivePopupId, setArchivePopupId] = useState(null)
    const [name, setName] = useState()
    const [key, setKey] = useState()
    const [versions, setVersions] = useState()
    const [roleDetail, setRoleDetail] = useState(null)
    const history = useHistory()

    const openRoleDetail = (id) => {
        getEntityVersionById(id, entityType)
            .then((versions) => {
                setVersions(versions)
                if (versions && versions.length > 0) {
                    setRoleDetail(versions[0])
                }
            })
            .catch(handleError)
    }
    const viewRole = (item) => {
        history.push(`${window.location.pathname}/${item.data._id}?type=view`)
    }

    // const deleteRole = () => {
    //     archiveRoleById(archivePopupId)
    //         .then((versions) => {
    //             setArchivePopupId(null)
    //             setVersions(versions)
    //             if (versions && versions.length > 0) {
    //                 setVersions(versions[0])
    //             }
    //             toast.success('Role deleted successfully')
    //         })
    //         .catch(handleError)
    // }

    const editDataItem = (item) => {
        getDataById({ id: item.data.modelId })
            .then((dataById) => {
                setShowPopup(true)
                setName(item.data.name)
                setVersions(item.data.version)
                setKey(item.data.key)
            })
            .catch(handleError)
    }
    const closeRoleDetail = () => {
        setRoleDetail(false)
    }

    const rolesTableColumn = [
        { field: 'name', filter: true },

        {
            field: 'status',
            filter: true,
            valueGetter: (params) => {
                return STATUSES_BY_KEY[params.data.status]
            },
        },
        {
            headerName: 'Last Modified By',
            field: 'user',
            filter: 'agDateColumnFilter',
        },
        {
            headerName: 'Last Modified Date',
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            field: 'Actions',
            cellRenderer: (params) => {
                return (
                    <div className="icons">
                        <img
                            src={editGreen}
                            onClick={() => editDataItem(params)}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={setingGreen}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                openRoleDetail(params.data._id)
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                        {/*<img*/}
                        {/*    src={Delete}*/}
                        {/*    onClick={(event) => {*/}
                        {/*        event.stopPropagation()*/}
                        {/*        event.preventDefault()*/}
                        {/*        setArchivePopupId(params.data._id)*/}
                        {/*    }}*/}
                        {/*    style={{ cursor: 'pointer', paddingRight: '5px' }}*/}
                        {/*/>{' '}*/}
                        <img
                            src={View}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                viewRole(params)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                    </div>
                )
            },
        },
    ]
    return (
        <>
            {/*<ArchivePopup*/}
            {/*    isOpen={!!archivePopupId}*/}
            {/*    togglePanel={setArchivePopupId}*/}
            {/*    onDelete={() => deleteRole()}*/}
            {/*></ArchivePopup>*/}
            <div className={'tableWrapper'}>
                {roleDetail ? (
                    <RoleDetails
                        details={roleDetail}
                        versions={versions}
                        close={() => {
                            setRoleDetail(null)
                        }}
                    />
                ) : null}
                <ListPage
                    pg="20"
                    dataModelCall={getDataModel}
                    dataCall={() => getAllRole([])}
                    tableColumns={rolesTableColumn}
                    parameterPanel={FlowDetail}
                    roleDEtail={roleDetail}
                    setRoleDetail={setRoleDetail}
                    closeRuleDetail={closeRoleDetail}
                    setOpenPanel={props.setOpenPanel}
                    editDataItem={editDataItem}
                    HeaderComponent={() => <RoleListHeader />}
                />
            </div>
        </>
    )
}
