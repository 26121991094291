import React from "react";
import { ButtonLighth } from "../../../Atoms/Buttons/ButtonLighth";
import { ButtonSmall } from "../../../Atoms/Buttons/ButtonSmall";
import s from './FlowArchivePopup.module.scss'

export const FlowArchivePopup = ({setFlowArchivePopup, dataName, onArchiveData}) => {
    return <div className={s.popupWrapper}>
    <div className={s.popupBox}>

        <div className={s.popupHeader}>
            <div className={s.popupName}>
                Confirm Submit
            </div>
            <div className={s.popupX}>
                <img onClick={() => setFlowArchivePopup(false)} src="/assets/images/popupX.png" alt="close" />
            </div>
        </div>

        <div className={s.popupContent}>
            <div className={s.popupText}>
                Are you sure you want to delete <span>{dataName}</span>
            </div>
        </div>

        <div className={s.popupButtonsWrapper}>
            <ButtonLighth onClick={() => setFlowArchivePopup(false)} value="Cancel" />
            <ButtonSmall red value="Delete" onClick={onArchiveData} />
        </div>

    </div>
</div>
}