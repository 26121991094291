import React, { memo } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { TextInput } from '../../Atoms/Inputs/Text'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { conditionOptions } from '../../../utils/selectOptions'
import { getSegmentById } from '../../../store/segment/api'
import { handleError } from '../../../utils/handleErrors'

export const ScoreSegments = memo(
    ({ setEntity, entity, segmentModelsList }) => {
        const segments = entity.segments

        const updateEntity = (_segments) => {
            setEntity({
                ...entity,
                segments: _segments,
            })
        }

        const updateSegmentData = (j, i, key, value) => {
            entity.segments[j].scores[i][key] = value
            setEntity({
                ...entity,
            })
        }
        const addSegment = () => {
            updateEntity([...segments, { segmentId: null }])
        }

        const removeSegment = (id) => {
            updateEntity(segments.filter((item) => item.segmentId !== id))
        }
        const onChangeSegment = (oldValue, newValue) => {
            let newSegmentList = segments
            if (oldValue) {
                newSegmentList = newSegmentList.filter(
                    (item) => item.id === oldValue,
                )
            }
            if (newValue === 'Choose') {
                newSegmentList = newSegmentList.filter(
                    (item) => item.id === oldValue,
                )
                updateEntity(newSegmentList)
                return
            }

            getSegmentById({ id: newValue })
                .then((segmentById) => {
                    const scores = segmentById.segment.conditions.map(
                        (item) => {
                            return {
                                ...item,
                                scoreValue: 0,
                                scoreId: '',
                            }
                        },
                    )
                    const newS = [
                        ...newSegmentList.filter(
                            (item) => item.segmentId !== null,
                        ),
                        {
                            segmentId: segmentById.segment.segmentId,
                            scores,
                        },
                    ]
                    updateEntity(newS)
                })
                .catch(handleError)
        }

        return (
            <>
                {(segments || []).map((item, j) => {
                    return (
                        <div
                            id={item.segmentId}
                            key={item.segmentId}
                            className="segmentSection"
                        >
                            <div className="selectWrapper">
                                <div className="selectButtonWrapper">
                                    <SelectWithLabelInput
                                        options={segmentModelsList}
                                        defaultValue={item.segmentId}
                                        onChange={({ target }) => {
                                            onChangeSegment(
                                                item.segmentId,
                                                target.value,
                                            )
                                        }}
                                    />
                                </div>
                                <div className="selectRemoveWrapper">
                                    <ButtonWithIcon
                                        onClick={() => {
                                            removeSegment(item.segmentId)
                                        }}
                                        icon="/assets/images/Delete.png"
                                        red={true}
                                        value="Remove"
                                    />
                                </div>
                            </div>
                            {(item.scores || []).map((condition, i) => {
                                return (
                                    <div className="formItem" key={i}>
                                        <TextInput
                                            disabled
                                            label="Name"
                                            placeholder="Name"
                                            value={condition.name}
                                            onChange={({ target }) => {
                                                updateSegmentData(
                                                    j,
                                                    i,
                                                    'name',
                                                    target.value,
                                                )
                                            }}
                                        />
                                        <SelectWithLabelInput
                                            disabled
                                            label="Condition"
                                            options={conditionOptions}
                                            defaultValue={condition.condition}
                                            onChange={({ target }) => {
                                                updateSegmentData(
                                                    j,
                                                    i,
                                                    'condition',
                                                    target.value,
                                                )
                                            }}
                                        />
                                        <TextInput
                                            disabled
                                            label="Value"
                                            placeholder="Value"
                                            value={condition.value}
                                            onChange={({ target }) => {
                                                updateSegmentData(
                                                    j,
                                                    i,
                                                    'value',
                                                    target.value,
                                                )
                                            }}
                                        />
                                        <TextInput
                                            label="Score Value"
                                            placeholder="Score Value"
                                            value={condition.scoreValue}
                                            onChange={({ target }) => {
                                                updateSegmentData(
                                                    j,
                                                    i,
                                                    'scoreValue',
                                                    target.value,
                                                )
                                            }}
                                        />

                                        <TextInput
                                            label="Score ID"
                                            placeholder="Score ID"
                                            value={condition.scoreId}
                                            onChange={({ target }) => {
                                                updateSegmentData(
                                                    j,
                                                    i,
                                                    'scoreId',
                                                    target.value,
                                                )
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
                <div className="addButton">
                    <ButtonWithIcon
                        onClick={addSegment}
                        icon="/assets/images/icon+.png"
                        green={true}
                        value="Add Segment"
                    />
                </div>
            </>
        )
    },
)
