import editGreen from './../imgs/EditGreen.png'
import { formatDate } from '../Services/dateFormat'

export const DATA_MODEL_TABLE_COLUMNS = [
    {
        field: 'name',
        headerName: 'Data Name',
        filter: true,
        sort: true,
    },
    {
        field: 'id',
        filter: true,
        sort: true,
    },
    {
        field: 'createdAt',
        filter: true,
        sort: true,
        valueGetter: (params) => {
            return formatDate(new Date(params.data.createdAt))
        },
    },
    { field: 'createdBy', headerName: 'Created By', filter: true, sort: true },
    {
        field: 'Actions',
        filter: false,
        sort: false,
        cellRenderer: (params) => {
            return (
                <img
                    src={editGreen}
                    style={{
                        cursor: 'pointer',
                        paddingRight: '5px',
                        paddingLeft: '15px',
                    }}
                />
            )
        },
    },
]

export const scriptTableColumn = [
    { field: 'name', filter: true, sort: true },
    {
        headerName: 'Data Model',
        field: 'dataModelName',
        filter: true,
        sort: true,
    },
    {
        field: 'createdAt',
        filter: true,
        sort: true,
        valueGetter: (params) => {
            return formatDate(new Date(params.data.createdAt))
        },
    },
    { field: 'createdBy', headerName: 'Created By', filter: true, sort: true },
    {
        field: 'Actions',
        cellRenderer: (params) => {
            return (
                <img
                    src={editGreen}
                    style={{
                        cursor: 'pointer',
                        paddingRight: '5px',
                        paddingLeft: '15px',
                    }}
                />
            )
        },
    },
]

export const DataStoreColumn = [
    { headerName: 'Data Store Name', field: 'name', filter: true, sort: true },
    {
        field: 'createdAt',
        filter: true,
        sort: true,
        valueGetter: (params) => {
            return formatDate(new Date(params.data.createdAt))
        },
    },
    { field: 'user', headerName: 'Created By', filter: true, sort: true },
    {
        field: 'data',
        headerName: 'Last Updated At',
        filter: true,
        sort: true,
        valueGetter: (params) => {
            return formatDate(new Date(params.data.createdAt))
        },
    },
    {
        field: 'Actions',
        maxWidth: 120,
        cellRenderer: (params) => {
            return (
                <img
                    src={editGreen}
                    style={{
                        cursor: 'pointer',
                        paddingRight: '5px',
                        paddingLeft: '15px',
                    }}
                />
            )
        },
    },
]

export const integrationTableColumn = [
    { field: 'name', filter: true },
    {
        field: 'createdAt',
        filter: true,
        sort: true,
        valueGetter: (params) => {
            return formatDate(new Date(params.data.createdAt))
        },
    },
    { field: 'user', headerName: 'Created By', filter: true, sort: true },
    {
        headerName: 'Data Model',
        field: 'dataModelName',
        filter: true,
        sort: true,
    },
    {
        field: 'Actions',
        cellRenderer: (params) => {
            return (
                <img
                    src={editGreen}
                    style={{
                        cursor: 'pointer',
                        paddingRight: '5px',
                        paddingLeft: '15px',
                    }}
                />
            )
        },
    },
]

export const integrationDBTableColumn = [
    { field: 'name', filter: true },
    {
        field: 'createdAt',
        filter: true,
        sort: true,
        valueGetter: (params) => {
            return formatDate(new Date(params.data.createdAt))
        },
    },
    { headerName: 'Created By', field: 'user', filter: 'agDateColumnFilter' },
    {
        field: 'Actions',
        cellRenderer: (params) => {
            return (
                <img
                    src={editGreen}
                    style={{
                        cursor: 'pointer',
                        paddingRight: '5px',
                        paddingLeft: '15px',
                    }}
                />
            )
        },
    },
]

export const segmentTableColumn = [
    { headerName: 'segment Name', field: 'name', filter: true },
    { headerName: 'Data Model', field: 'dataModelName', filter: true },
    {
        field: 'createdAt',
        filter: true,
        sort: true,
        valueGetter: (params) => {
            return formatDate(new Date(params.data.createdAt))
        },
    },
    { headerName: 'Created By', field: 'user', filter: 'agDateColumnFilter' },
    {
        field: 'Actions',
        cellRenderer: (params) => {
            return (
                <img
                    src={editGreen}
                    style={{
                        cursor: 'pointer',
                        paddingRight: '5px',
                        paddingLeft: '15px',
                    }}
                />
            )
        },
    },
]

export const scoreTableColumn = [
    { headerName: 'ScoreResults Name', field: 'name', filter: true },
    { headerName: 'Data Model', field: 'dataModelName', filter: true },
    {
        field: 'createdAt',
        filter: true,
        sort: true,
        valueGetter: (params) => {
            return formatDate(new Date(params.data.createdAt))
        },
    },
    { field: 'user', headerName: 'Created By', filter: true, sort: true },
    {
        field: 'Actions',
        cellRenderer: (params) => {
            return (
                <img
                    src={editGreen}
                    style={{
                        cursor: 'pointer',
                        paddingRight: '5px',
                        paddingLeft: '15px',
                    }}
                />
            )
        },
    },
]

export const valueSetterTableColumn = [
    { headerName: 'Flow Name', field: 'name', filter: true },
    { headerName: 'Data Model', field: 'dataModelName', filter: true },
    { field: 'user', headerName: 'Created By', filter: true, sort: true },
    {
        field: 'Actions',
        cellRenderer: (params) => {
            return (
                <img
                    src={editGreen}
                    style={{
                        cursor: 'pointer',
                        paddingRight: '5px',
                        paddingLeft: '15px',
                    }}
                />
            )
        },
    },
]
