import React, { useState } from 'react'
import { Button } from '../../Atoms/Buttons/Button'
import { TextInput } from '../../Atoms/Inputs/Text'
import { useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import s from './Login.module.scss'
import { Api } from '../../../Services/Api'
import { handleError } from '../../../utils/handleErrors'
import { login } from '../../../store/user/api'

export const Login = () => {
    const history = useHistory()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const handleSubmit = (e) => {
        e.preventDefault()
        login({ email, password })
            .then((logedInUser) => {
                Api().setToken(logedInUser.access_token)
                window.localStorage.setItem(
                    'Authorization',
                    logedInUser.access_token,
                )
                window.localStorage.setItem(
                    'User',
                    JSON.stringify(logedInUser.user),
                )

                history.push('/data-models')
            })
            .catch((err) => {
                handleError(err)
            })
    }
    return (
        <form className={s.mainComponentLogin} onSubmit={handleSubmit}>
            <ToastContainer />
            <div className={s.mainComponent}>
                <div className={s.logoWrapper}>
                    <img alt="logo" src="/assets/images/logo.png" />
                </div>
                <div className={s.loginFormWrapper}>
                    <div className={s.loginFormBox}>
                        <div className={s.loginFormTitle}>Sign In</div>
                        <div className={s.loginFormInputUsername}>
                            <TextInput
                                label="Username"
                                placeholder="Username"
                                value={email}
                                onChange={({ target }) => {
                                    setEmail(target.value)
                                }}
                            />
                        </div>
                        <div className={s.loginFormInputPassword}>
                            <TextInput
                                type="password"
                                label="Password"
                                placeholder="Password"
                                value={password}
                                onChange={({ target }) => {
                                    setPassword(target.value)
                                }}
                            />
                        </div>
                        <div className={s.buttonWrapper}>
                            <Button
                                value="Sign In"
                                type="submit"
                                disabled={!(email && password)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
