import React from "react";
import { ButtonLighth } from "../../../Atoms/Buttons/ButtonLighth";
import { ButtonSmall } from "../../../Atoms/Buttons/ButtonSmall";
import s from './SegmentRemovePopup.module.scss'

export const SegmentRemovePopup = ({setSegmentArchivePopup, segmentName, onArchiveSegment}) => {
    return <div className={s.popupWrapper}>
    <div className={s.popupBox}>

        <div className={s.popupHeader}>
            <div className={s.popupName}>
                Are you sure you want to remove Segment
            </div>
            <div className={s.popupX}>
                <img onClick={() => setSegmentArchivePopup(false)} src="/assets/images/popupX.png" alt="close" />
            </div>
        </div>

        <div className={s.popupContent}>
            <div className={s.popupText}>
                Segment  - <span>{segmentName}</span>
            </div>
        </div>

        <div className={s.popupButtonsWrapper}>
            <ButtonLighth onClick={() => setSegmentArchivePopup(false)} value="Cancel" />
            <ButtonSmall value="Remove" onClick={onArchiveSegment} />
        </div>

    </div>
</div>
}