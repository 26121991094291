import { GET_ALL_LOGS, GET_LOGS_BY_ID, UPDATE_CURRENT_ACTIVITY_LOG } from './types'

export const initialData = {
    current: {},
    all: [],
    currentPage: 1,
    totalPages: null
};

export function activityLogReducer(state = initialData, action) {
    switch (action.type) {

        case GET_ALL_LOGS: {
            return {
                ...state,
                all: action.allLogs.logs.length ? action.allLogs.logs.sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)) : action.allLogs.logs,
                currentPage: +action.allLogs.currentPage,
                totalPages: action.allLogs.totalPages
            }
        }

        case GET_LOGS_BY_ID: {
            return {
                ...state,
                current: action.logsById.dataModel,
                all: state.all.map(element => {
                    if(element.id === action.logsById.dataModel.id) {
                        return {...element, ...{isDetailLoaded: true}, ...action.logsById.dataModel}
                    } 
                    return element
                })
            }
        }

        case UPDATE_CURRENT_ACTIVITY_LOG: {
            return {
                ...state,
                current: action.row
            }
        }

        default:
            break;
    }

    return state;
};
