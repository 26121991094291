import React from 'react'
import MonacoEditor from '@monaco-editor/react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { handleError } from '../../../utils/handleErrors'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { TextInput } from '../../Atoms/Inputs/Text'
import { SinglePageHook } from '../../../hooks/single-page-hook'
import { updateEntity } from '../../../store/common/api'
import { IntegrationHeaders } from './integration-headers'
import { Header } from '../../Organisms/single-page-common/header'

const API_TYPES = [
    { name: 'GET', value: 'get' },
    { name: 'POST', value: 'post' },
    { name: 'PUT', value: 'put' },
    { name: 'PATCH', value: 'patch' },
    { name: 'DELETE', value: 'delete' },
    { name: 'HEAD', value: 'head' },
    { name: 'OPTIONS', value: 'options' },
]

const FILE_TYPES = [
    { name: 'JSON', value: 1 },
    { name: 'XML', value: 2 },
]
const entityType = 'integration'
const entityURL = 'integrations'

export const Integration = () => {
    const { id, entity, setEntity, versions, setVersions } = SinglePageHook({
        entityType,
    })
    const onEntityChange = (key, value) => {
        setEntity({
            ...entity,
            [key]: value,
        })
    }
    const onUpdateIntegrations = () => {
        const { modelId, name, body, headers, type, url, method } = entity
        if (body?.trim()) {
            updateEntity(id, entityType, {
                modelId: modelId,
                name: name,
                body: body,
                headers:
                    typeof headers === 'string'
                        ? headers
                        : JSON.stringify(headers),
                type: Number(type),
                url: url,
                method: method.toLowerCase(),
            })
                .then((updatedEntity) => {
                    updatedEntity.headers = JSON.parse(updatedEntity.headers)
                    setEntity(updatedEntity)
                    setVersions((prevState) => {
                        return [updatedEntity, ...prevState]
                    })
                    toast.success('Successfully Updated')
                })
                .catch(handleError)
        } else {
            toast.error('Body is empty')
        }
    }

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <div className="integration-content">
                        {entity.headers && (
                            <>
                                <Header
                                    entityType={entityType}
                                    onEntityUpdate={onUpdateIntegrations}
                                    setEntity={setEntity}
                                    entity={entity}
                                    entityURL={entityURL}
                                    versions={versions}
                                />
                                <div
                                    className="codeContent"
                                    style={{ top: '160px' }}
                                >
                                    <div className="fileTypes">
                                        <SelectWithLabelInput
                                            defaultValue={entity.type}
                                            onChange={({ target }) => {
                                                onEntityChange(
                                                    'type',
                                                    target.value,
                                                )
                                            }}
                                            options={FILE_TYPES}
                                        />
                                    </div>
                                    <div className="codeContent_left">
                                        <div className="apiType">
                                            <SelectWithLabelInput
                                                defaultValue={entity.method}
                                                onChange={({ target }) => {
                                                    onEntityChange(
                                                        'method',
                                                        target.value,
                                                    )
                                                }}
                                                options={API_TYPES}
                                            />
                                        </div>
                                        <TextInput
                                            value={entity.url}
                                            onChange={({ target }) => {
                                                onEntityChange(
                                                    'url',
                                                    target.value,
                                                )
                                            }}
                                            className="selectUrl"
                                            placeholder="URL"
                                        ></TextInput>
                                    </div>
                                    <div className="codeContent_right">
                                        <div className="formBody">
                                            <div className="formWrapper">
                                                <IntegrationHeaders
                                                    setEntity={setEntity}
                                                    entity={entity}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="monacoField">
                                    <h1 className="monacoTitle">Body</h1>
                                    <MonacoEditor
                                        defaultLanguage="javascript"
                                        theme="vs-dark"
                                        value={entity.body}
                                        onChange={(value) =>
                                            onEntityChange('body', value)
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
