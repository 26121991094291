import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import s from './Sidebar.module.scss'

export const Sidebar = ({onClick = () => {}, value="", disabled }) => {
    let history = useHistory();
    const logout = useCallback(() => {
        window.localStorage.clear()
        history.push("/main");
    }, [history]);
    
    return <div className={s.sidebarWrapper}>
        <div className={s.sidebar}>
            <div className={s.sidebarTitle}>
                <img src="/assets/images/DMS.png" alt="Icon" />
                <span>DMS PRO</span>
            </div>
            <div className={s.sidebarMenu}>
                <ul>
                    <li>
                        <img src="/assets/images/main.png" alt="Icon" />
                        <span>Main</span>
                    </li>
                    <li>
                        <img src="/assets/images/Applications.png" alt="Icon" />
                        <span>Applications</span>
                    </li>
                    <li>
                        <img src="/assets/images/reports.png" alt="Icon" />
                        <span>Reports</span>
                    </li>
                    <li>
                        <img src="/assets/images/NotificationIcon.png" alt="Icon" />
                        <span>Notification</span>
                    </li>
                    <li>
                        <img src="/assets/images/help.png" alt="Icon" />
                        <span>Help</span>
                    </li>
                    <li>
                        <img src="/assets/images/Administration.png" alt="Icon" />
                        <span>Administration</span>
                    </li>
                    <li className={s.active}>
                        <Link to="/user-management">
                            <img src="/assets/images/UserManagement.png" alt="Icon" />
                            <span>User Management</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className={s.sidebaLogout}>
                <ul>
                    <li onClick={logout}>
                        <img src="/assets/images/Logout.png" alt="Icon" />
                        <span>Logout</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
}