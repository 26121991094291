import React from 'react'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { TextInput } from '../../../Atoms/Inputs/Text'
import s from './AddVariablePopup.module.scss'

export const AddVariablePopup = ({
    setShowVariablePopup,
    variable,
    comment,
    setVariable,
    setComment,
    addVariable,
}) => {
    return (
        <div className={s.popupWrapper}>
            <div className={s.popupBox}>
                <div className={s.popupHeader}>
                    <div className={s.popupName}>Add Variable</div>
                    <div className={s.popupX}>
                        <img
                            onClick={() => setShowVariablePopup(false)}
                            src="/assets/images/popupX.png"
                            alt="close"
                        />
                    </div>
                </div>

                <div className={s.popupForm}>
                    <div className={s.popupFormItem}>
                        <TextInput
                            placeholder="Variable"
                            value={variable}
                            onChange={({ target }) => {
                                setVariable(target.value)
                            }}
                        />
                    </div>
                    <div className={s.popupFormItem}>
                        <TextInput
                            placeholder="Comment"
                            value={comment}
                            onChange={({ target }) => {
                                setComment(target.value)
                            }}
                        />
                    </div>
                </div>

                <div className={s.popupButtonsWrapper}>
                    <ButtonSmall onClick={addVariable} value="Add Variable" />
                </div>
            </div>
        </div>
    )
}
