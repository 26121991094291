import React, { useState } from 'react'
import { ButtonLighth } from '../../../Atoms/Buttons/ButtonLighth'
import { createSegment } from '../../../../store/segment/api'
import { toast } from 'react-toastify'
import { handleError } from '../../../../utils/handleErrors'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { SelectWithLabelInput } from '../../../Atoms/Inputs/SelectWithLabel'
import { TextInput } from '../../../Atoms/Inputs/Text'
import s from './CreateNewSegmentPopup.module.scss'

export const CreateNewSegmentPopup = ({
    closePopUp,
    dataModelsList,
    stateDataModel,
    state,
    setState,
    setShowPopup,
}) => {
    const [segmentName, setSegmentName] = useState('')
    const [dataNameSegment, setDataNameSegment] = useState('')
    const [propertyName, setPropertyName] = useState('')
    const defaultValue = stateDataModel.map((dm) => ({
        value: dm._id,
        name: dm.name,
    }))

    const handleCreateSegment = ({
        dataNameSegment,
        segmentName,
        propertyName,
    }) => {
        setShowPopup(false)
        createSegment(
            {
                name: segmentName,
                property: propertyName,
                modelId: dataNameSegment
                    ? dataNameSegment
                    : defaultValue[0].value,
                conditions: [],
            },
            stateDataModel,
        )
            .then((createSegment) => {
                setState([createSegment, ...state])
                toast.success('Successfully Created')
            })
            .catch(handleError)
    }

    return (
        <div className={s.popupWrapper}>
            <div className={s.popupBox}>
                <div className={s.popupHeader}>
                    <div className={s.popupName}>Create New Segment</div>
                    <div className={s.popupX}>
                        <img
                            onClick={closePopUp}
                            src="/assets/images/popupX.png"
                            alt="close"
                        />
                    </div>
                </div>

                <div className={s.popupForm}>
                    <div className={s.popupFormItem}>
                        <TextInput
                            label="Segment Name"
                            placeholder="Segment Name"
                            value={segmentName}
                            onChange={({ target }) => {
                                setSegmentName(target.value)
                            }}
                        />
                    </div>
                    <div className={s.popupFormItem}>
                        <SelectWithLabelInput
                            label="Data Model"
                            options={dataModelsList}
                            defaultValue={dataNameSegment}
                            onChange={({ target }) => {
                                setDataNameSegment(target.value)
                            }}
                        />
                    </div>
                    <div className={s.popupFormItem}>
                        <TextInput
                            label="Property Name"
                            placeholder="Property Name"
                            value={propertyName}
                            onChange={({ target }) => {
                                setPropertyName(target.value)
                            }}
                        />
                    </div>
                </div>

                <div className={s.popupButtonsWrapper}>
                    <ButtonLighth onClick={closePopUp} value="Cancel" />
                    <ButtonSmall
                        onClick={() => {
                            handleCreateSegment({
                                segmentName,
                                dataNameSegment,
                                propertyName,
                            })
                        }}
                        value="Create"
                    />
                </div>
            </div>
        </div>
    )
}
