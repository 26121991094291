import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import '../../../Styles/list-Page.scss'
import editGreen from './../../../imgs/EditGreen.png'
import Delete from './../../../imgs/recycle.png'
import View from './../../../imgs/view.png'
import { getDataModel } from '../../../store/model/api'
import { handleError } from '../../../utils/handleErrors'
import { FlowDetail } from '../FlowList/FlowDetail'
import { formatDate } from '../../../Services/dateFormat'
import ListPage from '../../Organisms/ListPage/ListPage'
import { UserListHeader } from './UserListHeader'
import { ArchivePopup } from '../../Organisms/Popups/General/ArchivePopup'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    getAllUsers,
    getUserById,
    archiveUserById,
} from '../../../store/user/api'
const entityType = 'user'
export const UserList = (props) => {
    const [showPopup, setShowPopup] = useState(false)
    const [archivePopupId, setArchivePopupId] = useState(null)
    const [name, setName] = useState()
    const [key, setKey] = useState()
    const [dataModels, setDataModels] = useState([])
    const [userDetail, setUserDetail] = useState(null)

    const STATUSES_BY_KEY = {
        0: 'Archived',
        1: 'Active',
    }
    const history = useHistory()

    useEffect(() => {
        getDataModel().then((_dataModels) => {
            setDataModels(_dataModels)
        })
    }, [])

    const editDataItem = (item) => {
        getUserById(item.data.id)
            .then((dataById) => {
                setShowPopup(true)
                setUserDetail(dataById)
                setName(item.data.name)
                setKey(item.data.key)
            })
            .catch(handleError)
    }

    const viewUser = (item) => {
        history.push(`${window.location.pathname}/${item.data._id}?type=view`)
    }

    const deleteUser = () => {
        archiveUserById(archivePopupId)
            .then((versions) => {
                setArchivePopupId(null)

                toast.success('User deleted successfully')
            })
            .catch(handleError)
    }

    const closeUserDetail = () => {
        setUserDetail(false)
    }

    const userTableColumns = [
        { field: 'name', filter: true },

        {
            field: 'roleName',
            filter: true,
        },
        {
            field: 'status',
            filter: true,
            valueGetter: (params) => {
                return STATUSES_BY_KEY[params.data.status]
            },
        },

        {
            headerName: 'Last Modified Date',
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.updatedAt))
            },
        },
        {
            field: 'Actions',
            cellRenderer: (params) => {
                return (
                    <div className="icons">
                        <img
                            src={editGreen}
                            onClick={() => editDataItem(params)}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={Delete}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                setArchivePopupId(params.data._id)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={View}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                viewUser(params)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                    </div>
                )
            },
        },
    ]
    return (
        <>
            <ArchivePopup
                isOpen={!!archivePopupId}
                togglePanel={setArchivePopupId}
                onDelete={() => deleteUser()}
            ></ArchivePopup>
            <div className={'tableWrapper'}>
                <ListPage
                    pg="20"
                    dataModelCall={getDataModel}
                    dataCall={getAllUsers}
                    tableColumns={userTableColumns}
                    parameterPanel={FlowDetail}
                    userDetail={userDetail}
                    setUserDetail={setUserDetail}
                    closeUserDetail={closeUserDetail}
                    setOpenPanel={props.setOpenPanel}
                    HeaderComponent={() => <UserListHeader />}
                />
            </div>
        </>
    )
}
