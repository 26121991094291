import { LOGIN_USER, GET_ALL_USER  } from './types'

export const initialData = {
    current: null,
    all: []
};

export function userReducer(state = initialData, action) {
    switch (action.type) {
        case LOGIN_USER: {
            return {
                current: action.user
            }
        }
        case GET_ALL_USER: {
            return {
                all: action.allUsers
            }
        }

        default:
            break;
    }

    return state;
};
