import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { TextInput } from '../../Atoms/Inputs/Text'
import { useParams } from 'react-router-dom'
import { getEntityVersionById } from '../../../store/common/api'
import { handleError } from '../../../utils/handleErrors'
import { formatDate } from '../../../Services/dateFormat'
import { RoleHistoryChangesPopup } from './RoleHistoryChangesPopup'
import './createRole.scss'
import Switch from '../role-pages/Switch'

const entityType = 'role'
const entityURL = 'roles'

const PERMISSION_LIST = [
    'CREATE_SCRIPT',
    'EDIT_SCRIPT',
    'VIEW_SCRIPT',
    'CREATE_SEGMENT',
    'EDIT_SEGMENT',
    'VIEW_SEGMENT',
    'CREATE_SCORE',
    'EDIT_SCORE',
    'VIEW_SCORE',
    'CREATE_VALUE_SETTER',
    'EDIT_VALUE_SETTER',
    'VIEW_VALUE_SETTER',
    'CREATE_FLOW',
    'VIEW_FLOW',
    'EDIT_FLOW',
    'CREATE_DATA_STORAGE',
    'VIEW_DATA_STORAGE',
    'EDIT_DATA_STORAGE',
    'CREATE_INTEGRATION',
    'VIEW_INTEGRATION',
    'EDIT_INTEGRATION',
    'CREATE_INTEGRATION_DB',
    'VIEW_INTEGRATION_DB',
    'EDIT_INTEGRATION_DB',
    'FLOW_PUBLISH',
    'VIEW_PUBLISH_FLOW',
    'VIEW_LOGS',
    'VIEW_APIS',
    'CREATE_ROLE',
    'VIEW_ROLE',
    'EDIT_ROLE',
    'CREATE_USER',
    'VIEW_USER',
    'EDIT_USER',
    'CREATE_PARAMETER',
    'VIEW_PARAMETER',
    'EDIT_PARAMETER',
    'CREATE_RULE',
    'VIEW_RULE',
    'EDIT_RULE',
    'CREATE_CATALOG',
    'VIEW_CATALOG',
    'EDIT_CATALOG',
    'CREATE_SUB_CATALOG',
    'VIEW_SUB_CATALOG',
    'EDIT_SUB_CATALOG',
]
export const Role = () => {
    const { id } = useParams()
    const [entity, setEntity] = useState({
        permissions: [],
    })
    const [versions, setVersions] = useState([])
    const [openRowPopup, setOpenRowPopup] = useState(null)

    const handleToggle = (perm) => {
        if (entity.permissions.includes(perm)) {
            onEntityChange(
                'permissions',
                entity.permissions.filter((p) => p !== perm),
            )
        } else {
            onEntityChange('permissions', [...entity.permissions, perm])
        }
    }

    const updateInfo = (id) => {
        getEntityVersionById(id, entityType)
            .then((versions) => {
                setVersions(versions)
                if (versions && versions.length > 0) {
                    setEntity(versions[0])
                }
            })
            .catch(handleError)
    }

    useEffect(() => {
        updateInfo(id)
    }, [])

    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }
    console.log(entity)
    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header
                        updateInfo={updateInfo}
                        entity={entity}
                        entityURL={entityURL}
                    />
                    {openRowPopup ? (
                        <RoleHistoryChangesPopup
                            id={openRowPopup}
                            close={() => setOpenRowPopup(null)}
                        />
                    ) : null}
                    <div className="single-table-wrapper rule-content parameter-individual-page">
                        <div className="inputsWrapper">
                            <div className="row first-row roles-page">
                                <TextInput
                                    label="Name"
                                    value={entity?.name}
                                    onChange={({ target }) => {
                                        onEntityChange('name', target.value)
                                    }}
                                    placeholder="Name"
                                />
                                <ul className="infoList ">
                                    {PERMISSION_LIST.map((perm, index) => {
                                        return (
                                            <li
                                                className="infoListRole"
                                                key={index}
                                            >
                                                <div
                                                    className="infoListNameRole"
                                                    key={index}
                                                >
                                                    {perm}
                                                </div>
                                                <div
                                                    className="infoListActions"
                                                    key={perm}
                                                >
                                                    <Switch
                                                        key={index}
                                                        perm={perm}
                                                        isOn={entity.permissions?.includes(
                                                            perm,
                                                        )}
                                                        handleToggle={
                                                            handleToggle
                                                        }
                                                        colorOne="#01AA6B"
                                                        colorTwo="#292626"
                                                    />
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
