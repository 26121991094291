import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

function normalizePublishedFlow(response, dataModelsForNormalize) {
    if (response?.flows?.length) {
        const data = response.flows.map((flow) => {
            return {
                ...flow,
                user: Users().getUserById(flow.userId)?.name,
                dataModel: dataModelsForNormalize.find(
                    (dm) => dm._id === flow.modelId,
                )?.name,
            }
        })
        return data.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
        )
    } else {
        return []
    }
}

export function getAllPublishedFlow(dataModelsForNormalize) {
    const page = 1
    const limit = 10000
    return Api()
        .get(`api/flows/publish/all?page=${page}&limit=${limit}`)
        .then((response) =>
            normalizePublishedFlow(response, dataModelsForNormalize),
        )
}

export function getPublishById({ id }) {
    return Api().get(`api/flows/${id}`)
}

export function publishedCode({ slug, version, apiKey, body }) {
    return Api().test(
        `api/flows/publish/${slug}/v${version}`,
        JSON.parse(body),
        { key: apiKey },
    )
}

export function archivePublishedFlowById(id) {
    return Api().patch(`api/flows/archive/publish/${id}`)
}
