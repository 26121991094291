import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

function normalizeIntegrations(response, dataModelsForNormalize) {
    if (response?.integrations?.length) {
        const data = response?.integrations.map((item) => {
            return {
                ...item.models[0],
                dataModelName: dataModelsForNormalize.find(
                    (dm) => dm._id == item._id,
                )?.name,
                _id: item._id,
                user: Users().getUserById(item.models[0].userId)?.name,
            }
        })
        return data.sort(
            (a, b) => Date.parse(a.createdAt) - Date.parse(a.createdAt),
        )
    }
    return []
}

function normalizeIntegraion(response, dataModelsForNormalize) {
    if (response.integration) {
        return {
            ...response.integration,
            _id: response.integration.integrationId,
            dataModelName: dataModelsForNormalize.find(
                (dm) => dm._id === response.integration.modelId,
            )?.name,
            user: Users().getUserById(response.integration.userId)?.name,
        }
    }
    return []
}

export function getAllIntegrations(dataModelsForNormalize) {
    const page = 1
    const limit = 1000
    return Api()
        .get(`api/integrations?page=${page}&limit=${limit}`)
        .then((response) =>
            normalizeIntegrations(response, dataModelsForNormalize),
        )
}

export function getIntegrationsByDataModelId({ page, limit, id }) {
    return Api().get(`api/integrations/data-model/${id}`)
}

export function createIntegrations(
    { name, body, id, modelId },
    dataModelsForNormalize,
) {
    return Api()
        .post('api/integrations/create', {
            name,
            modelId,
            body,
            id,
        })
        .then((response) =>
            normalizeIntegraion(response, dataModelsForNormalize),
        )
}

export function getIntegrationsById({ id }) {
    return Api().get(`api/integrations/${id}`)
}

export function updateIntegrations({
    name,
    body,
    headers,
    method,
    id,
    type,
    url,
    modelId,
}) {
    return Api().put(`api/integrations/update/${id}`, {
        name,
        body,
        method,
        type,
        url,
        headers,
        modelId,
    })
}

export function archiveIntegrationsById({ id }) {
    return Api().patch(`api/integrations/archive/${id}`)
}
