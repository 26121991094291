import React, { useState } from 'react'
import { ButtonLighth } from '../../../Atoms/Buttons/ButtonLighth'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { SelectWithLabelInput } from '../../../Atoms/Inputs/SelectWithLabel'
import { createFlow } from '../../../../store/flow/api'
import { toast } from 'react-toastify'
import { handleError } from '../../../../utils/handleErrors'
import { TextInput } from '../../../Atoms/Inputs/Text'
import s from './CreateNewFlowPopup.module.scss'

const initialNodes = [
    {
        id: '1',
        type: 'input',
        data: { label: 'Start' },
        position: { x: 250, y: 25 },
    },
    {
        id: '2',
        type: 'output',
        data: { label: 'End' },
        position: { x: 250, y: 250 },
    },
]

export const CreateFlowPopup = ({
    isOpen,
    closePopUp,
    dataModelsList,
    state,
    setState,
    stateDataModel,
}) => {
    const [name, setName] = useState()
    const [dataModel, setDataModel] = useState(dataModelsList[0]?.value)
    const [slug, setSlug] = useState()

    const handleCreateFlow = ({ dataModel, slug, name }) => {
        closePopUp(false)
        createFlow(
            {
                slug,
                name,
                modelId: dataModel,
                body: { nodes: initialNodes, edges: [] },
            },
            stateDataModel,
        )
            .then((createFlow) => {
                setState([createFlow, ...state])
                toast.success('Successfully Created')
            })
            .catch(handleError)
    }

    return (
        <>
            {isOpen && (
                <div className={s.popupWrapper}>
                    <div className={s.popupBox}>
                        <div className={s.popupHeader}>
                            <div className={s.popupName}>Create New Flow</div>
                            <div className={s.popupX}>
                                <img
                                    onClick={closePopUp}
                                    src="/assets/images/popupX.png"
                                    alt="close"
                                />
                            </div>
                        </div>

                        <div className={s.popupForm}>
                            <div className={s.popupFormItem}>
                                <TextInput
                                    label="Name"
                                    placeholder="Name"
                                    value={name}
                                    onChange={({ target }) => {
                                        setName(target.value)
                                    }}
                                />
                            </div>
                            <div className={s.popupFormItem}>
                                <TextInput
                                    label="Slug"
                                    placeholder="Slug"
                                    value={slug}
                                    onChange={({ target }) => {
                                        setSlug(target.value)
                                    }}
                                />
                            </div>
                            <div className={s.popupFormItem}>
                                <SelectWithLabelInput
                                    label="Data Model"
                                    options={dataModelsList}
                                    onChange={({ target }) => {
                                        setDataModel(target.value)
                                    }}
                                />
                            </div>
                        </div>

                        <div className={s.popupButtonsWrapper}>
                            <ButtonLighth onClick={closePopUp} value="Cancel" />
                            <ButtonSmall
                                onClick={() => {
                                    handleCreateFlow({
                                        name,
                                        dataModel,
                                        slug,
                                    })
                                }}
                                value="Create"
                                disabled={!(name && dataModel && slug)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
