import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import s from './FlowScriptEditPopup.module.scss'
import { ButtonLighth } from '../../../Atoms/Buttons/ButtonLighth'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { SelectWithLabelInput } from '../../../Atoms/Inputs/SelectWithLabel'
import { handleError } from '../../../../utils/handleErrors'
import { getComponentsByDataModelId } from '../../../../store/common/api'

export const FlowEditComponentPopup = ({
    config,
    closePopup,
    deleteComponent,
    onChange,
    modelId,
}) => {
    const [options, setOptions] = useState()
    const [selected, setSelected] = useState('')

    useEffect(() => {
        if (modelId && config?.type) {
            getComponentsByDataModelId(config?.type, modelId)
                .then((entities) => {
                    const defaultOption = { name: 'Select', value: '' }
                    setOptions([defaultOption, ...entities])
                })
                .catch(handleError)
            setSelected(options?.[1]?.value || '')
        }
    }, [modelId, config?.type])
    return config ? (
        <div className={s.popupWrapper}>
            <div className={s.popupBox}>
                <div className={s.popupHeader}>
                    <div className={s.popupName}>Edit</div>
                    <div className={s.popupX}>
                        <img
                            onClick={closePopup}
                            src="/assets/images/popupX.png"
                            alt="close"
                        />
                    </div>
                </div>
                {config?.type !== 'catalog' ? (
                    <div className={s.popupForm}>
                        <div className={s.popupFormItem}>
                            <SelectWithLabelInput
                                label={config?.type}
                                options={options}
                                // defaultValue={selected}
                                onChange={({ target }) => {
                                    setSelected(target.value)
                                }}
                            />
                        </div>
                    </div>
                ) : null}

                <div className={s.popupButtonsWrapper}>
                    <ButtonLighth onClick={deleteComponent} value="Delete" />
                    <ButtonSmall
                        value="Apply"
                        onClick={() => {
                            const select = options.find(
                                (o) => o.value === selected,
                            )
                            onChange({
                                id: select.value,
                                name: select.name,
                            })
                            closePopup()
                        }}
                    />
                </div>
            </div>
        </div>
    ) : null
}
