import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { CreateNewDataPopup } from '../../Organisms/Popups/CreateNewDataPopup'
import { DATA_MODEL_TABLE_COLUMNS } from '../../../utils/tableColumns'
import { getDataModel } from '../../../store/model/api'
import ListPage from '../../Organisms/ListPage/ListPage'

export const DataModelList = () => {
    return (
        <>
            <ListPage
                title="Data Model"
                dataModelCall={getDataModel}
                tableColumns={DATA_MODEL_TABLE_COLUMNS}
                CreatePopupComponent={CreateNewDataPopup}
                btnName="Create New Data"
            />
        </>
    )
}
