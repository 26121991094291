import { TextInput } from '../../Atoms/Inputs/Text'
import React from 'react'

export function CreateVariableFields({ entity, onEntityChange }) {
    return (
        <>
            <div className="row">
                <TextInput
                    label="Key"
                    value={entity.key}
                    onChange={({ target }) => {
                        onEntityChange('key', target.value)
                    }}
                    placeholder="Key"
                />
            </div>
            <div className="row">
                <TextInput
                    label="Default Value"
                    value={entity.defaultValue}
                    onChange={({ target }) => {
                        onEntityChange('defaultValue', target.value)
                    }}
                    placeholder="Default Value"
                />
            </div>
        </>
    )
}
