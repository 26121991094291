import React from 'react'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { SelectWithLabelInput } from '../../../Atoms/Inputs/SelectWithLabel'
import s from './AddNewSegmentPopup.module.scss'

export const AddNewSegmentPopup = ({
    setShowSegmentPopup,
    segmentList,
    setSelectedSegment,
    selectedSegment,
    addSegment,
}) => {
    return (
        <div className={s.popupWrapper}>
            <div className={s.popupBox}>
                <div className={s.popupHeader}>
                    <div className={s.popupName}>Add New Segment</div>
                    <div className={s.popupX}>
                        <img
                            onClick={() => setShowSegmentPopup(false)}
                            src="/assets/images/popupX.png"
                            alt="close"
                        />
                    </div>
                </div>

                <div className={s.popupForm}>
                    <div className={s.popupFormItem}>
                        <SelectWithLabelInput
                            options={segmentList}
                            defaultValue={selectedSegment}
                            onChange={({ target }) =>
                                setSelectedSegment(target.value)
                            }
                        />
                    </div>
                </div>

                <div className={s.popupButtonsWrapper}>
                    <ButtonSmall onClick={addSegment} value="Add Segment" />
                </div>
            </div>
        </div>
    )
}
