import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import editGreen from './../../../imgs/EditGreen.png'
import setingGreen from './../../../imgs/SettingGreen.png'
import { ActiveAPIPopup } from '../../Organisms/Popups/ActiveAPIPopup/ActiveAPIPopup'
import {
    getAllPublishedFlow,
    getPublishById,
} from '../../../store/activeAPI/api'
import { getDataById, getDataModel } from '../../../store/model/api'
import { handleError } from '../../../utils/handleErrors'
import { FlowDetail } from '../FlowList/FlowDetail'
import { formatDate } from '../../../Services/dateFormat'
import ListPage from '../../Organisms/ListPage/ListPage'

export const PublishedList = (props) => {
    const [showPopup, setShowPopup] = useState(false)
    const [slug, setSlug] = useState()
    const [modelBody, setModelBody] = useState('{}')
    const [apiKey, setApiKey] = useState()
    const [version, setVersion] = useState()
    const [flowDetail, setFlowDetail] = useState(null)

    const openFlowDetail = (item) => {
        getPublishById({ id: item.data.flowId })
            .then((publishById) => {
                setFlowDetail(publishById)
            })
            .catch(handleError)
    }

    const editDataItem = (item) => {
        getDataById({ id: item.data.modelId })
            .then((dataById) => {
                setShowPopup(true)
                setModelBody(dataById.bodyInput)
                setSlug(item.data.slug)
                setVersion(item.data.version)
                setApiKey(item.data.apiKey)
            })
            .catch(handleError)
    }
    const closeFlowDetail = () => {
        setFlowDetail(false)
    }

    const copyText = useCallback((text) => {
        navigator.clipboard.writeText(text)
        toast.success('Successfully copied')
    }, [])
    const activeAPIsTableColumn = [
        { field: 'slug', filter: true },
        { field: 'version', filter: true },
        { headerName: 'Data Model', field: 'dataModel', filter: true },
        {
            field: 'createdAt',
            filter: true,
            sort: true,
            valueGetter: (params) => {
                return formatDate(new Date(params.data.createdAt))
            },
        },
        {
            headerName: 'Created By',
            field: 'user',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Actions',
            cellRenderer: (params) => {
                return (
                    <div className="icons">
                        <img
                            src={editGreen}
                            onClick={() => editDataItem(params)}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                        <img
                            src={setingGreen}
                            onClick={() => openFlowDetail(params)}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                )
            },
        },
    ]
    return (
        <>
            {showPopup && (
                <ActiveAPIPopup
                    setShowPopup={setShowPopup}
                    slug={slug}
                    version={version}
                    apiKey={apiKey}
                    setModelBody={setModelBody}
                    modelBody={modelBody}
                />
            )}
            <ListPage
                title="Published Decison Trees"
                dataModelCall={getDataModel}
                dataCall={getAllPublishedFlow}
                tableColumns={activeAPIsTableColumn}
                FlowPanel={FlowDetail}
                flowDetail={flowDetail}
                setFlowDetail={setFlowDetail}
                closeFlowDetail={closeFlowDetail}
                setOpenPanel={props.setOpenPanel}
                editDataItem={editDataItem}
            />
        </>
    )
}
