import React from "react";
import s from './Text.module.scss'

export const TextInput = (
            {
                onChange = () => {}, 
                onBlur = ()=>{},
                placeholder="", 
                value="", 
                error, 
                label="", 
                marginBottom=0, 
                type="text",
                min,
                disabled=false,
                marginTop=8,
                defaultValue=""
            }
        ) => {
    return <label style={{marginBottom: marginBottom}} className={s.formInput}>
        {label}
        <input style={{marginTop: marginTop}}  type={type} onBlur={onBlur} defaultValue={defaultValue} onChange={onChange} minLength={min} placeholder={placeholder} value={value} disabled={disabled? "disabled": ""} />
        {error && <span>{error}</span>}
    </label>
}