import { Api } from '../../Services/Api'
import { getRoleById } from '../role/api'

export function login({ email, password }) {
    return Api().post('api/users/login', {
        email,
        password,
    })
}
const normalizeUsers = async (response) => {
    if (response?.users?.length) {
        const data = await Promise.all(
            response.users.map(async (user) => {
                const role = await getRoleById(user.role)
                return {
                    ...user,
                    roleName: role != 'ADMIN' ? role.name : 'Admin',
                    _id: user.id,
                }
            }),
        )
        return data.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
        )
    }
    return []
}

export function getAllUsers({ page = 1, limit = 10000 }) {
    return Api()
        .get(`api/users/all?page=${page}&limit=${limit}`)
        .then((response) => {
            return normalizeUsers(response)
        })
}

export function getUserById(id) {
    console.log('param id', id)
    return Api()
        .get(`api/users/${id}`)
        .then((response) => {
            return { _id: response.user.id, ...response.user }
        })
}

export function addUsers({ name, email, phone, password, role, dataModels }) {
    return Api().post('api/users/create', {
        name,
        email,
        password,
        role,
        dataModels,
        phone,
        clientId: 0,
    })
}

export function updateUsers({ editName, password, phone, oldPassword }) {
    return Api().put('api/users/update', {
        name: editName,
        password,
        phone,
        oldPassword,
    })
}

export function archiveUserById(id) {
    return Api().patch(`api/users/archive/${id}`)
}
