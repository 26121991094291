import React from 'react'

import { Node } from './value-setter-node'
import { RenderCondition } from './value-setter-condition'

export const Segment = ({
    stateCurrentTree,
    stateLevelLength,
    stateCurrentVariables,
    removeVariable,
    addSegmentChild,
    toggleRow,
    setSegmentArchivePopup,
    updateVariable,
}) => {
    return (
        <div className="formTableWrapper">
            {stateCurrentTree && stateCurrentTree.main && (
                <Node
                    stateCurrentTree={stateCurrentTree}
                    level={stateCurrentTree.level}
                    stateLevelLength={stateLevelLength}
                    stateCurrentVariables={stateCurrentVariables}
                    removeVariable={removeVariable}
                />
            )}

            <div className="formTable">
                {stateCurrentTree &&
                    stateCurrentTree?.conditions?.map((item) => {
                        return (
                            <RenderCondition
                                level={stateCurrentTree.level}
                                stateLevelLength={stateLevelLength}
                                updateVariable={updateVariable}
                                key={item.id}
                                removeVariable={removeVariable}
                                treeId={stateCurrentTree.treeId}
                                item={item}
                                toggleRow={toggleRow}
                                stateCurrentVariables={stateCurrentVariables}
                                addSegmentChild={addSegmentChild}
                                setSegmentArchivePopup={setSegmentArchivePopup}
                            />
                        )
                    })}
            </div>
        </div>
    )
}
