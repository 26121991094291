import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { handleError } from '../../../../utils/handleErrors'
import { ButtonLighth } from '../../../Atoms/Buttons/ButtonLighth'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { createScorings } from '../../../../store/score/api'
import { SelectWithLabelInput } from '../../../Atoms/Inputs/SelectWithLabel'
import { TextInput } from '../../../Atoms/Inputs/Text'
import s from './CreateNewScorePopup.module.scss'

export const CreateNewScorePopup = ({
    closePopUp,
    dataModelsList,
    onCreate,
    setShowPopup,
    state,
    stateDataModel,
    setState,
}) => {
    const [scoreName, setScoreName] = useState('')
    const [dataNameScore, setDataNameScore] = useState(dataModelsList[0]?.value)

    const handleCreateScore = (
        { dataNameScore, scoreName },
        dataModelsList,
    ) => {
        setShowPopup(false)
        createScorings(
            {
                name: scoreName,
                segments: [],
                results: [],
                modelId: dataNameScore,
            },
            dataModelsList,
        )
            .then((score) => {
                setState([score, ...state])
                // setFlows([createFlow, ...flows])
                toast.success('Successfully Created')
            })
            .catch(handleError)
    }
    return (
        <div className={s.popupWrapper}>
            <div className={s.popupBox}>
                <div className={s.popupHeader}>
                    <div className={s.popupName}>Create New ScoreResults</div>
                    <div className={s.popupX}>
                        <img
                            onClick={() => closePopUp(false)}
                            src="/assets/images/popupX.png"
                            alt="close"
                        />
                    </div>
                </div>

                <div className={s.popupForm}>
                    <div className={s.popupFormItem}>
                        <TextInput
                            label="Score Name"
                            placeholder="Score Name"
                            value={scoreName}
                            onChange={({ target }) => {
                                setScoreName(target.value)
                            }}
                        />
                    </div>
                    <div className={s.popupFormItem}>
                        <SelectWithLabelInput
                            label="Data Model"
                            options={dataModelsList}
                            onChange={({ target }) => {
                                setDataNameScore(target.value)
                            }}
                        />
                    </div>
                </div>

                <div className={s.popupButtonsWrapper}>
                    <ButtonLighth
                        onClick={() => closePopUp(false)}
                        value="Cancel"
                    />
                    <ButtonSmall
                        onClick={() =>
                            handleCreateScore(
                                { scoreName, dataNameScore },
                                dataModelsList,
                            )
                        }
                        value="Create"
                    />
                </div>
            </div>
        </div>
    )
}
