import React, { useCallback, useEffect, useState } from 'react';
import s from "./ScoreList.module.scss";
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { SidebarBlack } from '../../Organisms/SidebarBlack';
import { HeaderBlack } from '../../Organisms/HeaderBlack';
import { CreateNewScorePopup } from '../../Organisms/Popups/CreateNewScorePopup';
import Table from '../../Organisms/Tables/Table/Table';
import { scoreTableColumn } from '../../../utils/tableColumns';
import { createScorings, getAllScorings } from '../../../store/score/api';
import { getDataModel } from '../../../store/model/api';
import { handleError } from '../../../utils/handleErrors';
import ListPage from '../../Organisms/ListPage/ListPage';

export const ScoreList = () => {
    return (
      
        <>             
                <ListPage 
                 title="ScoreResults"
                 dataModelCall={getDataModel}  
                 dataCall={getAllScorings} 
                 tableColumns={scoreTableColumn}
                 CreatePopupComponent = {CreateNewScorePopup}
                 btnName="Create New ScoreResults" />
                </>
        
    )
}