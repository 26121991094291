import { Handle, Position } from 'react-flow-renderer';
import s from './nodes.module.scss'

export function IntegrationNode(props) {
  return (
    <>
      <Handle type="target" position={Position.Top} />
      <div className={s.integrationNodeContent} >
        <label htmlFor="text">Integration: {props.data.label}</label>
      </div>
      <Handle type="source" position={Position.Bottom}/>
    </>
  );
}