import { Api } from '../../Services/Api'

function normalizelogs(response) {
    return response
}

export function getAllLogs(page) {
    return Api()
        .get(`api/logs/all?page=${page}&limit=${10}`)
        .then((response) => normalizelogs(response))
}

export function getLogsById({ id }) {
    return Api().get(`api/logs/${id}`)
}
