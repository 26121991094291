import React from 'react'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function PublicWrapper(props) {
  const history = useHistory()

  useEffect(() => {
    let token =  window.localStorage.getItem('Authorization');
    if (token){
          history.push('/flows')
    }
    }, []);

  return (
  <>
    <div>{props.children}</div>
  </>
  )
}

export default PublicWrapper