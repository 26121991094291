import React, { useCallback, useEffect, useState } from 'react'
import { TextInput } from '../../Atoms/Inputs/Text'
import s from './Profile.module.scss'
import { ButtonSmall } from '../../Atoms/Buttons/ButtonSmall'
import { Header } from '../../Organisms/Header'
import { Sidebar } from '../../Organisms/Sidebar/Sidebar'
import { updateUsersAction } from '../../../store/user/actions'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { handleError } from '../../../utils/handleErrors'

export const Profile = () => {
    const dispatch = useDispatch()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [oldPassword, setOldPassword] = useState()
    const [user, setUser] = useState()

    const handleEditUser = useCallback(() => {
        dispatch(
            updateUsersAction({
                password,
                oldPassword,
                confirmPassword,
                editName: user?.name,
                phone: user?.phone,
            }),
        )
            .then(() => {
                toast.success('Successfully Changed')
            })
            .catch(handleError)
    }, [
        password,
        oldPassword,
        confirmPassword,
        user?.name,
        user?.phone,
        dispatch,
    ])

    useEffect(() => {
        let User = window.localStorage.getItem('User')
        User && setUser(JSON.parse(User))
    }, [])

    if (window.localStorage.getItem('Authorization') === null)
        return <Redirect to="/main" />

    return (
        <>
            <ToastContainer />
            <div className={s.mainWrapper}>
                <div className={s.sidebarWrapper}>
                    <Sidebar />
                </div>
                <div className={s.contentWrapper}>
                    <Header title="My Profile" />
                    <div className={s.mainComponentLogin}>
                        <div className={s.mainComponent}>
                            <div className={s.profileWrapper}>
                                <div className={s.profileBox}>
                                    <div className={s.profileImage}>
                                        <img
                                            alt="Profile_Image"
                                            src="/assets/images/profileImage.png"
                                        />
                                    </div>
                                    <div className={s.profileName}>
                                        {user?.name}
                                    </div>
                                    <div className={s.profileEmail}>
                                        {user?.email}
                                    </div>
                                    <div className={s.profileRole}>
                                        Administrator
                                    </div>
                                </div>
                                <div className={s.editUserWrapper}>
                                    <div className={s.editUserTitle}>
                                        Password Change
                                    </div>
                                    <div className={s.editUserBox}>
                                        <div className={s.editUserInput}>
                                            <TextInput
                                                type="password"
                                                label="Current Password"
                                                placeholder="Current Password"
                                                value={oldPassword}
                                                onChange={({ target }) => {
                                                    setOldPassword(target.value)
                                                }}
                                            />
                                        </div>
                                        <div className={s.editUserInput}>
                                            <TextInput
                                                min={6}
                                                type="password"
                                                label="New Password"
                                                placeholder="New Password"
                                                value={password}
                                                onChange={({ target }) => {
                                                    setPassword(target.value)
                                                }}
                                            />
                                        </div>
                                        <div className={s.editUserInput}>
                                            <TextInput
                                                min={6}
                                                type="password"
                                                label="Confirm New Password"
                                                placeholder="Confirm New Password"
                                                value={confirmPassword}
                                                onChange={({ target }) => {
                                                    setConfirmPassword(
                                                        target.value,
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className={s.formText}>
                                            *Password must be at least 8
                                            characters including a number an
                                            uppercase letter and special
                                            character.
                                        </div>

                                        <div className={s.buttonsWrapper}>
                                            <ButtonSmall
                                                onClick={handleEditUser}
                                                value="Change"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
