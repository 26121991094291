import React from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ToastContainer } from 'react-toastify'
import { SidebarBlack } from '../Components/Organisms/SidebarBlack'
import { HeaderBlack } from '../Components/Organisms/HeaderBlack'
import { Users } from '../Services/users'

function AuthWrapper(props) {
  const history = useHistory()
  const [initialLoad, setInitialLoad] = useState(false)

  useEffect(() => {
    let token =  window.localStorage.getItem('Authorization');
    if (token){
      Users().initialLoad().then(()=>{
        setInitialLoad(true)
      })
    } else {
        history.push('/');
    }
  },[])

    useEffect(() => {

        history.listen((location, action) => {
            let token =  window.localStorage.getItem('Authorization');
            if (!token){
                history.push('/');
            }
        })

    }, [])
  
  return (
    <div>
         <div className="mainWrapper">
            <ToastContainer/>
            <HeaderBlack/>
            <div className="contentWrapper">
            <SidebarBlack activeItem={props.id} />
                {initialLoad && props.children}
            </div>
        </div>
    </div>
  )
}

export default AuthWrapper