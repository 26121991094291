import { ButtonWithIcon } from '../../../Atoms/Buttons/ButtonWithIcon'
import React from 'react'

export function ArchivePopup({
    children,
    isOpen,
    togglePanel,
    onDelete,
    text = 'Are you sure, you want to delete',
}) {
    return (
        <>
            {isOpen ? (
                <div className="overlead">
                    <div
                        className="logOutForm"
                        component="form"
                        sx={{ borderRadius: '5px' }}
                    >
                        <h4>
                            {text} {children} ?
                        </h4>
                        <div className="LogOutPanelButtons">
                            <ButtonWithIcon
                                color="error"
                                type="submit"
                                value="Yes"
                                red={true}
                                onClick={onDelete}
                                sx={{ width: '25%', margin: '20px' }}
                                variant="contained"
                            ></ButtonWithIcon>
                            <ButtonWithIcon
                                color="inherit"
                                type="submit"
                                value="No"
                                grey={true}
                                onClick={() => togglePanel(false)}
                                sx={{
                                    color: 'black',
                                    width: '25%',
                                    margin: '20px',
                                }}
                                variant="contained"
                            ></ButtonWithIcon>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
}
