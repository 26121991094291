import React, { useRef, useState } from 'react'
import ListPage from '../../Organisms/ListPage/ListPage'
import 'react-toastify/dist/ReactToastify.css'
import { ModalEditObject } from './ModalEditObject'
import DeletIcon from './../../../imgs/Delete.png'
import { CreateNewDataStore } from '../../Organisms/Popups/CreateNewDataStorePopup/CreateNewDataStorePopup'
import { useParams } from 'react-router-dom'
import { TextInput } from '../../Atoms/Inputs/Text'
import { createDataStorage } from '../../../store/dataStorage/api'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import Switch from '../role-pages/Switch'
import { getDataModel } from '../../../store/model/api'
import { toast } from 'react-toastify'

export const CreateDataStore = () => {
    const [edit, setEdit] = useState(false)
    const [rowId, setRowId] = useState('')
    const [key, setKey] = useState('')
    const [objectValue, setObjectValue] = useState('')
    const [showPopup, setShowPopup] = useState(false)

    const [column, setColumn] = useState([])
    const { id } = useParams()
    const editorRef = useRef(null)

    const handleInputChange = (event) => {
        const inputText = event.target.value
        try {
            JSON.parse(inputText)
            // setIsValidJson(true);
            return true
        } catch (error) {
            toast.error('isNot Valid Object')
            return false
        }
    }

    const DataStoreColumn = [
        {
            headerName: 'Name',
            field: 'name',
            cellRenderer: (params) => {
                const id = params.data?.id
                return (
                    <div style={{ width: '150px', height: '60px' }}>
                        <TextInput
                            marginTop={'4px'}
                            value={params.value}
                            onChange={({ target }) =>
                                params.setValue(target.value)
                            }
                            onBlur={({ target }) =>
                                editColumn(id, target.value, 'key')
                            }
                        />
                    </div>
                )
            },
        },
        {
            headerName: 'Type',
            field: 'type',
            cellRenderer: (params) => {
                const id = params.data?.id
                const value = params.data?.columnType
                return (
                    <div style={{ width: '150px', height: '60px' }}>
                        <SelectWithLabelInput
                            marginTop={'4px'}
                            options={['string', 'number', 'boolean', 'object']}
                            defaultValue={value}
                            onChange={({ target }) => {
                                editColumn(id, target.value, 'columnType')
                            }}
                        />
                    </div>
                )
            },
        },
        {
            headerName: 'Default Value',
            field: 'defaultValue',
            cellRenderer: (params) => {
                const id = params.data?.id
                const type = params.data?.columnType
                return (
                    <div
                        style={{
                            width: '200px',
                            height: '60px',
                            marginBottom: '5px',
                        }}
                    >
                        {type == 'object' ? (
                            <textarea
                                onClick={(e) => {
                                    setObjectValue(e.target.value)
                                    setRowId(id)
                                    setKey('defaultValue')
                                    setShowPopup(true)
                                }}
                                style={{
                                    width: '100%',
                                    height: '52px',
                                    borderRadius: '7px',
                                    marginTop: '7px',
                                }}
                                rows="5"
                                cols="25"
                                defaultValue={params.data.defaultValue}
                                onBlur={(e) => {
                                    const isValid =
                                        handleInputChange(objectValue)
                                    if (isValid) {
                                    }
                                }}
                            />
                        ) : type == 'boolean' ? (
                            <SelectWithLabelInput
                                options={['false', 'true']}
                                defaultValue={params.data.defaultValue}
                                onChange={({ target }) =>
                                    editColumn(
                                        id,
                                        JSON.parse(target.value),
                                        'defaultValue',
                                    )
                                }
                            />
                        ) : (
                            <TextInput
                                placeholder={
                                    type == 'object' ? '"{"a":"5"}"' : ''
                                }
                                type={type == 'number' ? 'number' : 'text'}
                                value={params.value}
                                onChange={({ target }) =>
                                    params.setValue(target.value)
                                }
                                onBlur={({ target }) =>
                                    editColumn(id, target.value, 'defaultValue')
                                }
                            />
                        )}
                    </div>
                )
            },
        },
        {
            headerName: 'Not Null ?',
            field: 'notNull',

            cellRenderer: (params) => {
                const id = params.data?.id
                return (
                    <Switch
                        isOn={params.value}
                        perm={params.data?.id}
                        colorOne="#01AA6B"
                        colorTwo="#292626"
                        handleToggle={(e) =>
                            editColumn(id, !params.value, 'notNull')
                        }
                    />
                )
            },
        },
        {
            headerName: 'Unique',
            field: 'uniqueColumn',
            cellRenderer: (params) => {
                const id = params.data?.id
                return (
                    <Switch
                        perm={params.data?.id + 'uinq'}
                        isOn={params.value}
                        colorOne="#01AA6B"
                        colorTwo="#292626"
                        handleToggle={() =>
                            editColumn(id, !params.value, 'uniqueColumn')
                        }
                    />
                )
            },
        },
        {
            field: 'Delete',
            maxWidth: 120,
            cellRenderer: (params) => {
                return (
                    <img
                        onClick={() =>
                            setColumn(
                                column.filter((i) => i.id !== params.data?.id),
                            )
                        }
                        src={DeletIcon}
                        style={{
                            cursor: 'pointer',
                            paddingRight: '5px',
                            paddingLeft: '15px',
                        }}
                    />
                )
            },
        },
    ]

    const editColumn = (id, value, key) => {
        const updatedColumn = column.map((item) => {
            if (item.id === id) {
                return { ...item, [key]: value }
            }
            return item
        })
        setColumn(updatedColumn)
    }

    const addColumn = () => {
        setColumn([
            {
                key: '',
                columnType: 'string',
                defaultValue: '',
                notNull: false,
                uniqueColumn: false,
                id: Math.random(),
            },
            ...column,
        ])
    }
    const addDataStorage = (id, column) => {
        createDataStorage(id, column)
            .then((r) => toast.success('You Create Data Storage'))
            .catch((e) => toast.error(e))
    }
    return (
        <>
            {showPopup && (
                <ModalEditObject
                    setShowPopup={setShowPopup}
                    editColumn={editColumn}
                    objectValue={objectValue}
                    setObjectValue={setObjectValue}
                    key1={key}
                    rowId={rowId}
                />
            )}
            <ListPage
                column={column}
                addColumn={addColumn}
                title={`Data Store ${id}`}
                dataModelCall={getDataModel}
                name={id}
                tableColumns={DataStoreColumn}
                addDataStorage={addDataStorage}
                savedBtn="Save"
                otherBtns="Add Column"
                CreatePopupComponent={CreateNewDataStore}
            />
        </>
    )
}
