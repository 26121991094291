import React from 'react'
import MonacoEditor from '@monaco-editor/react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { handleError } from '../../../utils/handleErrors'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { TextInput } from '../../Atoms/Inputs/Text'
import { SinglePageHook } from '../../../hooks/single-page-hook'
import { updateEntity } from '../../../store/common/api'
import { Header } from '../../Organisms/single-page-common/header'

const AVAILABLE_DBS = [
    { name: 'Oracle', value: 1 },
    { name: 'MySQL', value: 2 },
    { name: 'PostgreSQL', value: 3 },
]

const entityType = 'integrationDB'
const entityURL = 'integrationDBs'
export const IntegrationDb = () => {
    const { id, entity, setEntity, versions, setVersions } = SinglePageHook({
        entityType,
    })

    const onEntityChange = (key, value) => {
        setEntity({
            ...entity,
            [key]: value,
        })
    }
    const onEntityUpdate = () => {
        if (entity.connectionString?.trim()) {
            updateEntity(id, entityType, {
                name: entity.name,
                requestString: entity.requestString,
                username: entity.username,
                password: entity.password,
                type: +entity.type,
                connectionString: entity.connectionString,
            })
                .then((updatedEntity) => {
                    console.log(updatedEntity)
                    setEntity(updatedEntity)
                    setVersions((prevState) => {
                        return [updatedEntity, ...prevState]
                    })
                    toast.success('Successfully Updated')
                })
                .catch(handleError)
        } else {
            toast.error('Body is empty')
        }
    }

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <div className="integration-db-content">
                        {entity && (
                            <>
                                <Header
                                    entityType={entityType}
                                    onEntityUpdate={onEntityUpdate}
                                    setEntity={setEntity}
                                    entity={entity}
                                    entityURL={entityURL}
                                    versions={versions}
                                />
                                <div className="single-table-wrapper">
                                    <div className="inputsWrapper">
                                        <div className="row">
                                            <span className="inputTitle">
                                                Data Bases
                                            </span>
                                            <SelectWithLabelInput
                                                defaultValue={entity.type}
                                                onChange={({ target }) => {
                                                    onEntityChange(
                                                        'type',
                                                        target.value,
                                                    )
                                                }}
                                                options={AVAILABLE_DBS}
                                            />
                                        </div>
                                        <div className="row">
                                            <span className="inputTitle">
                                                Username
                                            </span>
                                            <TextInput
                                                value={entity.username}
                                                onChange={({ target }) => {
                                                    onEntityChange(
                                                        'username',
                                                        target.value,
                                                    )
                                                }}
                                                placeholder="Username"
                                            />
                                        </div>
                                        <div className="row">
                                            <span className="inputTitle">
                                                Password
                                            </span>
                                            <TextInput
                                                value={entity.password}
                                                onChange={({ target }) => {
                                                    onEntityChange(
                                                        'password',
                                                        target.value,
                                                    )
                                                }}
                                                placeholder="Password"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <span>Connection String</span>
                                        <TextInput
                                            value={entity.connectionString}
                                            onChange={({ target }) => {
                                                onEntityChange(
                                                    'connectionString',
                                                    target.value,
                                                )
                                            }}
                                            placeholder="Connection String"
                                        />
                                    </div>
                                    <div className="monacoField">
                                        <h1 className="monacoTitle">Body</h1>
                                        <MonacoEditor
                                            defaultValue={entity.requestString}
                                            defaultLanguage="javascript"
                                            theme="vs-dark"
                                            value={entity.requestString}
                                            onChange={(data) =>
                                                onEntityChange(
                                                    'requestString',
                                                    data,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
