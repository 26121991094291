import React, { useEffect, useState } from 'react'
import { handleError } from '../../../utils/handleErrors'
import { ArchivePopup } from '../../Organisms/Popups/General/ArchivePopup'
import 'react-toastify/dist/ReactToastify.css'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { toast } from 'react-toastify'
import CloseWrapper from '../../../utils/CloseWrapper'
import { archiveFlowById } from '../../../store/flow/api'
import { TakeLivePopup } from '../../Organisms/Popups/TakeLivePopup'
import { archivePublishedFlowById } from '../../../store/activeAPI/api'

export const FlowDetail = ({
    flowDetail,
    state,
    setState,
    closeFlowDetail,
    setFlowDetail,
    // setDeletePanelOpen,
}) => {
    const [isDeletePanelOpen, setDeletePanelOpen] = useState(false)
    const [serchedVersion, setserchedVersion] = useState([])
    const [showLivePopup, setShowLivePopup] = useState(false)
    useEffect(() => {
        setserchedVersion([])
    }, [flowDetail])
    const onArchiveFlow = () => {
        archiveFlowById(flowDetail.flow.flowId)
            .then((res) => {
                setState(state.filter((f) => f._id !== flowDetail.flow.flowId))
                setDeletePanelOpen(false)
                setFlowDetail(null)
            })
            .catch(handleError)
    }
    const onArchivePublishedFlow = () => {
        archivePublishedFlowById(flowDetail.flow.modelId)
            .then((res) => {
                setState(res.filter((f) => f._id !== flowDetail.flow.flowId))
                setDeletePanelOpen(false)
                setFlowDetail(null)
            })
            .catch(handleError)
    }
    if (!flowDetail) {
        return null
    }

    return (
        <>
            <CloseWrapper
                isOpen={flowDetail?.flow}
                onClose={closeFlowDetail}
            ></CloseWrapper>
            <div className="editWrapper">
                <div className="editHeader">
                    <div className="editTitle">Settings</div>
                    <div className="close" onClick={() => closeFlowDetail()}>
                        <img alt="x" src="assets/images/settingsX.png" />
                    </div>
                </div>
                <div className="editBox">
                    <ul className="infoList">
                        <li>
                            <div className="infoListName">Name</div>
                            <div className="infoListValue">
                                {flowDetail?.flow?.name}
                            </div>
                        </li>
                        <li>
                            <div className="infoListName">Main slug</div>
                            <div className="infoListValue">
                                {`{{url}} `}/api/flows/publish/
                                {flowDetail?.flow.slug}
                            </div>
                        </li>
                        <li>
                            <div className="infoListName">Flow Version</div>
                            <div className="infoListValue">
                                {flowDetail?.flow.version}
                            </div>
                        </li>
                        <li>
                            <div className="infoListName">Created</div>
                            <div className="infoListValue">
                                {flowDetail?.flow.createdAt}
                            </div>
                        </li>
                        <li>
                            <div className="infoListName">Created by</div>
                            <div className="infoListValue">
                                {flowDetail?.user.name}
                            </div>
                        </li>
                        <li>
                            <div className="infoListName">Data Model</div>
                            <div className="infoListValue">
                                {flowDetail?.dataModel.name}
                            </div>
                        </li>
                        <li>
                            <div className="infoListName">
                                Response / Request
                            </div>
                            <div className="infoListValue">Show</div>
                        </li>
                    </ul>
                    <hr className="line" />
                    <div className="api-box">
                        <div className="searchPanel">
                            <h4>Staging</h4>
                            <div className="VersionSearchheaderSearch">
                                <img
                                    src="/assets/images/Search.png"
                                    alt="search"
                                />
                                <input
                                    placeholder="Search Version"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                        setserchedVersion(
                                            flowDetail.publishedFlows.filter(
                                                (i) =>
                                                    i.version == e.target.value,
                                            ),
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        {serchedVersion.length
                            ? serchedVersion?.map((item) => (
                                  <ul className="apiBox" key={item.id}>
                                      <li>
                                          <div className="apiLabel">
                                              <div className="apiLabelName">
                                                  Endpoint
                                              </div>
                                              <div
                                                  onClick={() =>
                                                      setShowLivePopup(true)
                                                  }
                                                  className="apiLabelbutton"
                                              >
                                                  Take Live
                                              </div>
                                          </div>
                                          <div className="apiValue">
                                              <div className="apiValueText">
                                                  {`{{url}} `}
                                                  /api/flows/publish/
                                                  {item.slug}/v
                                                  {item.version}
                                              </div>
                                              <div className="apiValueAction">
                                                  <img
                                                      alt="copy"
                                                      src="assets/images/copy.png"
                                                      onClick={() => {
                                                          toast.success(
                                                              'Copied',
                                                          )
                                                          navigator.clipboard.writeText(
                                                              `${window.location.protocol}//${window.location.hostname}/api/flows/publish/${item.slug}/v${item.version}`,
                                                          )
                                                      }}
                                                  />
                                              </div>
                                          </div>
                                      </li>
                                      <li>
                                          <div className="apiLabel">
                                              <div className="apiLabelName">
                                                  API Key
                                              </div>
                                          </div>
                                          <div className="apiValue">
                                              <div className="apiValueText">
                                                  {item.apiKey.substring(0, 20)}
                                                  ...
                                              </div>
                                              <div className="apiValueAction">
                                                  <img
                                                      alt="copy"
                                                      src="assets/images/copy.png"
                                                      onClick={() => {
                                                          toast.success(
                                                              'Copied',
                                                          )
                                                          navigator.clipboard.writeText(
                                                              item.apiKey,
                                                          )
                                                      }}
                                                  />
                                              </div>
                                          </div>
                                      </li>
                                  </ul>
                              ))
                            : flowDetail.publishedFlows
                                  .sort((a, b) => b.version - a.version)
                                  .map((item) => (
                                      <ul className="apiBox" key={item.id}>
                                          <li>
                                              <div className="apiLabel">
                                                  <div className="apiLabelName">
                                                      Endpoint
                                                  </div>
                                                  <div
                                                      onClick={() =>
                                                          setShowLivePopup(true)
                                                      }
                                                      className="apiLabelbutton"
                                                  >
                                                      Take Live
                                                  </div>
                                              </div>
                                              <div className="apiValue">
                                                  <div className="apiValueText">
                                                      {`{{url}} `}
                                                      /api/flows/publish/
                                                      {item.slug}/v
                                                      {item.version}
                                                  </div>
                                                  <div className="apiValueAction">
                                                      <img
                                                          alt="copy"
                                                          src="assets/images/copy.png"
                                                          onClick={() => {
                                                              toast.success(
                                                                  'Copied',
                                                              )
                                                              navigator.clipboard.writeText(
                                                                  `${window.location.protocol}//${window.location.hostname}/api/flows/publish/${item.slug}/v${item.version}`,
                                                              )
                                                          }}
                                                      />
                                                  </div>
                                              </div>
                                          </li>
                                          <li>
                                              <div className="apiLabel">
                                                  <div className="apiLabelName">
                                                      API Key
                                                  </div>
                                              </div>
                                              <div className="apiValue">
                                                  <div className="apiValueText">
                                                      {item.apiKey.substring(
                                                          0,
                                                          20,
                                                      )}
                                                      ...
                                                  </div>
                                                  <div className="apiValueAction">
                                                      <img
                                                          alt="copy"
                                                          src="assets/images/copy.png"
                                                          onClick={() => {
                                                              toast.success(
                                                                  'Copied',
                                                              )
                                                              navigator.clipboard.writeText(
                                                                  item.apiKey,
                                                              )
                                                          }}
                                                      />
                                                  </div>
                                              </div>
                                          </li>
                                      </ul>
                                  ))}
                    </div>
                </div>
                <div className="settingsAction">
                    {window.location.pathname === '/flows' ? (
                        <ButtonWithIcon
                            onClick={() => setDeletePanelOpen(true)}
                            red={true}
                            value="Delete Flow"
                        />
                    ) : null}
                </div>
            </div>

            <ArchivePopup
                isOpen={isDeletePanelOpen}
                togglePanel={setDeletePanelOpen}
                onDelete={
                    window.location.pathname === '/published-flows'
                        ? onArchivePublishedFlow
                        : onArchiveFlow
                }
            >
                Are you sure, you want to delete this item?
            </ArchivePopup>
            {showLivePopup && (
                <TakeLivePopup setShowLivePopup={setShowLivePopup} />
            )}
        </>
    )
}
