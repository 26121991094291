import React, { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ButtonSmall } from '../../../Atoms/Buttons/ButtonSmall'
import { TextInput } from '../../../Atoms/Inputs/Text'
import s from './CreateNewDataStorePopup.module.scss'

export const CreateNewDataStore = ({
    isOpen,
    closePopUp,
    setState,
    dataModelsList,
    setShowPopup,
    stateDataModel,
    state,
}) => {
    const history = useHistory()
    const [name, setName] = useState('')

    return (
        <>
            {isOpen && (
                <div className={s.popupWrapper}>
                    <div className={s.popupBox}>
                        <div className={s.popupHeader}>
                            <div className={s.popupName}>Create Data Store</div>
                            <div className={s.popupX}>
                                <img
                                    onClick={closePopUp}
                                    src="/assets/images/popupX.png"
                                    alt="close"
                                />
                            </div>
                        </div>

                        <div className={s.popupForm}>
                            <div className={s.popupFormItem}>
                                <TextInput
                                    label="Create Data Store"
                                    placeholder="Create Data Store"
                                    value={name}
                                    onChange={({ target }) => {
                                        setName(target.value)
                                    }}
                                />
                            </div>
                        </div>

                        <div className={s.popupButtonsWrapper}>
                            {/* <ButtonLighth onClick={closePopUp} value="Cancel" /> */}
                            <ButtonSmall
                                disabled={!name.length}
                                onClick={() =>
                                    history.push(`/createstorage/${name}`)
                                }
                                value="Create"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
