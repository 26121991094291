import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { CreateNewIntegrationDBPopup } from '../../Organisms/Popups/CreateNewIntegrationDBPopup';
import { integrationDBTableColumn } from '../../../utils/tableColumns';
import { getAllIntegrationsDB } from '../../../store/integrationDB/api';
import { getDataModel } from '../../../store/model/api';
import ListPage from '../../Organisms/ListPage/ListPage';

export const IntegrationDBList = () => {

    return (
      
      <>
                <ListPage 
                 title="Integration DB" 
                 dataModelCall={getDataModel}  
                 dataCall={getAllIntegrationsDB} 
                 tableColumns={integrationDBTableColumn}
                 CreatePopupComponent = {CreateNewIntegrationDBPopup}
                 btnName="Create New Integration DB"
                
                />
                </>
    )
}