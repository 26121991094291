const Switch = ({ isOn, handleToggle, colorOne, colorTwo,perm }) => {
    return (
      <>
        <input
          checked={isOn}
          onChange={()=>handleToggle(perm)}
          className="switch-checkbox"
          key={perm}
          id={`switch-${perm}`}
          type="checkbox" 
        />
        <label
          style={{ background: isOn ? colorOne : colorTwo }}
          className="switch-label"
          htmlFor={`switch-${perm}`}
        >
          <span className={`switch-button`} />
        </label>
      </>
    );
  };
  
 export default Switch
  