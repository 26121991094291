import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import thunk from 'redux-thunk'
import { userReducer } from './user/reducer'
import { activityLogReducer } from './logs/reducer'

const composeEnhancers = composeWithDevTools({
    name: 'DMS Editor',
})

const reducers = combineReducers({
    users: userReducer,
    // model: modelReducer,
    // flow: flowReducer,
    // scorings: scoreReducer,
    // activeAPI: activeAPIReducer,
    // script: scriptReducer,
    // integration: integrationReducer,
    // segment: segmentReducer,
    activityLog: activityLogReducer,
    // valueSetter: valueSetterReducer,
})

const store = createStore(
    reducers,
    {},
    composeEnhancers(applyMiddleware(thunk)),
)

export default store
