import React from 'react'
import MonacoEditor from '@monaco-editor/react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { handleError } from '../../../utils/handleErrors'
import { updateEntity } from '../../../store/common/api'
import { Header } from '../../Organisms/single-page-common/header'
import { SinglePageHook } from '../../../hooks/single-page-hook'

const entityType = 'script'
const entityURL = 'scripts'

export const Script = () => {
    const { id, entity, setEntity, versions, setVersions } = SinglePageHook({
        entityType,
    })
    const onEditorChange = (body) => {
        setEntity({
            ...entity,
            body,
        })
    }

    const onEntityUpdate = () => {
        if (entity.body.trim()) {
            const { modelId, name, body } = entity
            updateEntity(id, entityType, { modelId, name, body })
                .then((updatedEntity) => {
                    setEntity(updatedEntity)
                    setVersions((prevState) => {
                        return [updatedEntity, ...prevState]
                    })
                    toast.success('Successfully Updated')
                })
                .catch(handleError)
        } else {
            toast.error('Body is empty')
        }
    }
  
    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header
                        entityType={entityType}
                        onEntityUpdate={onEntityUpdate}
                        setEntity={setEntity}
                        entity={entity}
                        entityURL={entityURL}
                        versions={versions}
                    />
                    <div className="script-content">
                        <MonacoEditor
                            defaultLanguage="javascript"
                            theme="vs-dark"
                            value={entity.body}
                            onChange={onEditorChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
