import React, { useCallback, useState } from 'react'
import { ButtonLighth } from '../../Atoms/Buttons/ButtonLighth'
import 'react-toastify/dist/ReactToastify.css'
import s from './../../Organisms/Popups/ActiveAPIPopup/ActiveAPIPopup.module.scss'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import MonacoEditor from '@monaco-editor/react'

export const ModalEditObject = ({
    setShowPopup = () => {},
    objectValue,
    editColumn,
    key1,
    rowId,
}) => {
    const [value, setValue] = useState(objectValue)

    const onCancel = useCallback(() => {
        setValue(null)
        setShowPopup(false)
    }, [setShowPopup, setValue])

    return (
        <div className={s.popupWrapper}>
            <div className={s.popupBox}>
                <div className={s.popupHeader}>
                    <div className={s.popupName}></div>
                    <div className={s.popupX}>
                        <img
                            onClick={onCancel}
                            src="/assets/images/popupX.png"
                            alt="close"
                        />
                    </div>
                </div>

                <div className={s.popupContent}>
                    <div className={s.codeWrapper}>
                        <MonacoEditor
                            defaultLanguage="json"
                            theme="vs-dark"
                            value={value}
                            onChange={setValue}
                        />
                    </div>
                </div>

                <div className={s.popupButtonsWrapper}>
                    <ButtonWithIcon
                        onClick={() => {
                            editColumn(rowId, value, key1)
                            setValue(null)
                            setShowPopup(false)
                        }}
                        green={true}
                        value="Save"
                    />
                    <ButtonLighth onClick={onCancel} value="Cancel" />
                </div>
            </div>
        </div>
    )
}
