import { Api } from '../../../Services/Api'

const slugMap = {
    2: 'scripts',
    3: 'value-setters',
    4: 'scorings',
}

export function getEntityByDataModelId(modelId, type) {
    return Api()
        .get(`api/${slugMap[type]}/data-model/${modelId}`)
        .then((response) => {
            return response[slugMap[type]].map((e) => {
                return {
                    name: e.models[0].name,
                    value: e._id,
                }
            })
        })
}
