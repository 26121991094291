import React, { useCallback, useEffect, useState } from 'react';
import ListPage from '../../Organisms/ListPage/ListPage';
import 'react-toastify/dist/ReactToastify.css';
import DeletIcon from './../../../imgs/Delete.png'
import { CreateNewScriptPopup } from '../../Organisms/Popups/CreateNewScriptPopup';
import { formatDate } from "../../../Services/dateFormat";
import { CreateNewDataStore } from '../../Organisms/Popups/CreateNewDataStorePopup/CreateNewDataStorePopup';
import { createScript, getAllScripts } from '../../../store/script/api';
import { TextInput } from '../../Atoms/Inputs/Text';
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel';
import Switch from '../role-pages/Switch';
import {  getDataModel } from '../../../store/model/api';
import { handleError } from '../../../utils/handleErrors';

export const TableName = () => {
 const [column,setColumn] = useState([])

 const DataStoreColumn = [
        { headerName:'Database',field: "database"},
        { headerName:'Owner',field: "owner"},
        { headerName:'Comment',field: "comment"},
   
      ];
   
    return (
              <>
                <ListPage 
                    column = {column}
                    title="Table Name"
                    dataModelCall={getDataModel}
                    tableColumns={DataStoreColumn} 
                    savedBtn="Save"
                    CreatePopupComponent={CreateNewDataStore}
                />
              </>
    )
}