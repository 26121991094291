import React, { useState } from 'react'
import '../../Organisms/single-page-common/single-page-content-header.scss'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { ArchivePopup } from '../../Organisms/Popups/General/ArchivePopup'
import { toast } from 'react-toastify'
import { handleError } from '../../../utils/handleErrors'
import { updateEntity } from '../../../store/common/api'
import { addUsers } from '../../../store/user/api'
const entityType = 'user'

export const Header = ({ entity, updateInfo, entityURL = 'users' }) => {
    const history = useHistory()
    const params = useParams()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const type = searchParams.get('type') ?? null
    const [archivePopup, setArchivePopup] = useState(false)
    const [approvalPopup, setApprovalPopup] = useState(false)
    const cancel = () => {
        history.push(`/${entityURL}`)
        setArchivePopup(false)
    }
    const save = () => {
        addUsers(entity)
            .then(() => {
                history.push(`/${entityURL}`)
                toast.success('Successfully Created')
            })
            .catch(handleError)
    }
    const edit = () => {
        updateEntity(params.id, entityType, {
            name: entity.name,
            email: entity.email,
            phone: entity.phone,
            password: entity.password,
            role: entity.role,
            dataModels: entity.dataModels,
        })
            .then(() => {
                updateInfo(params.id)
                console.log('dsfsdfsdfsdf')
                toast.success('Successfully Updated')
            })
            .catch(handleError)
    }

    const handleSaveOrEdit = entity.id ? edit : save

    return (
        <>
            <ArchivePopup
                isOpen={archivePopup}
                togglePanel={setArchivePopup}
                onDelete={cancel}
                text="Are you sure you want to cancel"
            ></ArchivePopup>

            <div className="single-page-content-header">
                <div className="title">
                    <span>{entity.name}</span>
                </div>
                {!type ? (
                    <div className="actions">
                        <ButtonWithIcon
                            onClick={() => setArchivePopup(true)}
                            icon="/assets/images/Delete.png"
                            red={true}
                            value="Cancel"
                        />
                        <ButtonWithIcon
                            onClick={handleSaveOrEdit}
                            value="Save"
                            green={true}
                        />
                    </div>
                ) : null}
            </div>
        </>
    )
}
