import React from 'react'
import logo from './../../../imgs/dms-02.png'

export const HeaderBlack = () => {
    return (
        <div className="headerWrapper">
            <div className="headerTitle">
                <img style={{ height: '70px' }} src={logo} alt="logo" />
            </div>
            <div className="headerActionWrapper">
                {/*<div className="headerSearch">*/}
                {/*    <img src="/assets/images/Search.png" alt="search" /> */}
                {/*    <input placeholder="Type here to search" />*/}
                {/*</div>*/}
                {/*<div className="headerNotification">*/}
                {/*    <img src="/assets/images/Notification.png" alt="Notification" />*/}
                {/*</div>*/}
                {/*<div className="headerSettings">*/}
                {/*    <img src="/assets/images/Setting.svg" alt="Setting" />*/}
                {/*</div>*/}
                <div className="headerProfile">
                    <img src="/assets/images/Profile.svg" alt="Profile" />
                </div>
            </div>
        </div>
    )
}
