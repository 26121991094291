import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MonacoEditor from '@monaco-editor/react'
import { handleError } from '../../../utils/handleErrors'
import { SinglePageHook } from '../../../hooks/single-page-hook'
import { Header } from '../../Organisms/single-page-common/header'
import { updateEntity } from '../../../store/common/api'

const entityType = 'data-model'
const entityURL = 'data-models'

export const DataModel = () => {
    const { id, entity, setEntity, versions, setVersions } = SinglePageHook({
        entityType,
    })
    const onDataModelChange = (e, key) => {
        setEntity({
            ...entity,
            [key]: e,
        })
    }
    const onEntityUpdate = () => {
        const { name, bodyInput, bodyOutput } = entity
        updateEntity(id, entityType, { name, bodyInput, bodyOutput })
            .then((updatedEntity) => {
                setEntity(updatedEntity)
                setVersions((prevState) => {
                    return [updatedEntity, ...prevState]
                })
                toast.success('Successfully Updated')
            })
            .catch(handleError)
    }
    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <div className="data-model-content">
                        {entity && (
                            <>
                                <Header
                                    entityType={entityType}
                                    onEntityUpdate={onEntityUpdate}
                                    setEntity={setEntity}
                                    entity={entity}
                                    entityURL={entityURL}
                                    versions={versions}
                                />
                                <div className="codeContent">
                                    <div className="codeContentTitle">
                                        Request
                                    </div>
                                    <MonacoEditor
                                        className="monaco"
                                        defaultLanguage="json"
                                        theme="vs-dark"
                                        value={entity?.bodyInput}
                                        onChange={(v) =>
                                            onDataModelChange(v, 'bodyInput')
                                        }
                                    />
                                    <div className="codeContentTitle">
                                        Response
                                    </div>
                                    <MonacoEditor
                                        className="monaco"
                                        defaultLanguage="json"
                                        theme="vs-dark"
                                        value={entity?.bodyOutput}
                                        onChange={(v) =>
                                            onDataModelChange(v, 'bodyOutput')
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
