import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getLogsByIdAction,
    updateCurrentActivityLogAction,
} from '../../../../store/logs/actions'
import { getActivityLogSelector } from '../../../../store/logs/selector'
import { ButtonLighth } from '../../../Atoms/Buttons/ButtonLighth'
import 'react-toastify/dist/ReactToastify.css'
import MonacoEditor from '@monaco-editor/react'
import s from './LogsPopup.module.scss'
import { getLogsById } from '../../../../store/logs/api'
import { handleError } from '../../../../utils/handleErrors'

export const LogsPopup = ({ setLogsPopup, row }) => {
    const dispatch = useDispatch()
    const stateActivityLog = useSelector(getActivityLogSelector)

    useEffect(() => {
        if (row?.isDetailLoaded) {
            dispatch(updateCurrentActivityLogAction(row))
        } else {
            if (row?.id) {
                getLogsById({ id: row?.id })
                    .then((logsById) => {
                        dispatch(getLogsByIdAction(logsById))
                    })
                    .catch(handleError)
            }
        }
    }, [dispatch, row])
    const handleEditorDidMount = (e) => {
        e.getAction('editor.action.formatDocument').run()
    }

    return (
        <div className={s.popupWrapper}>
            <div className={s.popupBox}>
                <div className={s.popupHeader}>
                    <div className={s.popupName}>Response and Request</div>
                    <div className={s.popupX}>
                        <img
                            onClick={() => setLogsPopup(false)}
                            src="/assets/images/popupX.png"
                            alt="close"
                        />
                    </div>
                </div>

                <div className={s.popupContent}>
                    <div className={s.popupResponse}>
                        <div className={s.popupResponseTitle}>Response</div>
                        {/* <div className={s.popupResponseText}>
                    
                </div> */}
                        <MonacoEditor
                            height={80}
                            onMount={handleEditorDidMount}
                            autoInden={true}
                            defaultLanguage="json"
                            theme="vs-dark"
                            value={stateActivityLog.current.responseData}
                        />
                    </div>
                    <div className={s.popupRequest}>
                        <div className={s.popupRequestTitle}>Request</div>
                        {/* <div className={s.popupRequestText}>
                    {stateActivityLog && stateActivityLog.current &&  stateActivityLog.current.requestData}
                </div> */}
                        <MonacoEditor
                            height={80}
                            onMount={handleEditorDidMount}
                            autoInden={true}
                            defaultLanguage="json"
                            theme="vs-dark"
                            value={stateActivityLog.current.requestData}
                        />
                    </div>
                </div>

                <div className={s.popupButtonsWrapper}>
                    <ButtonLighth
                        onClick={() => setLogsPopup(false)}
                        value="Cancel"
                    />
                </div>
            </div>
        </div>
    )
}
