import { GET_ALL_LOGS, GET_LOGS_BY_ID, UPDATE_CURRENT_ACTIVITY_LOG } from "./types";

function allLogsAction(allLogs) {
    return {
        type: GET_ALL_LOGS,
        allLogs
    }
}

export function getAllLogsAction(allLogs) {
    return (dispatch) => {
        return dispatch(allLogsAction(allLogs));
    }
}

function logsByIdAction(logsById) {
    return {
        type: GET_LOGS_BY_ID,
        logsById
    }
}

export function getLogsByIdAction(logsById) {
    return (dispatch) => {
        return dispatch(logsByIdAction(logsById));
    }
}

function currentActivityLogAction(row) {
    return {
        type: UPDATE_CURRENT_ACTIVITY_LOG,
        row
    }
}

export function updateCurrentActivityLogAction(row) {
    return (dispatch) => {
        return dispatch(currentActivityLogAction(row));
    }
}
