import { TextInput } from '../../Atoms/Inputs/Text'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { conditionOptions } from '../../../utils/selectOptions'
import React from 'react'

export const SegmentCondition = ({
    condition,
    removeSegmentItem,
    updateSegmentItem,
}) => {
    return (
        <div className="formItem">
            <TextInput
                placeholder="Name"
                value={condition.name}
                onChange={({ target }) => {
                    updateSegmentItem(condition.id, 'name', target.value)
                }}
            />
            <SelectWithLabelInput
                options={conditionOptions}
                defaultValue={condition.condition}
                onChange={({ target }) => {
                    updateSegmentItem(condition.id, 'condition', target.value)
                }}
            />
            <TextInput
                placeholder="Value"
                value={condition.value}
                onChange={({ target }) => {
                    updateSegmentItem(condition.id, 'value', target.value)
                }}
            />
            <div
                onClick={() => removeSegmentItem(condition.id)}
                className={'removeSegment'}
            >
                -
            </div>
        </div>
    )
}
