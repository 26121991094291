import React, { memo } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { TextInput } from '../../Atoms/Inputs/Text'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { conditionOptions } from '../../../utils/selectOptions'
import { v4 } from 'uuid'

export const ScoreResults = memo(({ setEntity, entity }) => {
    const results = entity.results
    const addResult = () => {
        setEntity({
            ...entity,
            results: [
                ...results,
                {
                    id: v4(),
                    condition: '',
                    result: '',
                    value: '',
                },
            ],
        })
    }
    const removeResult = (index) => {
        setEntity({
            ...entity,
            results: results.filter((_, i) => i !== index),
        })
    }
    const updateResult = (index, key, value) => {
        setEntity({
            ...entity,
            results: results.map((result, i) => {
                if (i === index) {
                    result[key] = value
                }
                return result
            }),
        })
    }

    return (
        <div className="formResult">
            <div className="resultTitle">Result</div>
            <div className="resultWrapper">
                {(entity.results || []).map((item, index) => {
                    return (
                        <div className="formItem" key={index}>
                            <SelectWithLabelInput
                                options={conditionOptions}
                                label="Condition"
                                defaultValue={results[index].condition}
                                onChange={({ target }) => {
                                    updateResult(
                                        index,
                                        'condition',
                                        target.value,
                                    )
                                }}
                            />

                            <TextInput
                                placeholder="Value"
                                label="Value"
                                value={results[index].value}
                                onChange={({ target }) => {
                                    updateResult(index, 'value', target.value)
                                }}
                            />
                            <TextInput
                                label="Result"
                                placeholder="Result"
                                value={results[index].result}
                                onChange={({ target }) => {
                                    updateResult(index, 'result', +target.value)
                                }}
                            />
                            <div
                                className="remove"
                                onClick={() => removeResult(index)}
                            >
                                -
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="addButton">
                <ButtonWithIcon
                    onClick={addResult}
                    icon="/assets/images/icon+.png"
                    green={true}
                    value="Add Result"
                />
            </div>
        </div>
    )
})
