import React, { memo } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { TextInput } from '../../Atoms/Inputs/Text'
import { useHistory } from 'react-router-dom'
import View from '../../../imgs/view.png'

export const ParameterRow = memo(
    ({ parameters, parameter, updateParameterById, removeParameterById }) => {
        const updateParameter = (key, value) => {
            updateParameterById({
                ...parameter,
                [key]: value,
            })
        }
        const removeParameter = (id) => {
            removeParameterById(id)
        }
        const history = useHistory()
        const viewParameter = (id) => {
            history.push(`/parameters/${id}?type=view`)
        }
        return (
            <div
                className="formResult"
                style={{
                    border: '1px solid #blue',
                }}
            >
                <div className="formItem">
                    <SelectWithLabelInput
                        options={parameters}
                        defaultValue={parameter.parameter}
                        onChange={({ target }) => {
                            updateParameter('parameter', target.value)
                        }}
                    />
                    <TextInput
                        value={parameter?.value}
                        onChange={({ target }) => {
                            updateParameter('value', target.value)
                        }}
                        placeholder="Value"
                    />
                    <div style={{ paddingTop: '11px', paddingLeft: '10px' }}>
                        <img
                            src={View}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                viewParameter(parameter.parameter)
                            }}
                            style={{ cursor: 'pointer', paddingRight: '5px' }}
                        />{' '}
                    </div>
                    <div
                        className="remove"
                        onClick={() => removeParameter(parameter.id)}
                    >
                        -
                    </div>
                </div>
            </div>
        )
    },
)
