import { login, getAllUsers, addUsers, updateUsers } from "./api";
import { LOGIN_USER, GET_ALL_USER, ADD_USER, UPDATE_USER } from "./types";
import { Api } from '../../Services/Api'



function userDataAction(user) {
    return {
        type: LOGIN_USER,
        user
    }
}

export function loginUserAction({email, password}) {
    return (dispatch) => {
        return login({email, password}).then((loginedUser) => {
            dispatch(userDataAction(loginedUser));
            Api().setToken(loginedUser.access_token);
            window.localStorage.setItem('Authorization', loginedUser.access_token);
            window.localStorage.setItem('User', JSON.stringify(loginedUser.user));
        })
    }
}

function allUsersAction(allUsers) {
    return {
        type: GET_ALL_USER,
        allUsers
    }
}

export function getAllUsersAction({page, limit}) {
    return (dispatch) => {
        return getAllUsers({page, limit}).then((allUsers) => {
            dispatch(allUsersAction(allUsers));
        })
    }
}

function addUsersAction(addUsers) {
    return {
        type: ADD_USER,
        addUsers
    }
}

export function addUserAction({ name, email, phone }) {
    return (dispatch) => {
        return addUsers({ name, email, phone }).then((addUser) => {
            dispatch(addUsersAction(addUser));
        })
    }
}

function updateUserAction(updateUser) {
    return {
        type: UPDATE_USER,
        updateUser
    }
}

export function updateUsersAction({editName, password, phone, oldPassword}) {
    return (dispatch) => {
        return updateUsers({editName, password, phone, oldPassword}).then((updateUser) => {
            dispatch(updateUserAction(updateUser));
        })
    }
}