import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { CreateNewSegmentPopup } from '../../Organisms/Popups/CreateNewSegmentPopup/CreateNewScriptPopup';
import { segmentTableColumn } from '../../../utils/tableColumns';
import { getAllSegments } from '../../../store/segment/api';
import {getDataModel } from '../../../store/model/api';
import ListPage from '../../Organisms/ListPage/ListPage';

export const SegmentList = () => {

    return (
     <>
                <ListPage
                 title="Segment" 
                 dataModelCall={getDataModel}  
                 dataCall={getAllSegments} 
                 tableColumns={segmentTableColumn}
                 CreatePopupComponent = {CreateNewSegmentPopup}
                 btnName="Create New Segment" />
                
                </>
       
    )
}