import React from 'react'

export const Node = ({
    stateCurrentTree,
    stateCurrentVariables,
    removeVariable,
    stateLevelLength,
    level,
}) => {
    return (
        <div className="formTableHeader">
            <div
                className="formTableSegmentName"
                style={{ marginRight: (stateLevelLength - level) * -5 }}
            >
                Segment - {stateCurrentTree.name}
            </div>
            {stateCurrentVariables &&
                stateCurrentVariables.length &&
                stateCurrentVariables.map((item) => {
                    return (
                        <div
                            className="formTableVariableNameWrapper"
                            key={item.id}
                        >
                            <div className="nameLine"></div>
                            <div className="formTableVariableName">
                                {item.name}
                                <img
                                    onClick={() => removeVariable(item.id)}
                                    src="/assets/images/trash.png"
                                    alt="trash1"
                                />
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}
